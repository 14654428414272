import React, { useState } from 'react';
import styles from './MaterialTable.module.scss';
import { Send } from 'lucide-react';
import Tooltip from '../../UI/ToolTip/ToolTip';

export default function MaterialTable({ 
    materials = {}, 
    onMaterialsChange, 
    setShowSummary,
    isMyQuotation,
    totals,
    isEditable = true }) {

      const [showToolTip, setShowToolTip] = useState({
        send: { show: false, text: "Debes cotizar al menos 1 material del listado" },
      });

      const handleTooltip = (button) => {
        setShowToolTip((prevState) => ({
          ...prevState,
          [button]: { ...prevState[button], show: !prevState[button].show },
        }));
      };

  const handleInputChange = (categoryId, itemId, field, value) => {
    if (!isEditable) return; // No hacer nada si no es editable
    const updatedMaterials = materials.map((category) => {
      if (category.category_id === categoryId) {
        return {
          ...category,
          materials: category.materials.map((item) =>
            item.id === itemId ? { ...item, [field]: value } : item
          ),
        };
      }
      return category;
    });
    onMaterialsChange(updatedMaterials);
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Listado de materiales a cotizar
        {!isMyQuotation &&
      <div className={styles['button-wrapper']}
              onMouseEnter={() => handleTooltip('send')}
              onMouseLeave={() => handleTooltip('send')}
            >
              <button
                className={styles['send-button']}
                disabled={totals === 0}
                onClick={() => setShowSummary(true)}
              >
                <Send size={20} />
                Enviar presupuesto
              </button>
              {totals === 0 && showToolTip.send.show && (
                <Tooltip text={showToolTip.send.text} />
              )}
            </div>
        }
      </h2>
      <table className={styles.table}>
        <thead className={styles.table_header}>
          <tr>
            <th>Material</th>
            <th>Unidad</th>
            <th>Cantidad</th>
            <th>Precio Unidad</th>
            <th>Descuento</th>
            <th>IVA*</th>
            <th>Precio Total</th>
          </tr>
        </thead>
        <tbody>
          {materials.map((category, index) => (
            <React.Fragment key={index}>
              <tr className={styles.categoryRow}>
                  <td colSpan="7" className={styles.categoryName}>{category.name}</td>
              </tr>
              {category?.materials?.map((item) => (
                <tr key={item.id} className={styles.itemRow}>
                  <td className={styles.itemName}>{item.name}</td>
                  <td>{item.unit}</td>
                  <td>
                    <span>{item.amount || '---'}</span>
                  </td>
                  <td>
                    {isEditable ? (
                      <input
                        type="number"
                        step="1"
                        min="0"
                        className={styles.input_table}
                        value={item.priceUnit || ''}
                        onChange={(e) => handleInputChange(category.category_id, item.id, 'priceUnit', e.target.value)}
                      />
                    ) : (
                      <span>{item.priceUnit || '---'}</span>
                    )}
                  </td>
                  <td>
                    {isEditable ? (
                      <input
                        type="number"
                        step="1"
                        min="0"
                        max="100"
                        className={styles.input_table}
                        value={item.discount || ''}
                        onChange={(e) => handleInputChange(category.category_id, item.id, 'discount', e.target.value)}
                      />
                    ) : (
                      <span>{item.discount !== undefined ? `${item.discount}%` : '---'}</span>
                    )}
                  </td>
                  <td>{(item.priceUnit * 0.21 || 0).toFixed(2)}</td>
                  <td>{((item.amount || 0) * (item.priceUnit || 0) * (1 - (item.discount || 0) / 100)).toFixed(2) || 0}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
        <tfoot>
          <tr className={styles.totalRow}>
            <td colSpan="2" className={styles.iva}>*Impuesto al Valor Agregado (21%)</td>
            <td colSpan="4" className={styles.totalText}>Total Cotización</td>
            <td className={styles.totalValue}>
              {new Intl.NumberFormat('es-AR', {
                style: 'currency',
                currency: 'ARS',
              }).format(
                materials.reduce((total, category) => {
                  return total + category.materials.reduce((categoryTotal, item) => {
                    return categoryTotal + (item.amount || 0) * (item.priceUnit || 0) * (1 - (item.discount || 0) / 100);
                  }, 0);
                }, 0)
              )}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
