import React from 'react';
import { useFinancialData } from '../../hooks/useFinancialData';
import FinancialData from './FinancialData';

const FinancialPage = () => {
  const { financialData, isLoading, error } = useFinancialData();

  if (isLoading) {
    return <div>Cargando datos financieros...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
      <FinancialData data={financialData} /> 
  );
};

export default FinancialPage;
