import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import styled from "styled-components";
import { useNavigate, useParams } from 'react-router-dom';
import QuotationForm from './Quotationform';
import SummaryPanel from './SummaryPanel';
import MaterialTable from './MaterialTable';
import styles from './Cotizador.module.scss';
import { getQuotationById, getMaterialsBySubquotationId, subQuotationPostulation } from '../../../store/actions/profileActions';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import NewNav from '../../Nav/NewNav';
import Botonera from '../../Botonera/Botonera';
import RegisteredUserProfileLoader from '../../RegisteredUsers/RegisteredUsersSkeleton/RegisteredUserProfileLoader';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  padding-right: 8%;
  padding-left: 8%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
  `;

const Cotizador = ({
  quotationData,
  materialsBySubquotation,
  loading,
  error,
  getQuotationById,
  getMaterialsBySubquotationId,
  subQuotationPostulation,
  userData }) => {
  const [materials, setMaterials] = useState([]);
  const [totals, setTotals] = useState({ subtotal: 0, iva: 0, total: 0 });
  const [endDate, setEndDate] = useState("");
  const [note, setNote] = useState("");
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showSummary, setShowSummary] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState({
    quotation: false,
    materials: false,
  });

  const materialsRef = useRef(null);
  const navigate = useNavigate();
  const { quotationId } = useParams();

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getQuotationById(quotationId, false, null);
        setIsDataLoaded({ ...isDataLoaded, quotation: true });
      } catch (error) {
        console.error(error);
      } finally {
        setIsDataLoaded({ ...isDataLoaded, quotation: true });
      }
    };

    fetchData();
  }, [quotationId]);

  useEffect(() => {
    if (quotationData?.subquotation?.length > 0) {
      Promise.all([getMaterialsBySubquotationId(quotationData.subquotation[0].id)])
        .then(() => {
          setIsDataLoaded({ ...isDataLoaded, materials: true });
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoaded({ ...isDataLoaded, materials: true });
        });
    }
  }, [quotationData]);


  useEffect(() => {
    if (materialsBySubquotation && materialsRef.current !== materialsBySubquotation) {
      materialsRef.current = materialsBySubquotation;

      const transformedMaterials = Object.values(materialsBySubquotation).flat();
      setMaterials(transformedMaterials.map((category) => ({
        category_id: category.category_id,
        name: category.name,
        materials: category.materials,
      })));
    }
  }, [materialsBySubquotation]);


  const allDataLoaded = Object.values(isDataLoaded).every((value) => value);

  useEffect(() => {
    if (allDataLoaded) {
      setIsLoading(false);
    }
  }, [isDataLoaded]);

  const handleMaterialsChange = (updatedMaterials) => {
    setMaterials(updatedMaterials);
    calculateTotals(updatedMaterials);
  };

  const calculateTotals = (updatedMaterials) => {
    let subtotal = 0;

    updatedMaterials.forEach((category) => {
      category.materials.forEach((item) => {
        const amount = parseFloat(item.amount) || 0;
        const priceUnit = parseFloat(item.priceUnit) || 0;
        const discount = parseFloat(item.discount) || 0;
        const totalPrice = amount * priceUnit * (1 - discount / 100);
        subtotal += totalPrice;
      });
    });

    const iva = subtotal * 0.21;
    const total = subtotal + iva;

    setTotals({ subtotal, iva, total });
  };

  const generatePdf = (materials, totals, note, endDate, userData) => {
    const formatDate = (date) => {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    };

    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("Presupuesto Detallado", 14, 20);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(`Generado por: ${userData?.type === "COMPANY" ?
      userData?.razon_social : `${userData?.name} ${userData?.last_name}`}`, 14, 30);

    doc.setFont("helvetica", "normal");
    doc.text(`Correo: ${userData?.username || "No disponible"}`, 14, 36);
    doc.text(`Teléfono: ${userData?.phone || "No disponible"}`, 14, 42);

    const validUntil = endDate ? `Presupuesto válido hasta: ${formatDate(endDate)}` : "";
    if (validUntil) {
      doc.text(validUntil, 14, 48);
    }

    const startY = 55;

    const headers = [["Material", "Categoría", "Unidad", "Cantidad", "Precio Unitario", "Descuento", "Subtotal"]];

    const data = materials.flatMap((category) =>
      category.materials.map((material) => {
        const priceUnit = isNaN(material.priceUnit) || material.priceUnit === 0
          ? "No cotizado"
          : `$${parseFloat(material.priceUnit).toFixed(2)}`;
        return [
          material.name,
          category.name,
          material.unit,
          material.amount || 0,
          priceUnit,
          `${(parseFloat(material.discount) || 0).toFixed(2)}%`,
          `$${((parseFloat(material.amount) || 0) * (parseFloat(material.priceUnit) || 0) * (1 - (parseFloat(material.discount) || 0) / 100)).toFixed(2)}`
        ];
      })
    );

    autoTable(doc, {
      head: headers,
      body: data,
      startY: startY,
      theme: "grid",
      headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255], fontSize: 12 },
      bodyStyles: { fontSize: 10 },
      columnStyles: {
        2: { halign: "right" },
        3: { halign: "right" },
        4: { halign: "right" },
        5: { halign: "right" },
      },
    });

    const tableY = doc.previousAutoTable.finalY + 10;
    doc.setFontSize(12);
    doc.text("Resumen de Totales", 14, tableY);

    autoTable(doc, {
      body: [
        ["Subtotal", `$${totals.subtotal.toFixed(2)}`],
        ["IVA (21%)", `$${totals.iva.toFixed(2)}`],
        ["Total", `$${totals.total.toFixed(2)}`],
      ],
      startY: tableY + 10,
      theme: "grid",
      bodyStyles: { fontSize: 10, halign: "right" },
      columnStyles: {
        0: { halign: "left" },
      },
    });

    if (note) {
      const noteY = doc.previousAutoTable.finalY + 10;
      doc.setFontSize(12);
      doc.text("Condiciones Especiales", 14, noteY);
      doc.setFontSize(10);
      doc.text(note, 14, noteY + 10);
    }

    const footerText = "Este presupuesto tiene carácter informativo y no es una oferta formal. Los precios y condiciones no están garantizados por Edify.";
    const footerY = doc.internal.pageSize.height - 20;

    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");

    const footerTextWidth = doc.getTextDimensions(footerText).w;
    const footerX = doc.internal.pageSize.width - 14 - footerTextWidth;

    doc.text(footerText, footerX, footerY);

    return new File([doc.output("blob")], "presupuesto.pdf", { type: "application/pdf" });
  };

  const handleSendQuotation = () => {
    const pdfBlob = generatePdf(materials, totals, note, endDate, userData);
    setShowSummary(false);
    subQuotationPostulation(quotationData?.subquotation[0].id, pdfBlob, note, endDate, navigate);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      {isLoading ? <RegisteredUserProfileLoader /> : (
        <div className={styles.container}>
          <div className={styles['header']} >
            <div className={styles['title']} >
              Cotización de materiales
              <h1 className={styles['text']}>{quotationData?.quotation?.name}</h1>
            </div>
            <div className={styles['user-infoWrapper']}>
              Pedido por:
              <div className={styles['user-info']}>

                <div className={styles['user-info-avatar']}>
                  <img src={quotationData?.quotation?.user?.image_profile} alt="avatar" />
                </div>
                <div className={styles['user-info-item']}>
                  <p className={styles['user-info-item-value']}>{quotationData?.quotation?.user?.type === "COMPANY" ?
                    quotationData?.quotation?.user.razon_social : quotationData?.quotation?.user?.name + " " + 
                    quotationData?.quotation?.user?.last_name}</p>
                  <p className={styles['user-info-item-value']}>{quotationData?.quotation?.user.username}</p>
                </div>

              </div>
            </div>

          </div>
          <div className={styles['body']}>
            <div className={styles['general-info']}>
              <QuotationForm quotation={quotationData} />
              <MaterialTable
                materials={materials}
                onMaterialsChange={handleMaterialsChange}
                setShowSummary={setShowSummary}
                showSummary={showSummary}
                totals={totals.total}
                isEditable={true} />
            </div>
            {showSummary && (
              <div className={styles['summary']}>
                <SummaryPanel
                  totals={totals}
                  handleSendQuotation={handleSendQuotation}
                  endDate={endDate}
                  note={note}
                  setNote={setNote}
                  closeModal={() => setShowSummary(false)}
                  setEndDate={(date) => {
                    setEndDate(date);
                  }}
                  id={quotationData.quotation.id} />
              </div>
            )}
          </div>

        </div>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  quotationData: state.profile.quotation,
  materialsBySubquotation: state.profile.materialsBySubquotation,
  loading: state.profile.loading,
  error: state.profile.error,
  userData: state.general.userData,
});

const mapDispatchToProps = {
  getQuotationById,
  getMaterialsBySubquotationId,
  subQuotationPostulation
};

export default connect(mapStateToProps, mapDispatchToProps)(Cotizador);
