import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styles from "./WorkBagSuccess.module.scss";
import { connect } from "react-redux";
import Confetti from "react-confetti";
import { getAllWorkOffers } from "../../store/actions";
import {
    CheckCircle2,
    MapPin,
    Briefcase,
    Wallet,
    Clock,
    Mail,
    FileText,
    ArrowRight,
    MessageSquare,
    Search,
    ShieldCheck
} from 'lucide-react';
import WorkApplyModal from "../WorkBag/WorkApplyModal";

const WorkBagSuccess = ({ allJobRequests, getAllWorkOffers }) => {
    const [showApplyModal, setShowApplyModal] = useState(false);
    const [currentWorkOffer, setCurrentWorkOffer] = useState(null);
    const [showConfetti, setShowConfetti] = useState(true);

    useEffect(() => {
        getAllWorkOffers();

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        const timer = setTimeout(() => setShowConfetti(false), 5000);
        return () => clearTimeout(timer);
    }
        , []);




    // Función para calcular la diferencia de tiempo en minutos, horas, días, semanas y meses
    const calcularDiferenciaTiempo = (fechaCreacion) => {
        const fechaActual = new Date();
        const fechaCreacionPost = new Date(fechaCreacion);
        const fechaCreacionLocal = new Date(fechaCreacionPost.getTime() - (fechaCreacionPost.getTimezoneOffset() * 60000) - (3 * 60 * 60 * 1000));
        let diferencia = fechaActual - fechaCreacionLocal;

        if (diferencia < 0) {
            diferencia = -diferencia;
        }

        const minutos = Math.floor(diferencia / (1000 * 60));
        const horas = Math.floor(diferencia / (1000 * 60 * 60));
        const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
        const semanas = Math.floor(dias / 7);
        const meses = Math.floor(dias / 30);

        if (minutos < 1) {
            return "Ahora";
        } else if (minutos < 60) {
            return minutos === 1 ? "1 minuto" : `${minutos} minutos`;
        } else if (horas < 24) {
            return horas === 1 ? "1 hora" : `${horas} horas`;
        } else if (dias < 7) {
            return dias === 1 ? "1 día" : `${dias} días`;
        } else if (semanas < 4) {
            return semanas === 1 ? "1 semana" : `${semanas} semanas`;
        } else if (meses < 12) {
            return meses === 1 ? "1 mes" : `${meses} meses`;
        } else {
            const años = Math.floor(dias / 365);
            return años === 1 ? "1 año" : `${años} años`;
        }
    };

    const formatStartDate = (date) => {
        let day = date?.split("T")[0].split("-")[2];
        let month = date?.split("T")[0].split("-")[1];
        let year = date?.split("T")[0].split("-")[0];
        return `${day}/${month}/${year}`;

    }

    const handleApply = (workOffer) => {
        setCurrentWorkOffer(workOffer);
        setShowApplyModal(true);
    }



    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.workBagSuccess}
        >
            {showConfetti && 
            <div className={styles.confettiContainer}>
            <Confetti
            />
            </div>}
            <header className={styles.workBagSuccess_header}>
                <div className={styles.workBagSuccess_header_container}>
                    <div className={styles.workBagSuccess_title}>
                        <div className={styles.workBagSuccess_title_icon}>
                            <CheckCircle2 size={32} />
                        </div>
                        <div className={styles.workBagSuccess_title_text}>
                            <h1 className={styles.workBagSuccess_title_text_h1}>¡Bienvenido a nuestra bolsa de trabajo!</h1>
                            <p className={styles.workBagSuccess_title_text_p}>Tu registro ha sido exitoso</p>
                        </div>
                    </div>
                    <p className={styles.workBagSuccess_header_text}>A partir de ahora, nuestro sistema buscará las mejores oportunidades laborales basadas en tu perfil. Cuando encontremos ofertas que coincidan con tus habilidades y ubicación, te notificaremos por email.</p>
                </div>
            </header>
            <div className={styles.workBagSuccess_body}>
                <div className={styles.workBagSuccess_body_infoContainer}>
                    <div className={styles.workBagSuccess_body_infoContainer_container}>
                        <h4 className={styles.workBagSuccess_body_infoContainer_title}>Como funciona el proceso</h4>
                        <div className={styles.workBagSuccess_body_infoContainer_stepContainer}>
                            <div className={styles.workBagSuccess_body_infoContainer_step}>
                                <div className={styles.workBagSuccess_body_infoContainer_step_icon}>
                                    <Mail size={24} />
                                </div>
                                <h5 className={styles.workBagSuccess_body_infoContainer_step_title}>1. Notificación por Email</h5>
                                <p className={styles.workBagSuccess_body_infoContainer_step_text}>
                                    Recibirás un email cuando encontremos ofertas que coincidan con tu perfil y experiencia
                                </p>
                            </div>
                            <div className={styles.workBagSuccess_body_infoContainer_step}>
                                <div className={styles.workBagSuccess_body_infoContainer_step_icon}>
                                    <ShieldCheck size={24} />
                                </div>
                                <h5 className={styles.workBagSuccess_body_infoContainer_step_title}>2. Validación de identidad</h5>
                                <p className={styles.workBagSuccess_body_infoContainer_step_text}>
                                    Para garantizar la seguridad, deberás validar tu identidad antes de postularte a las ofertas
                                </p>
                            </div>
                            <div className={styles.workBagSuccess_body_infoContainer_step}>
                                <div className={styles.workBagSuccess_body_infoContainer_step_icon}>
                                    <FileText size={24} />
                                </div>
                                <h5 className={styles.workBagSuccess_body_infoContainer_step_title}>3. Completa tu postulación</h5>
                                <p className={styles.workBagSuccess_body_infoContainer_step_text}>
                                    Al postularte, podrás subir tu CV y escribir una carta de presentación específica para la oferta
                                </p>
                            </div>
                            <div className={styles.workBagSuccess_body_infoContainer_step}>
                                <div className={styles.workBagSuccess_body_infoContainer_step_icon}>
                                    <MessageSquare size={24} />
                                </div>
                                <h5 className={styles.workBagSuccess_body_infoContainer_step_title}>4. Contacto directo</h5>
                                <p className={styles.workBagSuccess_body_infoContainer_step_text}>
                                    Si tu perfil coincide, la empresa se pondrá en contacto contigo para continuar el proceso
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.workBagSuccess_body_workOfferContainer}>
                    <div className={styles.workBagSuccess_body_workOfferContainer_container}>
                        <h4 className={styles.workBagSuccess_body_workOfferContainer_title}>Ofertas de trabajo disponibles</h4>
                        <div className={styles.workBagSuccess_body_workOfferContainer_disclaimer}>
                            <div className={styles.workBagSuccess_body_workOfferContainer_disclaimer_icon}>
                                <ShieldCheck size={24} />
                            </div>
                            <div className={styles.workBagSuccess_body_workOfferContainer_disclaimer_text}>
                                <div className={styles.workBagSuccess_body_workOfferContainer_disclaimer_text_title}>Validación de identidad requerida</div>
                                <p className={styles.workBagSuccess_body_workOfferContainer_disclaimer_text_p}>
                                    Para poder postularte a las ofertas de trabajo, primero deberás completar el proceso de validación de identidad. Esto nos ayuda a mantener un ambiente seguro y confiable para todos los usuarios.
                                </p>
                            </div>
                        </div>
                        <div className={styles.workBagSuccess_body_workOfferContainer_workOffer}>
                            {allJobRequests && allJobRequests?.total > 0 ? (
                                allJobRequests && allJobRequests?.workOffers?.map((jobRequest, index) => {
                                    const nameToDisplay = jobRequest.user?.type === "COMPANY" ? jobRequest.user?.razon_social : jobRequest.user?.name + " " + jobRequest.user?.last_name;

                                    return (
                                        <div className={styles.workBagSuccess_body_workOfferContainer_workOffer_card} key={index}>
                                            <div className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_container}>
                                                <div className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_container_icon}>
                                                    <Briefcase size={24} />
                                                </div>
                                                <div className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_body}>
                                                    <div className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_header}>
                                                        <div className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_header_info}>
                                                            {jobRequest.skill && jobRequest.skill[0] && (
                                                                <h5 className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_header_title}>{jobRequest.skill[0]?.skill?.name}</h5>
                                                            )}
                                                            <p className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_header_text}>{nameToDisplay}</p>
                                                        </div>
                                                        <div className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_header_timeLeft}>
                                                            <span className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_header_timeLeft_text}>
                                                                Tenés tiempo hasta {formatStartDate(jobRequest?.activeUntil)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_body_info}>
                                                        <div className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_body_info_icon}>
                                                            <MapPin size={16} />
                                                        </div>
                                                        <p className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_body_info_text}>
                                                            {jobRequest.workZones && jobRequest.workZones[0] && jobRequest.workZones[0]?.city}
                                                        </p>
                                                        {jobRequest.salary && (
                                                            <>
                                                                <div className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_body_info_icon}>
                                                                    <Wallet size={16} />
                                                                </div>
                                                                <p className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_body_info_text}>
                                                                    {jobRequest.salary}
                                                                </p>
                                                            </>
                                                        )}
                                                        <div className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_body_info_icon}>
                                                            <Clock size={16} />
                                                        </div>
                                                        <p className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_body_info_text}>Hace {calcularDiferenciaTiempo(jobRequest?.createdAt?.split("T")[0])}</p>
                                                    </div>
                                                    <button className={styles.workBagSuccess_body_workOfferContainer_workOffer_card_btn} onClick={() => handleApply(jobRequest)}>
                                                        Postularme <ArrowRight size={16} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                                )
                            ) : (
                                <div className={styles.workBagSuccess_body_workOfferContainer_noWorkOffer}>
                                    <div className={styles.workBagSuccess_body_workOfferContainer_noWorkOffer_icon}>
                                        <Search size={50} />
                                    </div>
                                    <h5 className={styles.workBagSuccess_body_workOfferContainer_noWorkOffer_title}>¡No hay ofertas disponibles!</h5>
                                    <p className={styles.workBagSuccess_body_workOfferContainer_noWorkOffer_text}>Estamos buscando las mejores oportunidades que coincidan con tus habilidades y experiencia.
                                        Te notificaremos por email cuando encontremos ofertas relevantes.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showApplyModal &&
                <WorkApplyModal
                    workOffer={currentWorkOffer}
                    isFromSuccessPage={true}
                    setShowApplyModal={setShowApplyModal}
                    allJobRequests={allJobRequests} />}
        </motion.div>
    );
};

const mapStateToProps = (state) => {
    return {
        allJobRequests: state.register.allJobRequests
    };
}

export default connect(mapStateToProps, { getAllWorkOffers })(WorkBagSuccess);