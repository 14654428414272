import React from "react";
import styles from "./FeedCardComment.module.scss";
import useUserImage from "../../../hooks/useUserImage";
import { ReactComponent as SendPostComment } from "../../../assets/svg/sendPostComment.svg";
import { ReactComponent as CancelIcon } from "../../../assets/svg/cancelIcon.svg";
import { ReactComponent as NoImage } from "../../../assets/svg/noImageIcon.svg";
import TextareaAutosize from "react-textarea-autosize";


const FeedCardComment = ({
    comments,
    commentContent,
    setCommentContent,
    showComments,
    handlePostComment,
    userData,
    editComment,
    id,
    handleEditComment,
    handleToggleMenuComments,
    showMenuComments,
    handleMenuItemClick,
    isEditingComment,
    editSelectedCard,
    setEditComment,
    setIsEditingComment,
    calcularDiferenciaTiempo
}) => {

    const { imageError, initials, profileImage } = useUserImage(userData);

    return (
        <>
            <div className={styles.itemCommentHeader}>
                <div className={styles.itemCommentHeader_imgContainer}>
                    {!imageError && profileImage ? (
                        <img
                            className={styles.itemCommentHeader_imgContainer_img}
                            src={profileImage}
                            alt="user"
                        />
                    ) : initials ? (
                        <div className={styles.itemCommentHeader_imgContainer_initials}>{initials}</div>
                    ) : (
                        <div className={styles.itemCommentHeader_imgContainer_img}>{<NoImage width={60} height={60} />}</div>
                    )}
                </div>
                <div className={styles.input}>
                    <TextareaAutosize
                        type="text"
                        placeholder={"Agregar comentario"}
                        className={styles.input_item}
                        id="comment"
                        style={{ height: "40px" }}
                        value={commentContent}
                        onChange={(e) => setCommentContent(e.target.value)} />
                    <div className={styles.input_item_iconContainer}>
                        <div onClick={() => handlePostComment(id, commentContent)}>
                            <SendPostComment width={25} height={25} />
                        </div>
                    </div>
                </div>
            </div>
            {showComments && (
                <div className={styles.itemComment}>
                    {Array.isArray(comments) && comments?.length > 0 &&
                        comments?.map((comment, index) => {
                            let commentCompleteName = comment.user.type === "COMPANY" ? comment.user.razon_social : comment.user.name + " " + comment.user.last_name;
                            let commentImageProfile = comment.user.image_profile;
                            let contenido = comment.content;
                            let creacion = comment.createdAt;
                            let isMyComment = comment.user.id === userData.id ? true : false;
                            let id = comment.id;
                            return (
                                <div className={styles.itemCommentBody_item} key={index}>
                                    <div className={styles.itemCommentBody_item_imgContainer}>
                                        <img src={commentImageProfile} alt="" />
                                    </div>
                                    <div className={styles.itemCommentBody_item_content}>
                                        <div className={styles.itemCommentBody_item_text_name}>{commentCompleteName}
                                            {isMyComment && (
                                                <div className={showMenuComments[id] ? styles.itemComments : styles.itemComments_optionsDotsComments}>
                                                    <div className={styles.itemComments_options_container} onClick={() => handleToggleMenuComments(id)}>
                                                        <div className={styles.itemComments_options_container_item}>
                                                            <div className={styles.itemComments_options_container_item_txt}>
                                                                {showMenuComments[id] ? (
                                                                    <ul className={styles.itemComments_options_container_item_dropdownMenu}>
                                                                        <li onClick={() => handleMenuItemClick("Editar", id, false)}>Editar</li>
                                                                        <li onClick={() => handleMenuItemClick("Eliminar", id, false)}>Eliminar</li>
                                                                    </ul>
                                                                ) : (
                                                                    <p className={styles.itemComments_options_container_item_span}>...</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {isEditingComment && editSelectedCard === id ? (
                                            <div className={styles.itemCommentBody_item_text_contentContainerEdit}>
                                                <TextareaAutosize
                                                    type="text"
                                                    className={styles.itemCommentBody_input_item}
                                                    id="comment"
                                                    value={editComment || contenido}
                                                    onChange={(e) => setEditComment(e.target.value)} />
                                                <div className={styles.itemCommentBody_input_item_iconContainer} onClick={() => handleEditComment(id, editComment)}>
                                                    <SendPostComment width={20} height={20} />
                                                </div>
                                                <div className={styles.itemCommentBody_input_item_iconContainer} onClick={() => setIsEditingComment(false)}>
                                                    <CancelIcon width={20} height={20} />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={styles.itemCommentBody_item_text_content}>
                                                {contenido}
                                            </div>
                                        )}
                                        <div className={styles.itemCommentBody_item_text_timeContainer}>
                                            <p className={showMenuComments ? styles.itemCommentBody_item_text_timeContainerWithMenu : ""}>
                                                {calcularDiferenciaTiempo(creacion)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )}
                </div>

            )}

        </>
    )
}

export default FeedCardComment;
