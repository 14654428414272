import React, { useEffect, useState } from "react";
import styles from "./WorkApplyModal.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { closeMainModal, openMainModal, getAllWorkBagUsers, sendIdentityPhotos, openIdentityModal } from "../../store/actions";
import { useNavigate } from "react-router-dom";
import PhotoUploadModal from "../../../src/components/FileUpload/PhotosUploadModal";
import { motion } from "framer-motion";

const variants = {
    visible: {
        scale: 1.0,
    },
    hidden: {
        scale: 1.1,
    },
};


const WorkApplyModal = ({
    workBagUsers,
    setShowApplyModal,
    openMainModal,
    closeMainModal,
    setShowForm,
    getAllWorkBagUsers,
    setFormViewInPhone,
    openIdentityModal,
    isFromSuccessPage,
    workOffer,
    isPhone,
    sendIdentityPhotos }) => {
    const [showPhotoUploadModal, setShowPhotoUploadModal] = useState(false);
    const [hideApplyModal, setHideApplyModal] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getAllWorkBagUsers();
    }, []);

    const handlePhotoModalState = () => {
        setShowPhotoUploadModal(true);
    }
    const handleSendPhotos = (files) => {
        const email = formik.values.email;
        sendIdentityPhotos(files, email, navigate);
    }

    const handleSubmit = (values) => {
        const isUserInWorkBag = workBagUsers.find((user) => user.email === values.email)

        if (!isUserInWorkBag) {
            setShowApplyModal(false);
            openMainModal({
                title: "¡Ups!",
                body: "No encontramos tu email en nuestra base de datos. Regístrate para poder postularte a esta oferta.",
                isSuccess: true,
                iconType: "error",
                noLabel: "Registrarme",
                customCloseMainModal: () => {
                    if (isFromSuccessPage) {
                        navigate(`/bolsadetrabajo`)
                        closeMainModal();
                    } else {
                        setShowForm(true);
                        closeMainModal();
                        if (isPhone) {
                            setFormViewInPhone(false);
                        }
                    }
                }
            })
        } else if (!isUserInWorkBag.identity_validated) {
            openMainModal({
                title: "Validación de identidad requerida",
                body: "Para postularte a esta oferta, debes validar tu identidad. Es muy fácil, solo necesitas subir unas fotos de tu DNI.",
                yesLabel: "Subir fotos",
                confirmHandler: () => {
                    closeMainModal();
                    handlePhotoModalState();
                    setHideApplyModal(true);
                }
            })
        } else {
            setShowApplyModal(false);
            closeMainModal();
            navigate(`/bolsadetrabajo/${workOffer.id}`)
        }
    }

    const initialValues = {
        email: ""
    }

    const formSchema = {
        email: Yup.string().email("Email inválido").required("Campo requerido")
    }

    const formik = useFormik({
        initialValues,
        validateOnMount: true,
        validationSchema: Yup.object(formSchema),
        onSubmit: (values) => {
            handleSubmit(values)
        }

    })

    const handleClosePhotoUploadModal = () => {
        setShowPhotoUploadModal(false);
        setHideApplyModal(false);
        setShowApplyModal(false);
        openIdentityModal(false);
    }


    return (
        <>
            <div className={`${styles.overlay} ${hideApplyModal && styles.hideApplyModal} `} >
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    className={styles.modal}>
                    <header className={styles.header}>
                        <h2 className={styles.header_title}>¿Te interesa esta oportunidad?</h2>
                        <button className={styles.header_button} onClick={() => setShowApplyModal(false)}>X</button>
                    </header>
                    <main className={styles.main}>
                        <h4>Ingresa tu correo electrónico para postularte</h4>
                        <form className={styles.form} onSubmit={formik.handleSubmit}>
                            <input className={styles.form_input}
                                type="email"
                                placeholder="ejemplo@mail.com"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange} />
                            <button type="submit" className={styles.form_button} disabled={!formik.isValid} >Enviar</button>
                        </form>
                    </main>
                </motion.div>
            </div>
            {showPhotoUploadModal && (
                <PhotoUploadModal
                    closeModal={handleClosePhotoUploadModal}
                    handleSendPhotos={handleSendPhotos}
                    onClick={() => {
                        setShowPhotoUploadModal(false);
                        openMainModal({
                            title: "¡Excelente!",
                            body: "Tus fotos han sido enviadas para verificación. Te notificaremos cuando tu identidad sea aprobada.",
                            isSuccess: true,
                            iconType: "success",
                            customCloseMainModal: () => {
                                closeMainModal()
                            }
                        });
                    }}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        workBagUsers: state.register.workBagUsers,
    };
}

export default connect(mapStateToProps, { openMainModal, closeMainModal, getAllWorkBagUsers, sendIdentityPhotos, openIdentityModal })(WorkApplyModal);
