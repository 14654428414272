import React, { useState } from "react";
import styles from "./SmallWorkerMapItem.module.scss";
import { Phone, Mail, MapPin, BookOpen, Clock } from "lucide-react";
import { motion } from "framer-motion";
import Tooltip from "../../../UI/ToolTip/ToolTip";
import useGetTodayDate from "../../../../hooks/useGetTodayDate";

const SmallWorkerMapItem = ({ worker, onClose, isMarker }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [tooltipVisibility, setTooltipVisibility] = useState({});

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    };

    const todayDate = useGetTodayDate();
    const formattedTodayDate = parseDate(todayDate);

    const handleMouseEnter = (index) => setTooltipVisibility((prev) => ({ ...prev, [index]: true }));
    const handleMouseLeave = (index) => setTooltipVisibility((prev) => ({ ...prev, [index]: false }));

    const handleSlideChange = (direction) => {
        setActiveIndex((prevIndex) => {
            const newIndex = direction === "next" ? prevIndex + 1 : prevIndex - 1;
            return newIndex < 0 ? worker.length - 1 : newIndex >= worker.length ? 0 : newIndex;
        });
    };

    const handleDotClick = (index) => {
        setActiveIndex(index);
    };

    const formatEmploymentEndDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
    };

    const renderAvailability = (workerItem, index) => {
        const isAvailable = workerItem?.userInfo?.current_job
            ? new Date(workerItem?.userInfo?.employment_end_date) <= formattedTodayDate
            : true;

        return (
            <div
                className={isAvailable ? styles.workerInfo_body_text_name_available : styles.workerInfo_body_text_name_notAvailable}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
            >
                {tooltipVisibility[index] && (
                    <Tooltip text={isAvailable
                        ? 'Disponible para trabajar'
                        : `Disponible a partir del ${formatEmploymentEndDate(workerItem?.userInfo?.employment_end_date)}`} />
                )}
            </div>
        );
    };

    const handleMail = (email) => {
        const isGmail = email.includes("@gmail.com");
        const mailtoLink = isGmail ? `https://mail.google.com/mail/?view=cm&fs=1&to=${email}` : `mailto:${email}`;
        window.open(mailtoLink, "_blank");
    };

    const handlePhone = (phone) => {
        const whatsappLink = `https://wa.me/${phone.replace(/\D/g, '')}`;
        window.open(whatsappLink, "_blank");
    };

    const renderWorkerInfo = (workerItem, index) => (
        <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            key={index}
            className={styles.workerCard}
        >
            <div className={styles.title}>
                <h3 className={styles.workerCard_name}>{workerItem?.userInfo?.name} {workerItem?.userInfo?.last_name}</h3>
                <button className={styles.title_close} onClick={onClose}>X</button>
            </div>

            <div className={styles.workerCard_item}>
                <Phone size={16} onClick={() => handlePhone(workerItem?.userInfo?.phone)} className={styles.iconButton} />
                <span>{workerItem?.userInfo?.phone}</span>
            </div>
            <div className={styles.workerCard_item}>
                <Mail size={16} onClick={() => handleMail(workerItem?.userInfo?.email)} className={styles.iconButton} />
                <span>{workerItem?.userInfo?.email}</span>
            </div>
            <div className={styles.workerCard_item}>
                <MapPin size={16} className={styles.iconButton} />
                <span>{workerItem?.userInfo?.jobBoardZones?.map(zone => zone.city.replace(", Argentina", "")).join(", ")}</span>
            </div>
            <div className={styles.workerCard_item}>
                <BookOpen size={16} className={styles.iconButton} />
                <span>Libreta de Desempleo: {workerItem?.userInfo?.unemployment_fund ? "Sí" : "No"}</span>
            </div>
            <div className={styles.workerCard_item}>
                <Clock size={16} className={styles.iconButton} />
                <span>Disponibilidad:
                    {new Date(workerItem?.userInfo?.employment_end_date) < formattedTodayDate ? "Inmediata" :
                        `A partir del ${formatEmploymentEndDate(workerItem?.userInfo?.employment_end_date)}` }
                </span>
            </div>
            <div className={styles.workerCard_itemNoIcon}>
                <span className={styles.workerCard_itemNoIcon_title}>Rubro</span>
                <div className={styles.workerCard_itemNoIcon_skill}>
                    {workerItem?.userInfo?.skill?.map((skill, index) => (
                        <span key={index} className={styles.workerCard_itemNoIcon_skillName}>
                            {skill?.skill?.name}
                        </span>
                    ))}
                </div>
            </div>
        </motion.div>
    );

    return (
        <div className={styles.smallWorkerMapItem}>
            {isMarker ? (
                <div className={styles.workerCards}>
                    {renderWorkerInfo(worker, 0)}
                </div>
            ) : (
                <div className={styles.carouselWrapper}>
                    <div className={styles.workerCards}>
                        {renderWorkerInfo(worker[activeIndex], activeIndex)}
                    </div>

                    {worker.length > 8 && (
                        <div className={styles.navigation}>
                            <button
                                className={styles.arrowButton}
                                onClick={() => handleSlideChange("prev")}
                                aria-label="Anterior"
                            >
                                &lt;
                            </button>
                            <button
                                className={styles.arrowButton}
                                onClick={() => handleSlideChange("next")}
                                aria-label="Siguiente"
                            >
                                &gt;
                            </button>
                        </div>
                    )}

                    {worker.length <= 5 && (
                        <div className={styles.dotsWrapper}>
                            {worker.map((_, index) => (
                                <button
                                    key={index}
                                    className={`${styles.dot} ${index === activeIndex ? styles.activeDot : ""}`}
                                    onClick={() => handleDotClick(index)}
                                    aria-label={`Ir al slide ${index + 1}`}
                                ></button>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SmallWorkerMapItem;






