import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { ReactComponent as ArrowIcon } from "../../../../assets/svg/arrowDownIcon.svg";
import {
  setSendSubProject,
  postSubprojects,
  setProjectToEdit,
  getSubprojectsType,
  resetNewSkill,
} from "../../../../store/actions/index";
import {
  closeMainModal,
} from "../../../../store/actions/profileActions";
import RubroInputSelect from "./RubroInputSelect";
import styles from "./NuevoSubproyecto.module.scss";
import { ReactComponent as Plus } from "../../../../assets/svg/plus.svg";
import { ReactComponent as Trash } from "../../../../assets/svg/description-sub.svg";
import { Info } from "lucide-react";

const NuevoSubproyecto = ({
  subProjectsType,
  subProjectId,
  setSendSubProject,
  existingSubProjects,
  closeModal,
  maxDateForSubProjects,
  resetNewSkill,
  minDateForSubProjects,
  isEditing,
  postSubprojects,
  setShowSubProjectsModal,
  ...props
}) => {
  const [clear, setClear] = useState(false);
  const [showSubProjectDocus, setShowSubProjectDocus] = useState(false);
  const [showItemInfo, setShowItemInfo] = useState({});
  const [selectedRubro, setSelectedRubro] = useState("");
  const [selectFileOrlink, setSelectFileOrLink] = useState(false);

  const resetClear = () => {
    setClear(false);
  };
  const addHandler = (values, initialValues, resetForm, isEditing, projectIdForSub) => {
  
    if (isEditing) {
      setSendSubProject(values);
      postSubprojects(values, projectIdForSub, isEditing, setShowSubProjectsModal);
      setProjectToEdit(projectIdForSub);
      resetForm(initialValues);
      resetClear();
      props.closeMainModal();
      resetNewSkill();

    } else {
      setSendSubProject(values);
      setProjectToEdit(projectIdForSub);
      resetForm(initialValues);
      resetClear();
      props.closeMainModal();
      setSelectedRubro("");
      resetNewSkill();
    }
  };


  //Validación del formulario de carga

  const subProyectosSchema = Yup.object().shape({
    nameSub: Yup.string().test(
      "is-empty",
      "El campo está vacío",
      function (valor) {
        return typeof valor === "string" && valor.trim() !== "";
      }
    ),

    descriptionSub: Yup.string().test(
      "is-empty",
      "*Si no proporciona una descripción puede que la cotización recibida no sea precisa",
      function (valor) {
        return typeof valor === "string" && valor.trim() !== "";
      }
    ),

    linkSub: Yup.string().test(
      "is-empty",
      "*Si no proporciona un link de documentación puede que la cotización recibida no sea precisa",
      function (valor) {
        return typeof valor === "string" && valor.trim() !== "";
      }
    ),

    endDateSub: Yup.date().test(
      "is-empty",
      "*Si no proporciona una fecha de cierre el usuario no sabrá hasta cuándo puede enviar la cotización",
      function (valor) {
        return typeof valor === "string" && valor.trim() !== "";
      }
    ),
  });

  const toggleShowItemInfo = (index) => {
    setShowItemInfo((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <div className={styles.generalContainer}>
      <Formik
        initialValues={{
          name: "",
          description: "",
          link: "",
          startDate: minDateForSubProjects,
          endDate: maxDateForSubProjects,
          excelBudget: null,
          excel: null,
          plantillaDePresupuesto: null,
          planosFile: null,
          metricComputation: null,
          technicalSpecifications: null,
        }}
        validationSchema={subProyectosSchema}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          values,
          resetForm,
          initialValues,
          setFieldValue,
          navigate,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className={`${styles.fieldWrapperSubProyect} `}>
              <RubroInputSelect
                inputName="name"
                rubros={subProjectsType}
                setFieldValue={setFieldValue}
                clear={clear}
                resetClear={resetClear}
                setSelectedRubro={setSelectedRubro}
                selectedRubro={selectedRubro}
                existingSubProjects={existingSubProjects}
                setShowSubProjectDocus={setShowSubProjectDocus}
              />
            </div>
            <div className={values.name ? styles.containerSubproject : ""}>
              <div
                className={props.subProjectStyleForm ? styles.fieldWrapper_hidden : `${styles.fieldWrapper_content} ${values.name && styles.open}`
                }
              >
                {!showSubProjectDocus && (                
                  <div className={styles.showDocus}>
                    <h2 className={styles.showDocus_tituloDocu}>¿Querés agregar información extra para obtener<br /> mejores cotizaciónes?</h2>
                    <div className={styles.showDocus_info}>
                      <Info size={80} style={{color: "rgb(245, 158, 11)"}} />
                      <p className={styles.showDocus_info_text}>Al agregar información adicional, los profesionales podrán<br /> brindarte una cotización más precisa.</p>
                    </div>
                    <div className={styles.showDocus_buttonContainer}>
                      <div
                        className={styles.showDocus_buttonContainer_btnNo}
                        onClick={() => {
                          addHandler(values, initialValues, resetForm, isEditing, props.projectIdForSub);
                          setShowSubProjectDocus(false)
                        }}
                      >
                        No, solo quiero agregar el rubro
                      </div>
                      <div
                        className={styles.showDocus_buttonContainer_btnSi}
                        onClick={() => setShowSubProjectDocus(true)}
                      >
                        Sí, quiero agregar información adicional
                      </div>
                    </div>
                  </div>
                  
                )}
                {showSubProjectDocus && (
                  <div className={styles.docusContainer}>
                    <h2 className={styles.tituloDocu}>Información adicional para cotizar</h2>
                    <div className={styles.tituloDocu_itemContainer}>
                      <div className={styles.tituloDocu_itemContainer_cover}>
                        <h3 className={styles.tituloDocu_link}>Planos</h3>
                        <div className={styles.tituloDocu_arrow} onClick={() => toggleShowItemInfo(1)}>
                          <ArrowIcon width={15} height={15} className={showItemInfo[1] ? styles.arrowIconDown : styles.arrowIconRight} />
                        </div>
                      </div>
                      {showItemInfo[1] && (
                        <div>
                          <div className={styles.radioGroup}>
                            <label className={styles.radioGroup_label}>
                              <input
                                type="radio"
                                name="uploadOption"
                                value="link"
                                checked={selectFileOrlink === 'link'}
                                onChange={() => setSelectFileOrLink('link')}
                                className={styles.radioGroup_label_input}
                              />
                              Cargar link de acceso
                            </label>
                            <label className={styles.radioGroup_label} title="PDF o excel">
                              <input
                                type="radio"
                                name="uploadOption"
                                value="planosFile"
                                checked={selectFileOrlink === 'planosFile'}
                                onChange={() => setSelectFileOrLink('planosFile')}
                                className={styles.radioGroup_label_input}
                              />
                              Cargar archivo
                            </label>
                          </div>
                          {selectFileOrlink === 'link' && (
                            <div className={styles.fieldWrapperLink}>
                              <div className={styles.relative}>
                                <Field
                                  className={styles.textAreaLink}
                                  id="link"
                                  name="link"
                                  placeholder="Por ej: Google Drive, Dropbox, etc."
                                />
                              </div>
                            </div>
                          )}
                          {selectFileOrlink === 'planosFile' && (
                            <div className={styles.fieldWrapperLink}>
                              <div className={styles.relative}>
                                <Field
                                  className={styles.fileName}
                                  id="planos"
                                  name="planos"
                                  type="file"
                                  accept=".pdf, .xlsx, .xls"
                                  onChange={(event) => {
                                    event.currentTarget.files[0] &&
                                      setFieldValue("planosFile", event.currentTarget.files[0]);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={styles.tituloDocu_itemContainer}>
                      <div className={styles.tituloDocu_itemContainer_cover}>
                        <h3 className={styles.tituloDocu_link}>Cómputo metrico</h3>
                        <div className={styles.tituloDocu_arrow} onClick={() => toggleShowItemInfo(2)}>
                          <ArrowIcon width={15} height={15} className={showItemInfo[2] ? styles.arrowIconDown : styles.arrowIconRight} />
                        </div>
                      </div>
                      {showItemInfo[2] && (
                        <div className={styles.fieldWrapperLink}>
                          <div className={styles.relative}>
                            <Field
                              className={styles.fileName}
                              id="computoMetrico"
                              name="computoMetrico"
                              type="file"
                              accept=".pdf, .xlsx, .xls"
                              onChange={(event) => {
                                event.currentTarget.files[0] &&
                                  setFieldValue("metricComputation", event.currentTarget.files[0]);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.tituloDocu_itemContainer}>
                      <div className={styles.tituloDocu_itemContainer_cover}>
                        <h3 className={styles.tituloDocu_link}>Especificaciónes técnicas</h3>
                        <div className={styles.tituloDocu_arrow} onClick={() => toggleShowItemInfo(3)}>
                          <ArrowIcon width={15} height={15} className={showItemInfo[3] ? styles.arrowIconDown : styles.arrowIconRight} />
                        </div>
                      </div>
                      {showItemInfo[3] && (
                        <div className={styles.fieldWrapperLink}>
                          <div className={styles.relative}>
                            <Field
                              className={styles.fileName}
                              id="especificacionesTecnicas"
                              name="especificacionesTecnicas"
                              type="file"
                              accept=".pdf, .xlsx, .xls"
                              onChange={(event) => {
                                event.currentTarget.files[0] &&
                                  setFieldValue("technicalSpecifications", event.currentTarget.files[0]);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.tituloDocu_itemContainer}>
                      <div className={styles.tituloDocu_itemContainer_cover}>
                        <h3 className={styles.tituloDocu_link}>Planilla modelo de cotización</h3>
                        <div className={styles.tituloDocu_arrow} onClick={() => toggleShowItemInfo(4)}>
                          <ArrowIcon width={15} height={15} className={showItemInfo[4] ? styles.arrowIconDown : styles.arrowIconRight} />
                        </div>
                      </div>
                      {showItemInfo[4] && (
                        <div className={styles.fieldWrapperLink}>
                          <Field
                            className={styles.fileName}
                            id="planillaExcel"
                            name="planillaExcel"
                            type="file"
                            accept=".pdf, .xlsx, .xls"
                            onChange={(event) => {
                              event.currentTarget.files[0] &&
                                setFieldValue("plantillaDePresupuesto", event.currentTarget.files[0]);
                            }
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div className={styles.tituloDocu_itemContainer}>
                      <div className={styles.tituloDocu_itemContainer_cover}>
                        <h3 className={styles.tituloDocu_link}>Descripción particular</h3>
                        <div className={styles.tituloDocu_arrow} onClick={() => toggleShowItemInfo(5)}>
                          <ArrowIcon width={15} height={15} className={showItemInfo[5] ? styles.arrowIconDown : styles.arrowIconRight} />
                        </div>
                      </div>
                      {showItemInfo[5] && (
                        <div className={styles.fieldWrapper}>
                          <div className={styles.relative}>
                            <Field
                              id="description"
                              name="description"
                              placeholder="Breve descripción de las tareas a realizar."
                              as="textarea"
                              className={styles.tituloDescripcion_textArea}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.tituloDocu_itemContainer}>
                      <div className={styles.tituloDocu_itemContainer_cover}>
                        <h3 className={styles.tituloDocu_link}>Fecha de recepción de presupuestos</h3>
                        <div className={styles.tituloDocu_arrow} onClick={() => toggleShowItemInfo(6)}>
                          <ArrowIcon width={15} height={15} className={showItemInfo[6] ? styles.arrowIconDown : styles.arrowIconRight} />
                        </div>
                      </div>
                      {showItemInfo[6] && (
                        <>
                          <div className={styles.fieldWrapper_startDateInput}>
                            <h5 className={styles.fechaCierre}>
                              Desde:
                            </h5>
                            <div className={styles.fieldWrapper_dateInputContainer}>
                              <Field
                                type="date"
                                id="startDate"
                                name="startDate"
                                className={styles.fieldWrapper_startDateInputPlaceholder}
                                readOnly={false}
                                min={isEditing ? minDateForSubProjects?.split("T")[0] : minDateForSubProjects}
                                value={minDateForSubProjects && minDateForSubProjects > values.startDateSub ? minDateForSubProjects : values.startDateSub}
                              />
                            </div>
                          </div>
                          <div className={styles.fieldWrapper_endDateInput}>
                            <h5 className={styles.fechaCierre}>
                              Hasta:
                            </h5>
                            <div className={styles.fieldWrapper_dateInputContainer}>
                              <Field
                                type="date"
                                id="endDate"
                                name="endDate"
                                placeholder="Seleccionar fecha"
                                className={styles.fieldWrapper_endDateInputPlaceholder}
                                readOnly={false}
                                max={isEditing ? maxDateForSubProjects?.split("T")[0] : maxDateForSubProjects}
                                min={values.startDateSub === "" ? minDateForSubProjects : values.startDateSub}
                                value={values.endDateSub === "" ? maxDateForSubProjects : values.endDateSub}
                              />
                            </div>
                          </div>
                        </>)}
                    </div>
                    <div className={styles.buttonContainer}>
                      <button
                        className={styles.subbutton_cancel}
                        onClick={() => {
                          resetForm(initialValues);
                          setShowSubProjectDocus(false);
                          resetNewSkill();
                          setSelectedRubro("");
                        }}
                      >
                        <span className={styles.trash}>
                          <Trash />
                        </span>
                        Cancelar
                      </button>
                      <button
                        className={styles.subbutton}
                        onClick={() => {
                          addHandler(values, initialValues, resetForm, isEditing, props.projectIdForSub);
                          setShowSubProjectDocus(false);
                        }}
                      >
                        <span className={styles.subbutton_add}>
                          <Plus />
                        </span>
                        <span className={styles.subbutton_text}>
                          Agregar rubro a cotizar
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>   
            </div>
          </Form>
        )}
      </Formik>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    subProjectsType: state.profile.subProjectsType,
    subProjectId: state.profile.subProjectId,
    subProjectStyleForm: state.profile.styleForm,
  };
};

export default connect(mapStateToProps, {
  setSendSubProject,
  postSubprojects,
  setProjectToEdit,
  closeMainModal,
  getSubprojectsType,
  resetNewSkill,
})(NuevoSubproyecto);
