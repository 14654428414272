import React, {useState, useEffect} from "react";
import styles from "./SubProyectosWrapper.module.scss";
import SubProyectoItem from "./SubProyectoItem/SubProyectoItem";
import { connect } from "react-redux";

const SubProyectosWrapper = ({ 
    isEditing, 
    type, 
    subProjects, 
    isPopUp,
    subProjectsToEdit, 
    maxDateForSubProjects,
    minDateForSubProjects,
    projectId,
    updatedSubProject
  }) => {

 
let items = isEditing ? subProjectsToEdit : subProjects;

return (
    <div className={styles.container}>
      <div className={`${styles.wrapper} ${items.length > 0 ? styles.active : ""}`}>
        <h5 className={styles.wrapper_headerTitle}>{isPopUp ? "Agregados" : "Rubros a cotizar"}</h5>
        {items.length > 0 ? (
          items.map((item, index) => {
            return <SubProyectoItem 
              subproject={item} 
              projectId={projectId}
              key={index} 
              index={index} 
              type={type} 
              maxDateForSubProjects={maxDateForSubProjects}
              minDateForSubProjects={minDateForSubProjects}
              isEditing={isEditing}/>;
          })
        ) : (
          <p>¡No hay rubros a cotizar en este proyecto!</p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    subProjects: state.profile.sendSubprojects,
    subQuotations: state.profile.sendSubQuotations,
    updatedSubProject: state.profile.projectToEdit.subproyecto,
  };
};

export default connect(mapStateToProps)(SubProyectosWrapper);
