import React, { useState, useEffect } from "react";
import styles from "./Profesionales.module.scss";
import { connect } from "react-redux";
import {
  getUsersRegisteredData,
  setGoBackToCategoryList,
  getAllUsersBySkills,
  getUsersBySkillName,
  openFilterUsersModal,
  closeFilterUsersModal,
} from "../../store/actions";
import Botonera from "../../components/Botonera/Botonera";
import RegisteredUsersCategory from "../../components/RegisteredUsers/RegisteredUsersCategory";
import FilterUsersModal from "../../components/UI/FilterUsersModal/FilterUsersModal";
import RegisteredUserItem from "../../components/RegisteredUsers/RegisteredUserItem";
import NewNav from "../../components/Nav/NewNav";
import styled from "styled-components";
import { Search, SlidersHorizontal, ChevronRight, Users, UserRoundX } from "lucide-react";
import usePremiumCheck from "../../hooks/usePremiumCheck";
import { AnimatePresence, motion } from "framer-motion";


const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  transition: { duration: 0.5 }
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 8%;
  padding-left: 8%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const Profesionales = ({
  usersRegisterData,
  getAllUsersBySkills,
  getUsersBySkillName,
  getUsersRegisteredData,
  setGoBackToCategoryList,
  completeSkillsList,
  selectedSkill,
  edifySpinner,
  filterUsersModal,
  openFilterUsersModal,
  closeFilterUsersModal,
  ...props }) => {

  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSkillsList, setSelectedSkillsList] = useState([]);
  const [initialState, setInitialState] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [noResultMessage, setNoResultMessage] = useState(" ");
  const [checkedItems, setCheckedItems] = useState({});

  const premiumCheck = usePremiumCheck();

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };


  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    getUsersRegisteredData();
    handleResize();
    getAllUsersBySkills();

  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const groupUsersBySkillNameAndCompletedFields = (users) => {
    const groupedUsers = {};

    users.forEach((user) => {
      if (user.skill) {
        user.skill.forEach((skill) => {
          const skillName = skill.skill.name;
          if (!groupedUsers[skillName]) {
            groupedUsers[skillName] = {
              users: [],
              userIds: new Set(),
            };
          }

          if (!groupedUsers[skillName].userIds.has(user.id)) {
            groupedUsers[skillName].users.push(user);
            groupedUsers[skillName].userIds.add(user.id);
          }
        });
      }
    });

    Object.keys(groupedUsers).forEach((key) => {
      groupedUsers[key] = groupedUsers[key].users;
    });

    return groupedUsers;
  };

  const groupedUsersBySkillAndCompletedFields = groupUsersBySkillNameAndCompletedFields(usersRegisterData);

  const handleSkillsToggle = (skillName) => {

    const updatedSelectedSkillsList = selectedSkillsList.includes(skillName)
      ? selectedSkillsList.filter((skill) => skill !== skillName)
      : [...selectedSkillsList, skillName];

    setSelectedSkillsList(updatedSelectedSkillsList);
    getUsersBySkillName(updatedSelectedSkillsList);

    if (updatedSelectedSkillsList.length === 0) {
      setInitialState(false);
      setGoBackToCategoryList(false);
    }

    // Update checkedItems state
    setCheckedItems((prevCheckedItems) => {
      const updatedCheckedItems = { ...prevCheckedItems };
      updatedSelectedSkillsList.forEach(skill => {
        updatedCheckedItems[skill] = true;
      });
      // Uncheck items that are no longer in the selectedSkillsList
      Object.keys(updatedCheckedItems).forEach(skill => {
        if (!updatedSelectedSkillsList.includes(skill)) {
          updatedCheckedItems[skill] = false;
        }
      });
      return updatedCheckedItems;
    });
  };

  const handleSearchClick = (searchValue) => {
    setInitialState(true)
    setShowInfo(true);
    handleSkillsToggle(searchValue.toLowerCase());
    setSuggestions([]);
    setInputValue("");
  };

  //Funciones del input 

  const normalizarAcentos = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleInputChange = (e) => {
    const search = e.target.value.toLowerCase();
    generateSuggestions(search);
    setInputValue(search);

    if (e.key === "Enter") {
      if (suggestions.length > 0) {
        const matchingSuggestion = suggestions.find(suggestion => normalizarAcentos(suggestion.name.toLowerCase()) === search);

        if (matchingSuggestion) {
          handleSearchClick(matchingSuggestion.name);
          setInputValue("");
          setNoResultMessage(" ");
        } else {
          setSuggestions([]);
          setNoResultMessage("La búsqueda no coincide con ninguna sugerencia");
        }
      } else {
        if (search !== "") {
          setNoResultMessage("No se encontraron resultados");
        }
      }
    }
  };

  useEffect(() => {
    if (inputValue.length > 3 && suggestions.length === 0) {
      setNoResultMessage("No se encontraron resultados");
    } else {
      setNoResultMessage(" ");
    }
  }, [suggestions]);

  const generateSuggestions = (search) => {
    if (search === "") {
      setSuggestions([]);
    } else {
      const newSuggestions = completeSkillsList.filter((skill) =>
        normalizarAcentos(skill.name.toLowerCase()).startsWith(search)
      );
      setSuggestions(newSuggestions);
    };
  };

  const resetCheckboxState = () => {
    setCheckedItems({});
  };

  const handleMakeSearch = () => {
    if (selectedSkillsList.length > 0) {
      getUsersBySkillName(selectedSkillsList);
      setInitialState(true)
      closeFilterUsersModal()
      resetCheckboxState();
    } else {
      if (selectedSkillsList.length === 0 && selectedSkill.length === 0) {
        setInitialState(false)
      }
    }
  }

  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile &&
        <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      <AnimatePresence>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}

          className={styles.container}>
          <div className={styles.headerContainer}>
            <div className={styles.toolTipContainer}>
              <div className={styles.toolTipContainer_text}>
                <h1 className={styles.toolTipContainer_text_header}>Encontrá el profesional perfecto para tu proyecto</h1>
                <p className={styles.toolTipContainer_text_description}>Conectamos tus necesidades con los mejores profesionales de la industria. Filtrá por especialidad, explorá perfiles verificados y encontrá el match perfecto para tu equipo.</p>
              </div>
            </div>
            <div className={styles.headerContainer_searchInput}>
              <div className={styles.headerContainer_searchInput_container}>
                <Search size={16} className={styles.headerContainer_searchInput_icon} />
                <input
                  className={styles.headerContainer_searchInput_input}
                  type="text"
                  placeholder="¿Qué especialidad estás buscando?"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleInputChange} />
                {noResultMessage !== " " && (
                  <ul className={styles.suggestions}>
                    <li className={styles.suggestions_item}>
                      <div className={styles.suggestions_item_container}>
                        <div className={styles.suggestions_item_icon}>
                          <UserRoundX size={20} />
                        </div>
                        <div className={styles.suggestions_item_name}>
                          <p className={styles.suggestions_item_name}>
                            {noResultMessage}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                )}
                {Array.isArray(suggestions) && suggestions.length > 0 && (
                  <ul className={styles.suggestions}>
                    {suggestions.map((suggestion) => (
                      <li className={styles.suggestions_item} key={suggestion.name}>
                        <div className={styles.suggestions_item_container} onClick={() => handleSearchClick(suggestion.name)}>
                          <div className={styles.suggestions_item_icon}>
                            <Users size={20} />
                          </div>
                          <div className={styles.suggestions_item_name}>
                            <p className={styles.suggestions_item_name}>
                              {suggestion.name}
                            </p>
                            <p className={styles.suggestions_item_subTitle}>
                              {suggestion.users === 1 ?
                                `${suggestion.users} profesional disonible` :
                                `${suggestion.users} profesionales disponibles`}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}

              </div>
              <div onClick={premiumCheck(openFilterUsersModal)} className={styles.headerContainer_searchInput_button}>
                <SlidersHorizontal size={20} />
                <span className={styles.headerContainer_searchInput_button_label}>Filtrar por categorías</span>
                {selectedSkillsList.length > 0 && (
                  <span className={styles.headerContainer_searchInput_button_count}>{selectedSkillsList.length}</span>
                )}
              </div>
              
            </div>
            {selectedSkillsList.length > 0 && (
                <div className={styles.selectedSkillsContainer}>
                  {selectedSkillsList.map((skill) => (
                    <div key={skill} className={styles.selectedSkillsContainer_skill}>
                      <p className={styles.selectedSkillsContainer_skill_text}>{skill}</p>
                      <div className={styles.selectedSkillsContainer_skill_close} onClick={() => handleSkillsToggle(skill)}>
                        <span className={styles.selectedSkillsContainer_skill_close_text}>x</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
          </div>
          <div className={styles.allCategoriesContainer}>
            {initialState ? (
              <div className={styles.infoContainer}>
                <RegisteredUsersCategory selectedSkillsList={selectedSkillsList} initialState={initialState} />
              </div>
            ) : (
              <div className={styles.infoContainer}>
                <div className={styles.buttonsContainer}>
                  {groupUsersBySkillNameAndCompletedFields &&
                    Object.entries(groupedUsersBySkillAndCompletedFields).map(([profession, user]) => (
                      <div key={profession} className={styles.categoryContainer}>
                        <div className={styles.categoryContainer_header}>
                          <h3 className={styles.categoryContainer_header_title}>{profession}</h3>
                          <button onClick={() => handleSearchClick(profession)} className={styles.categoryContainer_header_button}>
                            Ver todos
                            <ChevronRight size={16} />
                          </button>
                        </div>
                        <div className={styles.categoryContainer_users}>
                          {user.map((user) => (
                            <RegisteredUserItem
                              key={user.id}
                              user={user}
                              completedFields={user.completedFields}
                              stars={user.stars}
                              initialState={initialState}
                              portfolio={user.portfolio}
                              profession={profession} />
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
      {filterUsersModal && (
        <FilterUsersModal
          setShowInfo={setShowInfo}
          setIsLoading={setIsLoading}
          setSelectedSkillsList={setSelectedSkillsList}
          selectedSkillsList={selectedSkillsList}
          initialState={initialState}
          setInitialState={setInitialState}
          setCheckedItems={setCheckedItems}
          checkedItems={checkedItems}
          handleMakeSearch={handleMakeSearch}
          resetCheckboxState={resetCheckboxState}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    usersRegisterData: state.general.usersRegisteredData,
    userData: state.general.userData,
    edifySpinner: state.general.edifySpinner,
    filterUsersModal: state.general.filterUsersModal,
    completeSkillsList: state.register.skillsList,
    selectedSkill: state.register.selectedSkill,
  }
};

export default connect(mapStateToProps, {
  getUsersRegisteredData,
  getAllUsersBySkills,
  getUsersBySkillName,
  setGoBackToCategoryList,
  openFilterUsersModal,
  closeFilterUsersModal,
})(Profesionales);
