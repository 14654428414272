import React from "react";
import styles from "./UploadBudgetDisclaimer.module.scss";
import { Info, CircleHelp } from "lucide-react";

const UploadBudgetDisclaimer = () => {
    return (
        <div className={styles.container}>
            <Info size={20} className={styles.header_icon} />
            <div className={styles.body}>
                <h3 className={styles.header_title}>Pasos para cotizar</h3>
                <p>1. Solicita acceso a la documentación</p>
                <p>2. Descarga todos los documentos disponibles</p>
                <p>3. Revisa la documentación detalladamente</p>
                <p>4. Sube tu presupuesto usando el botón "Enviar presupuesto"</p>
                <div className={styles.footer}>
                    <CircleHelp size={16} />
                    <p>¿Necesitas ayuda? Contáctanos</p>
                </div>
            </div>
        </div>

    );
}

export default UploadBudgetDisclaimer;
