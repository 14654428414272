export const variables = [
    { id: 4, name: "Tipo de Cambio Minorista ($ por USD)" },
    { id: 5, name: "Tipo de Cambio Mayorista ($ por USD)" },
    { id: 6, name: "Tasa de Política Monetaria (en % n.a.)" },
    { id: 27, name: "Inflación mensual (variación en %)" },
    { id: 28, name: "Inflación interanual (variación en % i.a.)" },
    { id: 29, name: "Inflación esperada - REM próximos 12 meses" },
    { id: 31, name: "Unidad de Valor Adquisitivo (UVA)" },
    { id: 32, name: "Unidad de Vivienda (UVI)" },
    { id: 40, name: "Índice para Contratos de Locación (ICL-Ley 27.551)" }
  ];
  