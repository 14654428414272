import React from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { TrendingUp, DollarSign, Percent } from 'lucide-react';
import styles from './FinancialData.module.scss';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

// Función para convertir una fecha en formato 'DD/MM/YY' a un objeto Date
const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('/');
    return new Date(`20${year}-${month}-${day}`);
};

// Generar rango continuo de fechas
const generateDateRange = (startDate, endDate) => {
    const start = parseDate(startDate);
    const end = parseDate(endDate);
    const dateArray = [];

    while (start <= end) {
        dateArray.push(
            `${start.getDate().toString().padStart(2, '0')}/${(start.getMonth() + 1)
                .toString()
                .padStart(2, '0')}/${start.getFullYear().toString().slice(-2)}`
        );
        start.setDate(start.getDate() + 1);
    }

    return dateArray;
};

// Rellenar datos faltantes
const fillMissingData = (data, dateRange) => {
    const filledData = [];
    let lastValue = null;

    dateRange.forEach((date) => {
        // Convertir las fechas a objetos Date para una comparación correcta
        const matchingItem = data.find(
            (item) => parseDate(item.fecha).getTime() === parseDate(date).getTime()
        );

        if (matchingItem) {
            lastValue = matchingItem.valor;
            filledData.push({ fecha: date, valor: matchingItem.valor });
        } else if (lastValue !== null) {
            filledData.push({ fecha: date, valor: lastValue });
        }
    });

    return filledData;
};

// Función para formatear fechas y obtener los últimos tres meses únicos
const getLastThreeMonthsFromData = (data) => {
    const monthsMap = new Map();

    data.forEach((item) => {
        const [month, year] = item.fecha.split('/');
        const dateKey = `${year}-${month}`;
        if (!monthsMap.has(dateKey)) {
            monthsMap.set(dateKey, `${month}/${year}`);
        }
    });

    return Array.from(monthsMap.values()).slice(-3).reverse();
};

const FinancialData = ({ data }) => {
    const inflationRelatedData = data.filter((item) =>
        item.name.toLowerCase().includes('inflación')
    );

    const lastThreeMonths = getLastThreeMonthsFromData(inflationRelatedData);

    // Extraer los valores correspondientes a esos meses
    const inflationValues = lastThreeMonths.map((month) => {
        const matchingItem = inflationRelatedData.find((item) =>
            item.fecha.includes(month.split('/')[0]) &&
            item.fecha.includes(month.split('/')[1])
        );
        return matchingItem ? matchingItem.valor : null;
    });

    // Datos para el gráfico de barras

    const inflationValuesMax = Math.max(...inflationValues);

    const inflationMargin = 0.5;

    const inflationYMin = 0
    const inflationYMax = inflationValuesMax + inflationMargin;

    const inflationData = {
        labels: lastThreeMonths,
        datasets: [
            {
                label: 'Inflación Mensual',
                data: inflationValues,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    const inflationOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                min: inflationYMin,
                max: inflationYMax,
            },
        },
    };

    const exchangeRateData = data.filter((item) =>
        item.name.toLowerCase().includes('tipo de cambio mayorista')
    );

    // Obtener todas las fechas
    const allDates = exchangeRateData.map((item) => item.fecha);

    // Obtener la fecha mínima y máxima
    const minDate = allDates.reduce(
        (min, curr) => (parseDate(curr) < parseDate(min) ? curr : min),
        allDates[0]
    );

    const maxDate = allDates.reduce(
        (max, curr) => (parseDate(curr) > parseDate(max) ? curr : max),
        allDates[0]
    );

    // Generar el rango de fechas
    const dateRange = generateDateRange(minDate, maxDate);

    // Rellenar los datos
    const filledExchangeRateData = fillMissingData(exchangeRateData, dateRange);

    // Datos para el gráfico de línea
    const exchangeRateValues = filledExchangeRateData.map((item) => item.valor);

    const firstValue = Math.min(...exchangeRateValues);
    const lastValue = Math.max(...exchangeRateValues);

    const margin = 5;

    const yMin = firstValue - margin;
    const yMax = lastValue + margin;
    const chartData = {
        labels: filledExchangeRateData.map((item) => item.fecha),
        datasets: [
            {
                label: 'Tipo de Cambio Mayorista',
                data: filledExchangeRateData.map((item) => item.valor),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                tension: 0.4,
            },
        ],
    };

    // Configuración del gráfico

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                min: yMin,
                max: yMax,
            },
        },
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2 className={styles.title}>Datos Económicos</h2>
                <div className={styles.icons}>
                    <TrendingUp className={styles.iconBlue} />
                </div>
            </div>
            <div className={styles.charts}>
                <div className={styles.chartCard}>
                    <h3 className={styles.chartTitle}>Tipo de cambio mayorista</h3>
                    <div className={styles.chart}>
                        <Line data={chartData} options={chartOptions} />
                    </div>
                </div>
                <div className={styles.chartCard}>
                    <h3 className={styles.chartTitle}>Inflación Mensual</h3>
                    <div className={styles.chart}>
                        <Bar data={inflationData} options={inflationOptions} />
                    </div>
                </div>
                <div className={styles.latestValuesGrid}>
                    {data
                        .reduce((acc, item) => {
                            const isExchangeRate = item.name.toLowerCase().includes('tipo de cambio');
                            const isInflation = item.name.toLowerCase().includes('inflación mensual');

                            if ((isExchangeRate || isInflation) || acc.some((el) => el.name === item.name)) {
                                return acc;
                            }

                            return [...acc, item];
                        }, [])
                        .map((item, index) => (
                            <div key={index} className={styles.valueCard}>
                                <h4 className={styles.valueName}>{item.name}</h4>
                                <p className={styles.valueAmount}>{item.valor}</p>
                                <p className={styles.valueDate}>{item.fecha}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default FinancialData;


