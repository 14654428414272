import React from "react";
import styles from "./PostulationItem.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "../../../assets/svg/arrowRightIcon.svg"
import { ReactComponent as ClockIcon } from "../../../assets/svg/clockIcon.svg";

const PostulationItem = ({ postulation, coverImage }) => {

    const navigate = useNavigate();

    const handleRedirectToProject = () => {
        navigate(`/obrasprivadas/${postulation.proyecto.id}`);
    };


    const translateStatusPostulation = (status) => {
        switch (status) {
            case 'SEND':
                return 'Pendiente';
            case 'ALLOWED':
                return 'Aceptada';
            case 'NEGATED':
                return 'Rechazada';
            default:
                return '';
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.container_image}>
                <img className={styles.container_image_img} src={coverImage} alt="" />
                <p className={styles.container_info_location}>{postulation?.proyecto?.zone}</p>
                <div className={`${styles.container_postulationStatus} ${postulation?.status === "SEND" && styles.postulationSend} 
                    ${postulation?.status === "ALLOWED" && styles.postulationAllowed} ${postulation?.status === "NEGATED" && styles.postulationDenied}`}>
                    <p>{translateStatusPostulation(postulation?.status)}</p>
                </div>
            </div>
            <div className={styles.container_info}>
                <div className={styles.container_info_text}>
                    <div className={styles.container_info_text_pill} />
                    <h4 className={styles.container_info_text_title}>{postulation?.name}</h4>
                    <p className={styles.container_info_text_description}>{postulation?.proyecto?.description}</p>
                </div>
                <div className={styles.container_info_footer}>
                    <div className={styles.container_info_footer_clock}>
                        <ClockIcon width={15} height={15} />
                        <relative-time lang="es" tense="past" datetime={postulation?.proyecto?.createdAt}></relative-time>
                    </div>
                    <div className={styles.container_info_footer_comments}>
                    </div>
                </div>
            </div>
            <div className={styles.container_buttons}>
                <div className={styles.container_buttons_title} onClick={handleRedirectToProject}>
                    <p>Ver detalles</p>
                    <ArrowRightIcon height={20} width={20} />
                </div>
            </div>
        </div>

    )
};

export default PostulationItem;
