import React, { useState } from "react";
import styles from "./SummaryPanel.module.scss";
import { connect } from "react-redux";
import useGetTodayDate from "../../../hooks/useGetTodayDate";
import { motion } from "framer-motion";
import { Send } from "lucide-react";

const variants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

const SummaryPanel = ({ totals, userData, setEndDate, endDate, handleSendQuotation, id, closeModal, note, setNote }) => {
  const [isIvaChecked, setIsIvaChecked] = useState(false);

  const fullName = userData.type === "COMPANY"
    ? userData.razon_social
    : `${userData.name} ${userData.last_name}`;

  const calculateIva = (subtotal) => Math.round(subtotal * 0.21);

  const total = isIvaChecked
    ? Math.round(calculateIva(totals.subtotal) + totals.subtotal)
    : Math.round(totals.subtotal);

  const todayDate = useGetTodayDate();

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate) {
      setEndDate(selectedDate);
    }
  };

  const convertDateToISOFormat = (date) => {
    const [dd, mm, yyyy] = date.split('/');
    return `${yyyy}-${mm}-${dd}`;
  };

 
  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      transition={{ duration: 0.3 }}
      className={styles.cotizador}>
      <div className={styles.container}>
        <div className={styles.title}>
          <h2 className={styles.title_text}>Condiciones finales</h2>
          <button className={styles.closeButton} onClick={closeModal}>X</button>
        </div>
        <div className={styles.user}>
          <img className={styles.avatar} src={userData.image_profile} alt="user" />
          <div className={styles.name}>{fullName}</div>
        </div>
        <div className={styles.bodyContainer}>
        <div className={styles.details}>
          <div className={styles.detailRow}>
            Válido hasta:
            <div className={styles.date}>
              <input
                type="date"
                value={endDate}
                onChange={handleDateChange}
                className={styles.dateInput}
                onMouseDown={(e) => e.preventDefault()}
                onFocus={(e) => e.target.showPicker()}
                min={convertDateToISOFormat(todayDate)}
              />
            </div>
          </div>

          <div className={styles.checkboxRow}>
            ¿Deseas incluir IVA?
              <input
                type="checkbox"
                className={styles.checkbox_input}
                checked={isIvaChecked}
                onChange={(event) => setIsIvaChecked(event.target.checked)}
              />
          </div>
          <div className={styles.detailRow}>
            <div>Subtotal</div>
            <div>{totals.subtotal}</div>
          </div>
          <div className={styles.detailRow}>
            <div>IVA*</div>
            <div>{isIvaChecked ? calculateIva(totals.subtotal) : 0}</div>
          </div>
          <div className={`${styles.detailRow} ${styles.detailRow_total}`}>
            <div>TOTAL</div>
            <div>{total}</div>
          </div>
          <div className={styles.detailNote}>
            <div className={styles.detailNote_title}>Condiciones especiales</div>
            <textarea
              className={styles.detailNote_noteInput}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.note}>
          *Impuesto al Valor Agregado (21%)
        </div>
        </div>
        <div className={styles.buttons}>
          <button
            className={`${styles.button} ${styles.button_send}`}
            onClick={() => handleSendQuotation()}
            disabled={!endDate}
          >
            <Send style={{minWidth: "1.6rem", maxWidth: "1.6rem", minHeight: "1.6rem", maxHeight: "1.6rem"}} />
            {!endDate ? "Selecciona una fecha" : "Enviar presupuesto"}
          </button>
        </div>
      </div>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.general.userData,
});

export default connect(mapStateToProps)(SummaryPanel);
