import React, { useState, useEffect, useRef } from "react";
import styles from "./HeaderNav.module.scss";
import { connect } from "react-redux";
import Logo from "../../assets/images/logoBig.png";
import SolicitudesWrapper from "../Solicitudes/SolicitudesWrapper/SolicitudesWrapper";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ReactComponent as Search } from "../../assets/svg/misProyectosLittle.svg";
import { ReactComponent as MarketPlace } from "../../assets/svg/marketplace.svg";
import { ReactComponent as ProyectosActive } from "../../assets/svg/nav-proyectos-active.svg";
import { ReactComponent as Faq } from "../../assets/svg/FAQ.svg";
import { ReactComponent as FaqActive } from "../../assets/svg/FAQActive.svg";
import { ReactComponent as Notif } from "../../assets/svg/nav-notif.svg";
import { ReactComponent as NotifActive } from "../../assets/svg/nav-notif-active.svg";
import { ReactComponent as WorkOffer } from "../../assets/svg/trabajadoresIcon.svg";
import { X } from "lucide-react";
import { ReactComponent as CreatePublicationIcon } from "../../assets/svg/crearPublicacion.svg";
import {
    closeSuscriptionModal,
    setActivateFreeTrial,
    openSuscriptionModal,
    openFeedTextModal,
    resetProjectToEdit,
    userInfoNavStyle,
    setProjectFormStyle,
    openWorkOfferModal,
    closeWorkOfferModal,
} from "../../store/actions";
import Tooltip from "../UI/ToolTip/ToolTip";



const HeaderNav = ({
    userData,
    isUserPremium,
    openFeedTextModal,
    openSuscriptionModal,
    openWorkOfferModal,
    resetProjectToEdit,
    userInfoNavStyle,
    newNotifications,
}) => {
    const [modalPublication, setModalPublication] = useState(false);
    const [isOpenProject, setModalOpenProject] = useState(false);
    const [changedLocation, setChangedLocation] = useState(false);
    const [isActive, setIsActive] = useState(null);
    const [showNotiications, setShowNotifications] = useState(false);
    const [showToolTip, setShowToolTip] = useState({
        notifications: { show: false, text: "Notificaciones" },
        faq: { show: false, text: "Ayuda" },
        createPublication: { show: false, text: "Crear publicación" },
    });
    const navigate = useNavigate();
    const notificationRef = useRef(null);

    const handleClickOutside = (event) => {
        if (notificationRef.current && !notificationRef.current.contains(event.target)) {
            setShowNotifications(false);
        }
    };

    useEffect(() => {
        if (showNotiications) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showNotiications]);

    const handleHelp = () => {
        setModalPublication(false);
        navigate("/faq");
    };

    const handleClose = () => {
        setModalPublication(false);
        userInfoNavStyle(false)
    }

    const handleOpenModalPublication = () => {
        setProjectFormStyle(false);
        resetProjectToEdit();
        setModalPublication(true);
        userInfoNavStyle(false);
    };

    const handleNewOpenProject = () => {
        if (isUserPremium) {
            navigate("/obrasprivadas/crear");
        } else {
            openSuscriptionModal();
        }
    };

    const handleNewMaterialBudget = () => {
        if (isUserPremium) {
            navigate("/cotizaciondemateriales/crear");
        } else {
            openSuscriptionModal();
        }
    };

    const handleNewFeedPost = () => {
        if (isUserPremium) {
            openFeedTextModal();
        } else {
            openSuscriptionModal();
        }
    };


    const completeName =
        userData.type === "COMPANY"
            ? userData.razon_social
            : `${userData.name} + " " + ${userData.last_name}`;

    const handleRedirect = (title) => {
        setChangedLocation(!changedLocation);
        if (title === "Perfil") {
            userInfoNavStyle(false)
            navigate(`/profesionales/${userData.id}/${completeName}`);
        } if (title === "notificaciones") {
            navigate("/solicitudes");
        } if (title === "FAQ") {
            navigate("/faq");
        }
    }

    useEffect(() => {
        if (
            navigate?.location?.pathname ===
            `/profesionales/${userData.id}/${completeName}`
        ) {
            setIsActive("Perfil");
        } if (
            navigate?.location?.pathname === "/solicitudes"
        ) {
            setIsActive("notificaciones");
        } if (
            navigate?.location?.pathname === "/faq"
        ) {
            setIsActive("FAQ");
        } else if (
            navigate?.location?.pathname !== "/solicitudes" &&
            navigate?.location?.pathname !== `/profesionales/${userData.id}/${completeName}` &&
            navigate?.location?.pathname !== "/faq"
        ) {
            setIsActive(null);
        }
    }, [navigate?.location?.pathname]);

    //CARDS

    const modalPublicationContent = [
        {
            title: "Obra privada",
            logo: <Search width={24} height={24} />,
            subTitle: "Gestiona proyectos de construcción",
            items: [
                "Indica el nombre y lugar.",
                "Programa las fechas para la recepción de presupuestos.",
                "Selecciona un rubro y adjunta documentos.",
            ],
            handleClick: handleNewOpenProject,
            color: "#234BA2",
            backgroundColor: "#E3EDF9",
            border: "2px solid #A7C7F5",
            hover: "#DBEAFE",
        },
        {
            title: "Cotización de materiales",
            logo: <MarketPlace width={24} height={24} className={styles.cardLogo_quotation} />,
            subTitle: "Solicita presupuestos de materiales",
            items: [
                "Lista los materiales necesarios.",
                "Especifica cantidades y detalles.",
                "Recibe múltiples cotizaciones.",
            ],
            handleClick: handleNewMaterialBudget,
            color: "#45A7FF",
            border: "2px solid #ADDFF7",
            backgroundColor: "#E0F3FF",
            hover: "#D9EFFF",
        },
        {
            title: "Artículo en el muro",
            subTitle: "Comparte contenido con la comunidad",
            logo: <ProyectosActive width={24} height={24} />,
            items: [
                "Comparte noticias y actualizaciones.",
                "Inicia discusiones relevantes.",
                "Conecta con otros profesionales.",
            ],
            handleClick: handleNewFeedPost,
            color: "#7000FF",
            border: "2px solid #CAADF7",
            backgroundColor: "#F0E8FF",
            hover: "#E3D5FF",
        },
        {
            title: "Oferta de trabajo",
            subTitle: "Publica oportunidades laborales",
            logo: <WorkOffer width={24} height={24} className={styles.cardLogo_workOffer} />,
            items: [
                "Define requisitos del puesto.",
                "Establece condiciones laborales.",
                "Recibe postulaciones de profesionales.",
            ],
            handleClick: openWorkOfferModal,
            color: "#22af86",
            border: "2px solid #B0E5D3",
            backgroundColor: "#E2F7F1",
            hover: "#D3F0E8",
        },
    ];

    const handleTooltip = (tooltip) => {
        setShowToolTip((prevState) => ({
            ...prevState,
            [tooltip]: { ...prevState[tooltip], show: !prevState[tooltip].show },
        }));
    };

    const handleBackToHome = () => {
        if (navigate?.location?.pathname !== "/dashboard") {
            navigate("/dashboard");
        }
    }

    return (
        <nav className={styles.nav}>
            <div className={styles.nav_body}>
                <div className={styles.logoContainer} onClick={handleBackToHome}>
                    <img src={Logo} alt="Logo" className={styles.logoContainer_logo} />
                </div>
                <div className={styles.navIcons}>
                    
                    <div className={styles.nav_navItem}>
                        <button className={styles.nav_navItem_createButton} onClick={() => handleOpenModalPublication()}>
                            <CreatePublicationIcon width={15} height={15} />
                            Crear publicación
                        </button>
                    </div>
                    <div className={`${styles.navIcons_navItemIcon} ${isActive === "FAQ" && styles.isActive}`}
                        onMouseEnter={() => handleTooltip("faq")}
                        onMouseLeave={() => handleTooltip("faq")}
                        onClick={() => handleRedirect("FAQ")}>
                        <div className={styles.navIcons_navItemIcon_notification}>
                            {isActive === "FAQ" ? (
                                <FaqActive width={20} height={20} />
                            ) : (
                                <Faq width={20} height={20} />
                            )}
                        </div>
                        {showToolTip.faq.show && (
                            <Tooltip text={showToolTip.faq.text} />
                        )}
                    </div>
                    <div className={`${styles.navIcons_navItemIcon} ${isActive === "notificaciones" && styles.isActive}`}
                        onMouseEnter={() => handleTooltip("notifications")}
                        onMouseLeave={() => handleTooltip("notifications")}
                        onClick={() => setShowNotifications(!showNotiications)}>
                        <div className={styles.navIcons_navItemIcon_notification}>
                            {newNotifications > 0 && (
                                <div className={styles.navIcons_navItemNotification}>
                                    {newNotifications}
                                </div>
                            )}
                            {isActive === "notificaciones" ? (
                                <NotifActive width={20} height={20} />
                            ) : (
                                <Notif width={20} height={20} />
                            )}

                        </div>
                        {showNotiications && (
                            <div ref={notificationRef}>
                                <SolicitudesWrapper />
                            </div>
                        )}
                        {showToolTip.notifications.show && (
                            <Tooltip text={showToolTip.notifications.text} fromHeaderNav />
                        )}
                    </div>

                </div>
                {modalPublication && (
                    <motion.div
                        className={styles.modal}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <div className={styles.modal_container}>
                            <div className={styles.modal_container_body}>
                                    <div className={styles.modal_container_title}>
                                        ¿Qué tipo de publicación querés crear?
                                        <div className={styles.modal_container_close} onClick={handleClose}>
                                            <X width={20} height={20} />
                                        </div>
                                    </div>
                                    <div className={styles.modal_container_body_container}>
                                        {modalPublicationContent.map((content, index) => (
                                            <motion.div
                                                key={index}
                                                whileHover={{ scale: 1.02, backgroundColor: content.hover }}
                                                transition={{ duration: 0.2 }}
                                                className={styles.cardFront}
                                                style={{ border: content.border, background: content.backgroundColor }}
                                                onClick={() => {
                                                    content.handleClick();
                                                    setModalPublication(false);
                                                }}
                                            >
                                                    {content.logo}
                                                    <div className={styles.cardTitle}>
                                                        {content.title}
                                                    </div>
                                                    <div className={styles.cardBody}>
                                                        <span className={styles.cardSubTitle}>{content.subTitle}</span>
                                                        <ul className={styles.cardItems}>
                                                            {content.items.map((item, i) => (
                                                                <li className={styles.cardItems_item} key={i}>{item}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                            </motion.div>
                                        ))}
                                    </div>
                                </div>
                            <div className={styles.modal_container_footer} onClick={handleHelp}>
                                ¿Necesitas ayuda?
                                <span className={styles.modal_container_footer_help}>Ver guía</span>
                            </div>
                        </div>
                    </motion.div>
                )}
            </div>
        </nav>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state.general.userData,
        isUserPremium: state.general.isUserPremium,
        newNotifications: state.dashboard.newNotifications,
        solicitudesList: state.dashboard.solicitudesList,
    };
}

export default connect(mapStateToProps, {
    closeSuscriptionModal,
    setActivateFreeTrial,
    openFeedTextModal,
    openSuscriptionModal,
    resetProjectToEdit,
    userInfoNavStyle,
    openWorkOfferModal,
    closeWorkOfferModal,
})(HeaderNav);