import React, { memo, useState, useRef, useEffect } from "react";
import styles from "./MainModal.module.scss";
import { closeMainModal } from "../../../store/actions/profileActions";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as SuccessIcon } from "../../../assets/svg/success.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/svg/error.svg";
import { ReactComponent as EmailIcon } from "../../../assets/svg/email.svg";
import { ReactComponent as WarningIcon } from "../../../assets/svg/modalAtencion.svg";
import { X } from "lucide-react";

const backdropVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modalVariants = {
  hidden: { opacity: 0, scale: 1.1 },
  visible: {
    opacity: 1,
    scale: 1.0,
    transition: { delay: 0.2 },
  },
};

const MainModal = ({
  closeMainModal,
  mainModalConfig: {
    isSuccess,
    dismiss,
    title,
    body,
    confirmHandler,
    customCloseMainModal,
    yesLabel = "Si",
    noLabel = "Volver",
    noActionNeeded,
    iconType,
  },
}) => {
  const [isButtonShrinking, setIsButtonShrinking] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(null);

  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current && !buttonWidth) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, []);


  const handleClose = () => {
    if (customCloseMainModal) {
      customCloseMainModal();
    } else {
      closeMainModal();
    }
  };

  const renderIcon = (type, size = "3.2rem") => {
    const icons = {
      success: <SuccessIcon width={size} height={size} />,
      error: <ErrorIcon width={size} height={size} />,
      email: <EmailIcon width="150" height={size} />,
      warning: <WarningIcon width={size} height={size} />,
    };
    return icons[type] || null;
  };

  const iconClass = iconType ? styles[`MainModal_${iconType}`] : styles.MainModal_default;

  const handleConfirm = () => {
    if (buttonWidth) {
      setIsButtonShrinking(true);
      setTimeout(() => {
        confirmHandler();
        setTimeout(() => {
          setIsButtonShrinking(false);
          setButtonWidth(null);
        }, 300);
      }, 300);
    }
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        className={styles.MainModal_overlay}
        variants={backdropVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        onClick={(event) => event.stopPropagation()}
      >
        <motion.div
          className={styles.MainModal}
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >

          <div className={`${styles.MainModal_body} ${iconClass}`}>
            <button
              className={styles.MainModal_closeButton}
              onClick={dismiss ? closeMainModal : handleClose}
              aria-label="Cerrar modal"
            >
              <X size={24} />
            </button>
            <div className={styles.MainModal_bodyContainer}>
              {iconType && (
                <div className={styles.iconContainer}>{renderIcon(iconType)}</div>
              )}
              <h3
                className={`${styles.MainModal_title} ${styles[`MainModal_title_${iconType}`] || styles.MainModal_title_default
                  }`}
              >
                {title}
              </h3>
              <div
                className={`${styles.bodyTxt} ${styles[`bodyTxt_${iconType}`] || styles.bodyTxt_default
                  }`}
              >
                {body}
              </div>
            </div>
            {!isSuccess || !noActionNeeded ? (
              <div className={styles.MainModal_options}>
                {isSuccess ? (
                  <div
                    className={`${styles.MainModal_options_decline} ${styles[`MainModal_options_success_${iconType}`] ||
                      styles.MainModal_options_success_default
                      }`}
                    onClick={handleClose}
                  >
                    {noLabel}
                  </div>
                ) : (
                  <>
                    <div
                      className={`${styles.MainModal_options_decline} ${styles[`MainModal_options_decline_${iconType}`] ||
                        styles.MainModal_options_decline_default
                        }`}
                      onClick={handleClose}
                    >
                      {noLabel}
                    </div>
                    <div
                      ref={buttonRef}
                      className={`${styles.MainModal_options_success} ${styles[`MainModal_options_success_${iconType}`] ||
                        styles.MainModal_options_success_default
                        }`}
                      onClick={handleConfirm}
                      style={{
                        width: buttonWidth ? `${buttonWidth}px` : 'auto',
                        transform: isButtonShrinking ? 'scaleX(0)' : 'scaleX(1)',
                        transformOrigin: 'center',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {yesLabel}
                    </div>
                  </>
                )}
              </div>
            ) : null}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

const mapStateToProps = (state) => ({
  mainModalConfig: state.general.mainModalConfig,
});

export default connect(mapStateToProps, { closeMainModal })(memo(MainModal));


