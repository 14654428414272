import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getQuotationById,
  setQuotationToEdit,
} from "../../../store/actions";
import Botonera from "../../Botonera/Botonera";
import { useParams } from "react-router-dom";
import styles from "./PublicQuotationById.module.scss";
import { Building2, Calendar, ChevronDown, ChevronUp, DollarSign, Download, MapPin } from "lucide-react";
import RegisteredUserProfileLoader from "../../RegisteredUsers/RegisteredUsersSkeleton/RegisteredUserProfileLoader";
import styled from "styled-components";

const Container = styled.div`
   display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 8%;
  padding-left: 8%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const PublicQuotationById = ({
  publicQuotation,
  getQuotationById,
  userData,
  ...props }) => {
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();
  // Dividimos la descripción en líneas usando '\n'
  const processDescription = (desc) => {
    if (!desc) return { isText: false, lines: [] };

    const lines = desc.split('\r\n').filter((line) => line.trim() !== '');

    // Si no hay saltos de línea significativos, consideramos que es un texto plano
    if (lines.length <= 1) {
      return { isText: true, text: desc.trim() };
    }

    const result = [];
    for (let i = 0; i < lines?.length; i += 2) {
      const key = lines[i]?.trim();
      const value = lines[i + 1]?.trim();
      if (key && value) {
        result.push({ key, value });
      }
    }
    return { isText: false, lines: result };
  };

  const { isText, lines, text } = processDescription(publicQuotation?.description);
  const descriptionLines = isText ? [] : lines;
  const visibleLines = isDescriptionExpanded ? descriptionLines : descriptionLines.slice(0, 5);


  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 768);
    setMobileStylesFromParent(windowWidth < 768);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
    getQuotationById(id, false, true);
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!publicQuotation || publicQuotation?.id !== id) {
      setIsLoading(true);
      getQuotationById(id, false, true);
    } else {
      setIsLoading(false);
    }
  }, [publicQuotation, id]);

  //Formateamos fechas
  const formatDate = (date) => {
    const utcDate = new Date(date);
    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth() + 1;
    const day = utcDate.getUTCDate();
    return `${day}/${month}/${year}`;
  };

  //Formateamos el monto a mostrar
  const formatMoney = (amount) => {
    return new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
<Container>
    <div className={styles.container}>
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      {isLoading ? (
        <RegisteredUserProfileLoader />
      ) : (
        <div className={styles.content}>
          <div className={styles.header}>
            <p className={styles.subtitle}>Materiales a licitar Públicos</p>
            <h1 className={styles.title}>
              {publicQuotation?.name}
            </h1>
          </div>
          <div className={styles.layout}>
            <div className={styles.mainContent}>
              <div className={styles.card}>
                <h2 className={styles.cardTitle}>Información General</h2>
                <div className={styles.infoList}>
                  <div className={styles.infoItem}>
                    <MapPin className={styles.icon} />
                    <div>
                      <p className={styles.infoTitle}>Ubicación</p>
                      <p className={styles.infoContent}>{publicQuotation?.jurisdiccion?.name}</p>
                    </div>
                  </div>
                  <div className={styles.infoItem}>
                    <Calendar className={styles.icon} />
                    <div>
                      <p className={styles.infoTitle}>Fecha de apertura</p>
                      <p className={styles.infoContent}>{formatDate(publicQuotation?.startDate)}</p>
                    </div>
                  </div>
                  <div className={styles.infoItem}>
                    <DollarSign className={styles.icon} />
                    <div>
                      <p className={styles.infoTitle}>Moneda</p>
                      <p className={styles.infoContent}>{publicQuotation?.currency !== "undefined" ? publicQuotation?.currency : "Pesos Arg"}</p>
                    </div>
                  </div>

                  <div className={styles.infoItem}>
                    <DollarSign className={styles.icon} />
                    <div>
                      <p className={styles.infoTitle}>Presupuesto oficial</p>
                      <p className={styles.infoContent}>{formatMoney(publicQuotation?.monto)}</p>
                    </div>
                  </div>
                  <div className={styles.infoItem1}>
                    <h3 className={styles.infoTitle}>Descripcion</h3>
                    <div className={styles.infoContent}>
                      {isText ? (
                        <p className={styles.descriptionText}>{text}</p>
                      ) : (
                        visibleLines.map((line, index) => (
                          <div key={index} className={styles.infoLine}>
                            {line.key && <span className={styles.infoSubtitle}>{line.key}:</span>}
                            <span className={styles.infoValue}>{line.value}</span>
                          </div>
                        ))
                      )}
                    </div>
                    {descriptionLines.length > 3 && (
                      <button
                        className={styles.expandButton}
                        onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
                      >
                        {isDescriptionExpanded ? 'Ver menos' : 'Ver más'}
                        {isDescriptionExpanded ? <ChevronUp className={styles.buttonIcon} /> : <ChevronDown className={styles.buttonIcon} />}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.sidebar}>
              <div className={styles.card}>
                <h2 className={styles.cardTitle}>Comitente</h2>
                <div className={styles.infoItem}>
                  <Building2 className={styles.icon} />
                  <div>
                    <p className={styles.infoItem_item}>{publicQuotation?.comitente}</p>
                  </div>
                </div>
              </div>

              <div className={styles.card}>
                <h2 className={styles.cardTitle}>Tipo de Obra</h2>
                <p className={styles.infoContent}>{publicQuotation?.obra_type?.name ? publicQuotation?.obra_type?.name : "No informado"}</p>
              </div>
              <div className={styles.card}>
                <h2 className={styles.cardTitle}>Organismo</h2>
                <p className={styles.infoContent}>{publicQuotation?.organismo?.name ? publicQuotation?.organismo?.name : "No informado"}</p>
              </div>
              <div className={styles.card}>
                <h2 className={styles.cardTitle}>Documentación</h2>
                <a href={publicQuotation?.link} target="_blank" rel="noreferrer" className={styles.downloadButton}>  <Download className={styles.buttonIcon} />
                  Descargar pliego</a>
              </div>

            </div>
          </div>
        </div>
      )}
    </div >
</Container>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    publicQuotation: state.profile.publicQuotation.quotation,
  };
}

export default connect(mapStateToProps, {
  getQuotationById,
  setQuotationToEdit,
})(PublicQuotationById); 