import { useDispatch, useSelector } from "react-redux";
import { openSuscriptionModal } from "../store/actions";


export default function usePremiumCheck() {
    const dispatch = useDispatch();
    const isUserPremium = useSelector((state) => state.general.isUserPremium);

    const premiumCheck = (onClickHandler, ...args) => {
        if (isUserPremium) {
            return () => onClickHandler(...args); 
        } else {
            return () => dispatch(openSuscriptionModal()); 
        }
    };

    return premiumCheck;
}
