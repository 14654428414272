import React, { useState, useEffect } from "react";
import styles from "./SubProjectInformation.module.scss";
import { connect } from "react-redux";
import { sendBudget, getSubProjectBudgets } from "../../../../store/actions";
import { FileText, Download, Ban, Link, Upload, Clock, CheckCheck, CloudUpload, X, File, FileQuestion } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import RegisteredUserProfileLoader from "../../../RegisteredUsers/RegisteredUsersSkeleton/RegisteredUserProfileLoader";

const variants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: { opacity: 1, scale: 1 },
    transition: { duration: 0.5 },
};

const SubProjectInformation = ({
    subproyecto,
    postulationStatus,
    sendBudget,
    userData,
    type,
    getSubProjectBudgets,
    mainProjectId,
    subProjectBudgets,
    handlePostulationButton,
    isMyProject,
}) => {
    const [fileName, setFileName] = useState(
        (subproyecto && subproyecto.fileName) || ""
    );
    const [fileUpload, setFileUpload] = useState(false);
    const [budgetToSend, setBudgetToSend] = useState(null);
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [hasSentBudgets, setHasSentBudgets] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isFileOrLink, setIsFileOrLink] = useState(false);
    const dsa = type === "subquotation" ? "quotation_payroll" : "budget_payroll";
    const projectIdWithSubprojectName = mainProjectId + '_' + subproyecto.name;


    useEffect(() => {
        getSubProjectBudgets(subproyecto.id);

        if (subproyecto?.link === "" || subproyecto?.link === "undefined" || subproyecto?.link === null) {
            setIsFileOrLink(true);
        }
    }, []);

    const uploadFile = (file) => {
        if (!file) {
            return;
        }
        setBudgetToSend(file);
        setFileName(file.name);
        setFileUpload(true);
    };

    const handleSendBudget = () => {
        setHasSentBudgets((prev) => ({ ...prev, [subproyecto.id]: true }));
        sendBudget(budgetToSend, subproyecto.id, projectIdWithSubprojectName);
    };

    //Definimos si ya se envio un presupuesto
    const hasSentBudgetForSubproject = (subprojectId) => {
        return subProjectBudgets[subprojectId]?.some(
            (budget) => budget.user.id === userData.id
        );
    };

    useEffect(() => {
        setHasSentBudgets((prev) => ({
            ...prev,
            [subproyecto.id]: hasSentBudgetForSubproject(subproyecto.id),
        }));
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);

    }, [subProjectBudgets]);

    const hasSentBudget = hasSentBudgets[subproyecto.id] || false;



    const hasBudgetPayroll = subproyecto?.budget_payroll !== null && subproyecto?.budget_payroll !== undefined;
    const hasPlanos = subproyecto?.planos !== "undefined" && subproyecto?.planos !== "null";
    const hasMetricComputation = subproyecto?.metric_computation !== null && subproyecto?.metric_computation !== undefined;
    const hasTechnicalSpecifications = subproyecto?.technical_specifications !== null && subproyecto?.technical_specifications !== undefined;
    const hasLink = subproyecto?.link !== "" && subproyecto?.link !== "undefined";

    const noDocus = !hasBudgetPayroll && !hasPlanos && !hasMetricComputation && !hasTechnicalSpecifications && !hasLink;

    return (
        <div className={styles.container}>
            {noDocus ? (
                <div className={styles.container_noDocus}>
                    <Ban size={20} className={styles.container_noDocus_icon} />
                    <p>Lamentablemente el usuario no adjuntó documentación específica</p>
                </div>
            ) : (
                <div className={styles.container_body}>
                    {hasLink && (
                        <div className={styles.container_body_item}>
                            <div className={styles.container_body_item_label}>
                                <FileText size={20} />
                                Link a los planos
                            </div>
                            <a href={subproyecto?.link} target="_blank" rel="noreferrer" className={`${styles.container_body_item_icon} ${postulationStatus !== "ALLOWED" && styles.forbidden}`}>
                                <Link size={16} />
                                Ir al link
                            </a>
                        </div>
                    )}
                    {hasPlanos && (
                        <div className={styles.container_body_item}>
                            <div className={styles.container_body_item_label}>
                                <FileText size={20} />
                                Planos
                            </div>
                            <a href={subproyecto?.planos} target="_blank" rel="noreferrer" className={`${styles.container_body_item_icon} ${postulationStatus !== "ALLOWED" && styles.forbidden}`}>
                                <Download size={16} />
                                Descargar
                            </a>
                        </div>
                    )}
                    {hasBudgetPayroll && (
                        <div className={styles.container_body_item}>
                            <div className={styles.container_body_item_label}>
                                <FileText size={20} />
                                Presupuesto
                            </div>
                            <a href={subproyecto?.budget_payroll} target="_blank" rel="noreferrer" className={`${styles.container_body_item_icon} ${postulationStatus !== "ALLOWED" && styles.forbidden}`}>
                                <Download size={16} />
                                Descargar
                            </a>
                        </div>
                    )}
                    {hasMetricComputation && (
                        <div className={styles.container_body_item}>
                            <div className={styles.container_body_item_label}>
                                <FileText size={20} />
                                Cómputo métrico
                            </div>
                            <a href={subproyecto?.metric_computation} target="_blank" rel="noreferrer" className={`${styles.container_body_item_icon} ${postulationStatus !== "ALLOWED" && styles.forbidden}`}>
                                <Download size={16} />
                                Descargar
                            </a>
                        </div>
                    )}
                    {hasTechnicalSpecifications && (
                        <div className={styles.container_body_item}>
                            <div className={styles.container_body_item_label}>
                                <FileText size={20} />
                                Especificaciones técnicas
                            </div>
                            <a href={subproyecto?.technical_specifications} target="_blank" rel="noreferrer" className={`${styles.container_body_item_icon} ${postulationStatus !== "ALLOWED" && styles.forbidden}`}>
                                <Download size={16} />
                                Descargar
                            </a>
                        </div>
                    )}
                </div>
            )}
            {isLoading ? (
                <div className={styles.container_budget}>
                    <RegisteredUserProfileLoader smallVersion={true} />
                </div>
            ) : (
                 !postulationStatus ? (
                    <div className={styles.container_budget}>
                        <button className={styles.container_budget_accessRequestBtn} onClick={handlePostulationButton}>
                            <FileQuestion size={20} />
                            <p>Solicitar acceso a la documentación</p>
                        </button>
                    </div>
                ) : (
                    <div className={styles.container_budget}>
                        {postulationStatus === "NEGATED" && (
                            <button className={styles.container_budget_deniedBtn}>
                                <X size={20} />
                                <p>Postulación negada</p>
                            </button>
                        )}
                        {postulationStatus === "SEND" && (
                            <button className={styles.container_budget_sendBtn}>
                                <Clock size={20} />
                                <p>Esperando respuesta</p>
                            </button>
                        )}
                        {postulationStatus === "ALLOWED" && !hasSentBudget && (
                            <div className={styles.container_budget_sendBudget}>
                                <div className={styles.container_budget_sendBudget_inputContainer}>
                                    <div className={styles.container_budget_sendBudget_inputContainer_header}>
                                        <h4 className={styles.container_budget_sendBudget_inputContainer_header_title}>Subir presupuesto</h4>
                                        <p className={styles.container_budget_sendBudget_inputContainer_header_sub} >Sube tu archivo de presupuesto en formato Excel (.xlsx) o PDF (.pdf)</p>
                                    </div>
                                    <AnimatePresence>
                                        {!fileName ? (

                                            <motion.div
                                                initial="hidden"
                                                animate="visible"
                                                exit="hidden"
                                                variants={variants}
                                            >
                                                <label
                                                    htmlFor="file"
                                                    className={styles.container_budget_sendBudget_label}>
                                                    <CloudUpload size={40} style={{ color: "rgb(156 163 175)" }} />
                                                    <span style={{ color: "rgb(75 85 99)", fontSize: "1.6rem" }}>Arrastra y suelta tu archivo aquí o</span>
                                                    <span className={styles.container_budget_sendBudget_label_sub}>selecciona un archivo</span>
                                                    <span>Archivos permitidos: XLSX, PDF, XLS</span>
                                                    <input
                                                        type="file"
                                                        onChange={(e) => uploadFile(e.target.files[0])}
                                                        className={styles.container_budget_inputFile}
                                                        accept=".xlsx, .pdf, .xls"
                                                        id="file"
                                                        style={{ display: "none" }}
                                                    />
                                                </label>
                                            </motion.div>
                                        ) : (
                                            <div className={styles.container_budget_sendBudget_file}>
                                                <div className={styles.container_budget_sendBudget_file_itemContainer}>
                                                    <div className={styles.container_budget_sendBudget_file_icon}>
                                                        <File size={20} />
                                                    </div>
                                                    <p className={styles.container_budget_sendBudget_file_name}>{fileName}</p>
                                                </div>
                                                <button
                                                    className={styles.container_budget_sendBudget_file_delete}
                                                    onClick={() => {
                                                        setFileName("");
                                                        setFileUpload(false);
                                                    }}
                                                >
                                                    <X size={20} />
                                                </button>
                                            </div>
                                        )}
                                    </AnimatePresence>
                                </div>

                                <button className={styles.container_budget_acceptedBtn} onClick={handleSendBudget}>
                                    <Upload size={20} />
                                    <p>Enviar presupuesto</p>
                                </button>
                            </div>
                        )}
                        {postulationStatus === "ALLOWED" && hasSentBudget && (
                            <button className={styles.container_budget_sentBtn}>
                                <CheckCheck size={20} />
                                Ya enviaste tu presupuesto
                            </button>
                        )}
                    </div>
                )
                )}
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        userData: state.general.userData,
        subProjectBudgets: state.profile.subProjectBudgets,
    };
};

export default connect(mapStateToProps, { sendBudget, getSubProjectBudgets })(SubProjectInformation);

