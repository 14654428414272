import React from "react";
import PrivateQuotationCard from "./PrivateQuotationCard";
import { connect } from "react-redux";
import {
  setPostulationProjectId,
  closeNDAModal,
  setResponsiveFall,
} from "../../store/actions/index";
import PublicQuotationItem from "./PublicQuotations/PublicQuotationItem";

const QuotationsList = ({
  item,
  userData,
}) => {

  return item.type === "PRIVATE" ? (
   <PrivateQuotationCard
      item={item}
      userData={userData}
      />
  ) : (
      <PublicQuotationItem  item={item} isQuotation />
  );

};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
  };
};

export default
  connect(mapStateToProps, {
    setPostulationProjectId,
    closeNDAModal,
    setResponsiveFall,
  })(QuotationsList);
