import React, { useEffect, useCallback } from 'react';
import { useMap, Marker, Circle, Popup } from 'react-leaflet';
import CityCoordinates from '../../CityCoordinates';
import useSupercluster from 'use-supercluster';
import L from "leaflet";
import styles from './../WorkersMaps.module.scss';
import "./WorkerData.css"
import { HardHat, MapPin, Info } from 'lucide-react';

const icons = {};
const fetchIcon = (count, size) => {
    if (!icons[count]) {
        icons[count] = L.divIcon({
            html: `<div class="${styles.clusterMarker}" style="width: ${size}px; height: ${size}px;">
        ${count}
      </div>`,
        });
    }
    return icons[count];
};

const WorkersData = ({
    ciudadesArray,
    setCoordinatesArray,
    setBounds,
    setCenter,
    setZoom,
    setShowSmallMap,
    coordinatesArray,
    center,
    bounds,
    zoom,
    setUniqueWorker,
    uniqueWorker,
    setMultipleWorkersSameCity,
    setAlternativeCoordinates,
    multipleWorkersSameCity,
    polygonBounds,
    setPolygonBounds,
    isFiltered,
    setIsFiltered,
    setIsMarker,
}) => {

    const map = useMap();
    const initialZoom = 5;
    const initialCenter = [-31.417, -64.183];


    const normalizeCityName = (cityName) => {
        if (!cityName) return '';

        return cityName
            .replace(/\s*,\s*/g, ', ')
            .replace(/\s+/g, ' ')
            .trim();
    };

    const handleSearch = async () => {
        try {
            const b = map.getBounds();
            setBounds([
                b.getSouthWest().lat,
                b.getSouthWest().lng,
                b.getNorthEast().lat,
                b.getNorthEast().lng,
            ])
            setZoom(map.getZoom());
            const results = ciudadesArray.flatMap((cityObj) =>
                cityObj.jobBoardZones.map((zone) => {
                    const city = normalizeCityName(zone.city);
                    const coordinates = CityCoordinates[city];

                    if (coordinates) {
                        return {
                            city: zone.city,
                            coordinates: [coordinates.lat, coordinates.lon],
                            userInfo: cityObj
                        };
                    }
                    return null;
                }
                ).filter(Boolean));
            setCoordinatesArray(results);
        } catch (error) {
            console.error('Error al procesar la solicitud:', error);
        }
    };

    const onMove = useCallback(() => {
        handleSearch();
    }, [map, ciudadesArray]);

    useEffect(() => {
        handleSearch();
    }, [map, ciudadesArray]);

    useEffect(() => {
        map.on("move", onMove);
        return () => {
            map.off("move", onMove);
        };
    }, [map, onMove]);

    const points = coordinatesArray.map((cityObj) => ({
        type: 'Feature',
        properties: {
            cluster: false,
            city: cityObj.city,
            userInfo: cityObj.userInfo
        },
        geometry: {
            type: 'Point',
            coordinates: cityObj.coordinates,
        },
    }));

    const { clusters, supercluster } = useSupercluster({
        points: points,
        bounds: bounds,
        zoom: zoom,
        options: { radius: 30, maxZoom: 17 },
    });

    const handleClusterClick = (cluster) => {
        setIsFiltered(false);
        setIsMarker(false);
        const expansionZoom = supercluster.getClusterExpansionZoom(cluster.id);
        const finalZoom = Math.min(expansionZoom, 12);

        if (zoom >= finalZoom) {
            return;
        }

        map.flyTo([cluster.geometry.coordinates[0], cluster.geometry.coordinates[1]], finalZoom, {
            animate: true,
        });

        const leaves = supercluster.getLeaves(cluster.id, Infinity);
        const allWorkers = new Set();
        const citiesWithMultipleWorkers = new Set();
        const cityWorkerCount = {};

        leaves.forEach((leaf) => {
            const { city, userInfo } = leaf.properties;
            const { coordinates } = leaf.geometry;

            const workerData = { city, userInfo, coordinates };
            const workerString = JSON.stringify(workerData);

            allWorkers.add(workerString);

            if (!cityWorkerCount[city]) {
                cityWorkerCount[city] = 0;
            }
            cityWorkerCount[city] += 1;

            if (cityWorkerCount[city] > 1) {
                citiesWithMultipleWorkers.add(city);
                setAlternativeCoordinates(coordinates);
                setPolygonBounds({ lat: coordinates[0], lon: coordinates[1] });
            }
        });

        const uniqueWorkers = Array.from(allWorkers).map((workerString) => JSON.parse(workerString));

        setMultipleWorkersSameCity(uniqueWorkers);

        if (citiesWithMultipleWorkers.size > 0) {
            setShowSmallMap(true);
            setUniqueWorker(null);
            setCenter([cluster.geometry.coordinates[0], cluster.geometry.coordinates[1]]);
        } else {
            setShowSmallMap(false);
        }
    };

    const handleMarkerClick = (coordinates, city, cluster) => {
        setIsMarker(true);
        const cityName = normalizeCityName(city);
        const cityBounds = CityCoordinates[cityName];
        if (cityBounds) {
            setPolygonBounds(cityBounds);
            setShowSmallMap(true);
            setUniqueWorker({ coordinates, cluster });
            setCenter([coordinates[0], coordinates[1]]);
            setIsFiltered(false);
            setAlternativeCoordinates(null);
            setMultipleWorkersSameCity(null);
        }
        map.flyTo(coordinates, 8, { animate: true });
    };

    const customIcon = new L.Icon({
        iconUrl: 'https://cdn-icons-png.flaticon.com/128/7193/7193392.png',
        iconSize: [25, 25],
    });

    useEffect(() => {
        if (!uniqueWorker) {
            map.flyTo(center, zoom, { animate: true });
        }
    }, [uniqueWorker]);

    useEffect(() => {
        if (!multipleWorkersSameCity) {
            map.flyTo(center, zoom, { animate: true });
        }
    }, [multipleWorkersSameCity]);

    useEffect(() => {
        if (isFiltered) {
            map.flyTo(initialCenter, initialZoom, { animate: true });
            setPolygonBounds(null);
        }
    }, [isFiltered]);

    return (
        <>
            {clusters.map((cluster) => {
                const [latitude, longitude] = cluster.geometry.coordinates;
                const {
                    cluster: isCluster,
                    point_count: pointCount,
                } = cluster.properties;

                if (isCluster) {
                    return (
                        <Marker
                            key={`cluster-${cluster.id}`}
                            position={[latitude, longitude]}
                            icon={fetchIcon(
                                pointCount,
                                10 + (pointCount / points.length) * 40
                            )}
                            eventHandlers={{
                                mouseover: (e) => {
                                    e.target.openPopup();
                                },
                                mouseout: (e) => {
                                    e.target.closePopup();
                                },
                                click: () => handleClusterClick(cluster),
                            }}
                        >
                            <Popup>
                            <div className="workersInfo">
                                <Info className="workersInfo__icon" />
                                <div className="infoText">
                                    {pointCount} trabajadores en esta zona
                                    <span className="infoText__seeMore">Click para ver más</span>
                                </div>
                                </div>
                            </Popup>
                        </Marker>
                    );
                }

                return (
                    <Marker
                        key={`marker-${cluster.properties.city}`}
                        position={[latitude, longitude]}
                        icon={customIcon}
                        eventHandlers={{
                            mouseover: (e) => {
                                e.target.openPopup();
                            },
                            mouseout: (e) => {
                                e.target.closePopup();
                            },
                            click: () => handleMarkerClick([latitude, longitude], cluster.properties.city, cluster),
                        }}
                    >
                        <Popup>
                            <div className="workerInfo">
                                <div className="infoText">
                                    <div className="infoText__header">
                                        <MapPin className='infoIcon' />
                                        <h3 className="infoText__header__city">{cluster.properties.city}</h3>
                                    </div>
                                    <div className="infoText__item">
                                        <HardHat className='infoIcon' />
                                        <span className="infoText__name">{cluster.properties.userInfo?.name} {cluster.properties.userInfo?.last_name}</span>
                                    </div>
                                    <div className="infoText__skill__container">{cluster.properties.userInfo?.skill.map(skill => {
                                        if (!skill?.skill?.name) return null;
                                        return (
                                            <span key={skill.id} className="infoText__skill">
                                                {skill?.skill?.name}
                                            </span>
                                        );
                                    })}
                                    </div>
                                    <span className="infoText__seeMore">Click para ver más</span>
                                </div>
                            </div>
                        </Popup>
                    </Marker>
                );
            })}
            {polygonBounds && (
                <Circle center={[polygonBounds.lat, polygonBounds.lon]} radius={30000} pathOptions={{ color: 'blue', fillColor: '#blue', fillOpacity: 0.2 }} />
            )}
        </>
    );
}


export default WorkersData;
