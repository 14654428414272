import React, { useState, useEffect } from "react";
import styles from "./PrivateQuotationCard.module.scss";
import usePremiumCheck from "../../hooks/usePremiumCheck";
import { CalendarDays, MapPin, Cuboid, DollarSign, Building2, User, ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

const PrivateQuotationCard = ({ item, userData }) => {
  const [randomImage, setRandomImage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getRandomImage();
  }, []);

  const handleOnClick = () => {
    isMyQuotation ? navigate(`/cotizaciondemateriales/${item?.id}`) :
      navigate(`/cotizaciondemateriales/postulante/${item?.id}`);
  }

  const premiumCheck = usePremiumCheck();

  let {
    name,
    zone,
    startDate,
    currency,
    organismo,
    jurisdiccion,
    user,
    description,
    endDate,
    subquotation,
  } = item;



  const isMyQuotation = item?.user?.id === userData?.id;

  const formatDate = (date) => {
    const utcDate = new Date(date);
    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth() + 1;
    const day = utcDate.getUTCDate();
    return `${day}/${month}/${year}`;
  };


  const constructionImages = [
    "https://images.pexels.com/photos/3990359/pexels-photo-3990359.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/439416/pexels-photo-439416.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/93400/pexels-photo-93400.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/1078884/pexels-photo-1078884.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/2138126/pexels-photo-2138126.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/1117452/pexels-photo-1117452.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/224924/pexels-photo-224924.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/7931/pexels-photo-7931.jpg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/1463917/pexels-photo-1463917.jpeg?auto=compress&cs=tinysrgb&w=600"
  ];

  // Función para obtener una imagen aleatoria
  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * constructionImages.length);
    return setRandomImage(constructionImages[randomIndex]);
  }

  const userNameToShow = item?.user?.type === "COMPANY" ? item?.user.razon_social : item?.user?.name + " " + item?.user?.last_name;

  const extractCategoryNamesFromSubquotation = (subquotation) => {
    const allCategories = subquotation
      .flatMap((item) => item.subQuotation_materials || [])
      .map((material) => material.material?.category?.name)
      .filter(Boolean);

    return [...new Set(allCategories)];
  };

  // Obtener los resultados
  const categoryNames = extractCategoryNamesFromSubquotation(subquotation);

  return (
    <div className={styles.licitacionesItemCard}>
      <div className={styles.licitacionesItemCard_backGround}>
        <img src={randomImage} alt="" className={styles.licitacionesItemCard_backGround_img} />
      </div>
      <div className={styles.licitacionesItemCard_content}>
        <h2 className={styles.licitacionesItemCard_content_title}>{name}</h2>
        <p className={styles.licitacionesItemCard_content_description}>{description}</p>
        <div className={styles.licitacionesItemCard_content_user}>
          <div className={styles.licitacionesItemCard_content_user_icon}>
            <User size={16} style={{ minWidth: "1.6rem", minHeight: "1.6rem" }} />
          </div>
          <div className={styles.licitacionesItemCard_content_user_info}>
            <div className={styles.licitacionesItemCard_content_user_info_name}>
              {userNameToShow}
            </div>
          </div>
        </div>
        <div className={styles.licitacionesItemCard_content_zone}>
          <MapPin size={16} style={{ minWidth: "1.6rem", minHeight: "1.6rem" }} />
          {jurisdiccion?.name} - {zone}
        </div>
        {organismo && (
          <div className={styles.licitacionesItemCard_content_zone}>
            <Building2 size={16} style={{ minWidth: "1.6rem", minHeight: "1.6rem" }} />
            {organismo.name}
          </div>
        )}
        <div className={styles.licitacionesItemCard_content_dates}>
          <CalendarDays size={16} style={{ minWidth: "1.6rem", minHeight: "1.6rem" }}/>
          <div className={styles.licitacionesItemCard_content_dates_dates}>
            <span className={styles.licitacionesItemCard_content_dates_dateContainer}>
              {formatDate(startDate)}
            </span> - <span className={styles.licitacionesItemCard_content_dates_date} >
              {formatDate(endDate)}
            </span>
          </div>

        </div>
        <div className={styles.licitacionesItemCard_content_budget}>
          <DollarSign size={16} style={{ minWidth: "1.6rem", minHeight: "1.6rem" }} />
          <span className={styles.licitacionesItemCard_content_budget_price}>
            {currency}
          </span>
        </div>
        <div className={styles.licitacionesItemCard_content_workType}>
          <div className={styles.licitacionesItemCard_content_workType_type}>
            {categoryNames?.map((category, idx) => (
              <div key={idx} className={styles.licitacionesItemCard_content_workType_category}>
                <Cuboid size={12} style={{ minWidth: "1.2rem", minHeight: "1.2rem" }} />
                <span>{category}</span>
              </div>
            ))}
          </div>
        </div>
       <div className={styles.licitacionesItemCard_content_button}>
        <button className={styles.licitacionesItemCard_content_button_btn} onClick={premiumCheck(handleOnClick)}>
          {isMyQuotation ? "Ver detalles" : "Cotizar"}
          <ChevronRight size={16} style={{ minWidth: "1.6rem", minHeight: "1.6rem" }} />
        </button>
      </div> 
      </div>
      
    </div>

  );
}

export default PrivateQuotationCard;