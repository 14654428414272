import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import {
    getPublicProjectById,
} from "../../../store/actions";
import { useParams } from "react-router-dom";
import Botonera from "../../Botonera/Botonera";
import styles from "./PublicProjectById.module.scss";
import { Building2, Calendar, ChevronDown, ChevronUp, DollarSign, Download, Pickaxe, MapPin } from "lucide-react";
import RegisteredUserProfileLoader from "../../RegisteredUsers/RegisteredUsersSkeleton/RegisteredUserProfileLoader";
import styled from "styled-components";

const Container = styled.div`
   display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 8%;
  padding-left: 8%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const PublicProjectById = ({
    publicProject,
    getPublicProjectById,
    ...props }) => {
    const [ocultarEnMobile, setOcultarEnMobile] = useState(true);
    const [mobileStylesFromParent, setMobileStylesFromParent] = useState(true);
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { id } = useParams();
    // Dividimos la descripción en líneas usando '\n'
    const processDescription = (desc) => {
        if (!desc) return { isText: false, lines: [] };

        const lines = desc.split('\r\n').filter((line) => line.trim() !== '');

        // Si no hay saltos de línea significativos, consideramos que es un texto plano
        if (lines.length <= 1) {
            return { isText: true, text: desc.trim() };
        }

        const result = [];
        for (let i = 0; i < lines?.length; i += 2) {
            const key = lines[i]?.trim();
            const value = lines[i + 1]?.trim();
            if (key && value) {
                result.push({ key, value });
            }
        }
        return { isText: false, lines: result };
    };

    const { isText, lines, text } = processDescription(publicProject?.description);
    const descriptionLines = isText ? [] : lines;
    const visibleLines = isDescriptionExpanded ? descriptionLines : descriptionLines.slice(0, 5);

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        setOcultarEnMobile(windowWidth < 768);
        setMobileStylesFromParent(windowWidth < 768);
    };

    // Ejecutar handleResize al cargar el componente
    useEffect(() => {
        handleResize();
        getPublicProjectById(id);
    }, []);

    // Agregar un event listener para el cambio de tamaño de la ventana
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        // Limpiar el event listener al desmontar el componente
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (!publicProject || publicProject.id !== id) {
            setIsLoading(true);
            getPublicProjectById(id);
        } else {
            setIsLoading(false);
        }
    }, [publicProject, id]);

    //Formateamos fechas
    const formatDate = (date) => {
        const utcDate = new Date(date);
        const year = utcDate.getUTCFullYear();
        const month = utcDate.getUTCMonth() + 1;
        const day = utcDate.getUTCDate();
        return `${day}/${month}/${year}`;
    };

    //Formateamos el monto a mostrar
    const formatMoney = (amount) => {
        return new Intl.NumberFormat('es-AR', {
            style: 'currency',
            currency: 'ARS',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(amount);
    };

    return (
        <Container>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.6 }}
                className={styles.container}>
                {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
                {isLoading ? <RegisteredUserProfileLoader /> : (
                    <div className={styles.content}>
                        <div className={styles.header}>
                            <p className={styles.subtitle}>Licitación Pública</p>
                            <h1 className={styles.title}>
                                {publicProject?.name}
                            </h1>
                        </div>

                        <div className={styles.layout}>
                            <div className={styles.mainContent}>
                                <div className={styles.card}>
                                    <h2 className={styles.cardTitle}>Información General</h2>

                                    <div className={styles.infoList}>
                                        <div className={styles.infoItem}>
                                            <MapPin className={styles.icon} />
                                            <div>
                                                <p className={styles.infoTitle}>Ubicación</p>
                                                <p className={styles.infoContent}>{publicProject?.jurisdiccion?.name}</p>
                                            </div>
                                        </div>

                                        <div className={styles.infoItem}>
                                            <Calendar className={styles.icon} />
                                            <div>
                                                <p className={styles.infoTitle}>Fecha de apertura</p>
                                                <p className={styles.infoContent}>{formatDate(publicProject?.startDate)}</p>
                                            </div>
                                        </div>
                                        <div className={styles.infoItem}>
                                            <DollarSign className={styles.icon} />
                                            <div>
                                                <p className={styles.infoTitle}>Moneda</p>
                                                <p className={styles.infoContent}>{publicProject?.currency !== "undefined" ? publicProject?.currency : "Pesos Arg"}</p>
                                            </div>
                                        </div>

                                        <div className={styles.infoItem}>
                                            <DollarSign className={styles.icon} />
                                            <div>
                                                <p className={styles.infoTitle}>Presupuesto oficial</p>
                                                <p className={styles.infoContent}>{formatMoney(publicProject?.monto)}</p>
                                            </div>
                                        </div>
                                        <div className={styles.infoItem1}>
                                            <h3 className={styles.infoTitle}>Descripción</h3>
                                            <div className={styles.infoContent}>
                                                {isText ? (
                                                    <p className={styles.descriptionText}>{text}</p>
                                                ) : (
                                                    visibleLines.map((line, index) => (
                                                        <div key={index} className={styles.infoLine}>
                                                            {line.key && <span className={styles.infoSubtitle}>{line.key}:</span>}
                                                            <span className={styles.infoValue}>{line.value}</span>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                            {descriptionLines.length > 3 && (
                                                <button
                                                    className={styles.expandButton}
                                                    onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
                                                >
                                                    {isDescriptionExpanded ? 'Ver menos' : 'Ver más'}
                                                    {isDescriptionExpanded ? <ChevronUp className={styles.buttonIcon} /> : <ChevronDown className={styles.buttonIcon} />}
                                                </button>
                                            )}

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className={styles.sidebar}>
                                <div className={styles.card}>
                                    <h2 className={styles.cardTitle}>Comitente</h2>
                                    <div className={styles.infoItem}>
                                        <Building2 className={styles.icon} />
                                        <div>
                                            <p className={styles.infoItem_item}>{publicProject?.comitente}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.card}>
                                    <h2 className={styles.cardTitle}>Tipo de Obra</h2>
                                    <div className={styles.infoItem}>
                                        <Pickaxe className={styles.icon} />
                                        <p className={styles.infoItem_item}>
                                            {publicProject?.obra_type?.name}</p>
                                    </div>
                                </div>

                                <div className={styles.card}>
                                    <h2 className={styles.cardTitle}>Documentación</h2>
                                    <a href={publicProject?.link} target="_blank" rel="noreferrer" className={styles.downloadButton}>  <Download className={styles.buttonIcon} />
                                        Descargar pliego</a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </motion.div >
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        publicProject: state.profile.publicProject,
    };
}

export default connect(mapStateToProps, {
    getPublicProjectById,
})(PublicProjectById); 