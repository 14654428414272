import React, { useState, useEffect } from "react";
import styles from "./PublicQuotationItem.module.scss";
import { iconJPG } from "../../UI/ProvinceIcons/IconsData";
import usePremiumCheck from "../../../hooks/usePremiumCheck";
import ArgentinaIcono from "../../../assets/images/argentinaIcon.jpg";
import { CalendarDays, MapPin, Pickaxe, DollarSign, Building2 } from "lucide-react";

const PublicQuotationItem = ({ item }) => {
  const [randomImage, setRandomImage] = useState(null);

  useEffect(() => {
    getRandomImage();
  }, []);

  const handleOnClick = () => {
    window.open(
      `/materiales-a-licitar-publicos/${item.id}`,
      "_blank"
    );
  };

  const premiumCheck = usePremiumCheck();

  let {
    name,
    zone,
    startDate,
    monto,
    organismo,
    jurisdiccion,
    obra_type,
  } = item;

  const current = function (number) {
    return new Intl.NumberFormat().format(number);
  };

  const formatDate = (date) => {
    const utcDate = new Date(date);
    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth() + 1;
    const day = utcDate.getUTCDate();
    return `${day}/${month}/${year}`;
  };

  const iconoProvincia = iconJPG.find(icon => icon.name === jurisdiccion?.name)?.icon || ArgentinaIcono;

  const constructionImages = [
    "https://images.pexels.com/photos/3990359/pexels-photo-3990359.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/439416/pexels-photo-439416.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/93400/pexels-photo-93400.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/1078884/pexels-photo-1078884.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/2138126/pexels-photo-2138126.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/1117452/pexels-photo-1117452.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/224924/pexels-photo-224924.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/7931/pexels-photo-7931.jpg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/1463917/pexels-photo-1463917.jpeg?auto=compress&cs=tinysrgb&w=600"
  ];


  // Función para obtener una imagen aleatoria
  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * constructionImages.length);
    return setRandomImage(constructionImages[randomIndex]);
  }

  return (
    <div className={styles.licitacionesItemCard}>
      <div className={styles.licitacionesItemCard_backGround}>
        <img src={randomImage} alt="" className={styles.licitacionesItemCard_backGround_img} />
        <div className={styles.licitacionesItemCard_backGround_data}>
          <img src={iconoProvincia} alt="Foto de perfil" className={styles.licitacionesItemCard_backGround_data_logo} />
          <div className={styles.licitacionesItemCard_backGround_data_info}>
            <h4 className={styles.licitacionesItemCard_backGround_data_info_name}>
              {name}
            </h4>
            <p>{jurisdiccion?.name}</p>
          </div>
        </div>
      </div>
      <div className={styles.licitacionesItemCard_content}>
        <div className={styles.licitacionesItemCard_content_zone}>
          <MapPin size={20} style={{ minWidth: "2rem" }} />
          {zone}
        </div>
        {organismo && (
          <div className={styles.licitacionesItemCard_content_zone}>
            <Building2 size={20} style={{ minWidth: "2rem" }} />
            {organismo.name}
          </div>
        )}
        {obra_type && (
          <div className={styles.licitacionesItemCard_content_workType}>
            <Pickaxe size={20} style={{ minWidth: "2rem" }} />
            {obra_type.name}
          </div>
        )}
        <div className={styles.licitacionesItemCard_content_dates}>
          <CalendarDays size={20} style={{ minWidth: "2rem" }} />
          <div className={styles.licitacionesItemCard_content_dates_dates}>
            <span className={styles.licitacionesItemCard_content_dates_dateContainer}>
              {formatDate(startDate)}
            </span>
          </div>

        </div>
        <div className={styles.licitacionesItemCard_content_budget}>
          <DollarSign size={20} style={{ minWidth: "2rem" }} />
          <span className={styles.licitacionesItemCard_content_budget_price}>
            {monto && current(monto)}
          </span>
        </div>
      </div>
      <div className={styles.licitacionesItemCard_content_button}>
        <button className={styles.licitacionesItemCard_content_button_btn} onClick={premiumCheck(handleOnClick)}>Ver más</button>
      </div>
    </div>

  );
}

export default PublicQuotationItem;
