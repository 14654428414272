import * as actionType from "../actions/types";

const INITIAL_STATE = {
  allProjects: [],
  viewProjects: [],
  viewStatus: 0,
  project: [],
  posteos: [],
  ownPosteos: [],
  recentPost: [],
  totalUsers: [],
  lastNewUsers: [],
  totalPublicProjects: [],
  lastPublicProject: [],
  totalPrivateProjects: [],
  totalPublicQuotations: [],
  totalAdjudicatedProjects: [],
  lastAdjudicatedProject: [],
  lastPublicQuotation: [],
  totalNewPublicQuotations: [],
  totalPrivateQuotations: [],
  totalNewPrivateQuotations: [],
  postulationProject: [],
  postulationProjectId: null,
  previousLocation: "",
  solicitudesList: [],
  budgetRequest: null,
  budgetlist: [],
  solicitudeId: null,
  tutorialModal: false,
  newNotifications: 0,
  filterBy: [],
  userProjectInfo: [],
};

const setViewProjects = (state, payload) => {
  const allProjects = payload.data;
  const itemCount = payload.itemCount;
  return {
    ...state,
    allProjects: allProjects,
    viewProjects: allProjects,
    page: payload.page,
    itemCount,
    take: payload.take,
    isSettingPostulation: false,
    loading: false,
    isFilter: payload.isFilter,
    searchParams: payload.searchParams,
    isLoading: payload.isLoading,
  };
};

const addNewFilter = (state, filter) => {
  let toAddArray = filter.filter((item) => {
    if (item.type !== "byDate") {
      return item.value !== "";
    } else {
      return item.value.since !== "" && item.value.until !== "";
    }
  });

  if (state.filterBy.length === 0) {
    //Primera carga
    return {
      ...state,
      filterBy: [...toAddArray],
    };
  } else {
    //Modificar valores existentes
    const filterByUpdated = state.filterBy.map((item) => {
      let devuelta = toAddArray.find((el) => {
        return el.type === item.type;
      });
      if (devuelta) {
        return { ...item, value: devuelta.value };
      } else {
        return item;
      }
    });

    return {
      ...state,
      filterBy: [...filterByUpdated],
    };
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.GET_DASHBOARD_PROJECTS:
      return setViewProjects({ ...state }, action.payload);
    case actionType.SET_POSTULATION_PROJECT:
      return {
        ...state,
        postulationProject: action.payload,
      };
    case actionType.CLOSE_TUTORIAL_MODAL:
      return {
        ...state,
        tutorialModal: false,
      };  
    case actionType.OPEN_TUTORIAL_MODAL:
      return {
        ...state,
        tutorialModal: true,
      };
    case actionType.SET_POSTULATION_PROJECT_ID:
      return {
        ...state,
        isSettingPostulation: false,
        postulationProjectId: action.payload,
      };
    case actionType.SET_POSTULATION_PROJECT_ID:
      return {
        ...state,
        project: action.payload,
      };
    case actionType.DISABLE_POSTULATE_BUTTON:
      return {
        ...state,
        isSettingPostulation: action.payload,
      };
    case actionType.GET_POSTEOS:
      return {
        ...state,
        posteos: action.payload,
      };
    case actionType.GET_OWN_POSTEOS:
      return {
        ...state,
        ownPosteos: action.payload,
      };
    case actionType.RECENT_POST:
      return {
        ...state,
        recentPost: action.payload,
      };
    case actionType.GET_TOTAL_USERS:
      return {
        ...state,
        totalUsers: action.payload,
      };
    case actionType.GET_LAST_NEW_USERS:
      return {
        ...state,
        lastNewUsers: action.payload,
      };
    case actionType.GET_TOTAL_PUBLIC_PROJECTS:
      return {
        ...state,
        totalPublicProjects: action.payload.totalPublicProjects,
        lastPublicProject: action.payload.lastPublicProject,
      };
    case actionType.GET_TOTAL_PRIVATE_PROJECTS:
      return {
        ...state,
        totalPrivateProjects: action.payload,
      };
    case actionType.GET_TOTAL_ADJUDICATED_PROJECTS: 
      return {
        ...state,
        totalAdjudicatedProjects: action.payload,
      };
    case actionType.GET_LAST_ADJUDICATED_PROJECT:
      return {
        ...state,
        lastAdjudicatedProject: action.payload,
      };
    case actionType.GET_TOTAL_PUBLIC_QUOTATIONS:
      return {
        ...state,
        totalPublicQuotations: action.payload.totalPublicQuotations,
        lastPublicQuotation: action.payload.lastPublicQuotation,
      };
    case actionType.GET_TOTAL_NEW_PUBLIC_QUOTATIONS:
      return {
        ...state,
        totalNewPublicQuotations: action.payload,
      };
    case actionType.GET_TOTAL_PRIVATE_QUOTATIONS:
      return {
        ...state,
        totalPrivateQuotations: action.payload,
      };
    case actionType.GET_TOTAL_NEW_PRIVATE_QUOTATIONS:
      return {
        ...state,
        totalNewPrivateQuotations: action.payload,
      };
    case actionType.CLEAR_POSTULATION_PROJECT:
      return {
        ...state,
        postulationProject: [],
      };
    case actionType.GET_SOLICITUDE_LIST:
      return {
        ...state,
        solicitudesList: action.payload,
        newNotifications: action.payload.filter(
          (item) => item.view !== true
        ).length,
      };
    case actionType.SET_BUDGET_ID:
      return {
        ...state,
        budgetRequest: action.payload,
      };
    case actionType.GET_BUDGETS_LIST:
      return {
        ...state,
        budgetList: action.payload,
      };
    case actionType.ADD_FILTER:
      return addNewFilter(state, action.payload);

    case actionType.REMOVE_FILTER:
      return {
        ...state,
        filterBy: state.filterBy.filter((item) => item.type !== action.payload),
      };
    case actionType.RESET_FILTERBY:
      return {
        ...state,
        filterBy: [],
      };
    case actionType.CLEAR_PROJECTS:
      return {
        ...state,
        allProjects: [],
        viewProjects: [],
      };
      case actionType.SET_PREVIOUS_LOCATION:
        return {
          ...state,
          previousLocation: action.payload,
        };
    case actionType.GET_DASHBOARD_PROJECTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
