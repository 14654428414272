import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Plus, Send, Search, Package, Trash2, Eye } from 'lucide-react';
import AddCustomMaterialPopup from './AddCustomMaterialPopup';
import SendQuotePopup from './SendQuotePopup';
import MaterialsListPreview from './MaterialsListPreview';
import styles from './CalculadoraDeMateriales.module.scss';
import DownloadPDFButton from './DownloadPdfButton';
import { getCategoriesWithMaterials } from '../../../store/actions/categoryActions';
import { closeMainModal, editSubquotationMaterials, getMaterialsBySubquotationId, openMainModal } from '../../../store/actions/profileActions';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import RegisteredUserProfileLoader from '../../RegisteredUsers/RegisteredUsersSkeleton/RegisteredUserProfileLoader';
import { useLocation } from 'react-router-dom';

const ulVariants = {
  hidden: {
    opacity: 0,
    height: 0,
  },
  visible: {
    opacity: 1,
    height: "auto",
    transition: {
      opacity: {
        duration: 0.8,
      },
      height: {
        duration: 0.3,
      },
    },
  },
};


const CalculadoraDeMateriales = ({
  getCategoriesWithMaterials,
  categoriesWithMaterials,
  quotationData,
  materialsBySubquotation,
  openMainModal,
  getMaterialsBySubquotationId,
  closeMainModal,
  editSubquotationMaterials }) => {

  const { id } = useParams();

  const location = useLocation();

  const searchInputRef = useRef(null);

  // Determinar el modo en función de la ruta
  const isEstimacionDeCostos = location.pathname.includes("estimacion-costos");

  const [categories, setCategories] = useState(categoriesWithMaterials);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState([0]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [showAddCustomPopup, setShowAddCustomPopup] = useState(false);
  const [showSendQuotePopup, setShowSendQuotePopup] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isSyncingMaterials, setIsSyncingMaterials] = useState(true);
  const [originalMaterials, setOriginalMaterials] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(!!id);
  const [searchInputActive, setSearchInputActive] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getCategoriesWithMaterials();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setCategories(categoriesWithMaterials);
  }, [categoriesWithMaterials]);

  useEffect(() => {
    setIsEditMode(!!id);
    setSelectedMaterials([]);
  }, [id]);



  useEffect(() => {
    if (isEditMode && quotationData && isSyncingMaterials) {
      const fetchMaterials = async () => {
        try {
          setIsLoading(true);
          await getMaterialsBySubquotationId(quotationData?.subquotation[0]?.id);

          if (!materialsBySubquotation) {
            console.warn("No hay materiales para las subcotizaciones");
            return;
          }

          const subCotizaciones = Object.values(materialsBySubquotation);
          const formattedMaterials = subCotizaciones.flatMap((subCotizacion) =>
            subCotizacion.flatMap((category) =>
              (category.materials || []).map((material) => ({
                id: material.id,
                name: material.name,
                unit: material.unit || 'N/A',
                quantity: material.amount || 1,
                category: category.name || 'Sin Categoría',
              }))
            )
          );

          setSelectedMaterials(formattedMaterials);
        } catch (error) {
          console.error("Error al obtener materiales:", error);
        } finally {
          setIsSyncingMaterials(false);
          setIsLoading(false);
        }
      };

      fetchMaterials();
    }
  }, [isEditMode, quotationData, isSyncingMaterials]);

  //Lógica para saber si hubo cambios en los materiales

  useEffect(() => {
    if (isEditMode && materialsBySubquotation) {
      const subCotizaciones = Object.values(materialsBySubquotation);
      const formattedMaterials = subCotizaciones.flatMap((subCotizacion) =>
        subCotizacion.flatMap((category) =>
          (category.materials || []).map((material) => ({
            id: material.id,
            name: material.name,
            unit: material.unit || 'N/A',
            quantity: material.amount || 1,
            category: category.name || 'Sin Categoría',
          }))
        )
      );
      setOriginalMaterials(formattedMaterials);
    }
  }, [materialsBySubquotation, isEditMode]);

  // Función para verificar si los materiales han cambiado
  const hasChanges = () => {
    if (selectedMaterials.length !== originalMaterials.length) {
      return true;
    }

    return selectedMaterials.some((material, index) => {
      const originalMaterial = originalMaterials[index];
      return (
        material.id !== originalMaterial.id ||
        Number(material.quantity) !== Number(originalMaterial.quantity)
      );
    });
  };

  const removeMaterial = (id) => {
    setSelectedMaterials((prev) => prev.filter((m) => m.id !== id));
  };

  useEffect(() => {
    if (!searchTerm) {
      setFilteredMaterials([]);
      return;
    }

    const removeAccents = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const normalizedSearchTerm = removeAccents(searchTerm.toLowerCase());

    const filtered = (categories.data || []).flatMap((category) => {
      return (category.materials || [])
        .filter((material) => {
          const normalizedMaterialName = removeAccents(material.name.toLowerCase());
          return normalizedMaterialName.includes(normalizedSearchTerm);
        })
        .map((material) => ({
          ...material,
          category: category.name,
        }));
    });

    setFilteredMaterials(filtered);
  }, [searchTerm, categories]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleCategory = (categoryId) => {
    setSelectedCategory(categoryId);
    setExpandedCategories((prevCategories) =>
      prevCategories.includes(categoryId) ? [] : [categoryId]
    );
  };

  const toggleMaterial = (material, categoryId) => {
    const category = categories?.data?.find((cat) => cat.id === categoryId)?.name || categoryId;

    setSelectedMaterials((prev) => {
      const existingIndex = prev.findIndex((m) => m.id === material.id);
      if (existingIndex >= 0) {
        return prev.filter((m) => m.id !== material.id);
      } else {
        return [...prev, { ...material, quantity: 1, category }];
      }
    });
    setSearchTerm('');
  };

  const updateQuantity = (id, quantity) => {
    setSelectedMaterials((prev) =>
      prev.map((m) => (m.id === id ? { ...m, quantity } : m))
    );
  };

  const addCustomMaterial = (newMaterial) => {
    if (!Array.isArray(categories.data)) {
      console.error("Categories data is not an array");
      return;
    }

    const categoryIndex = categories?.data?.findIndex((c) => c.id === newMaterial.categoryId);

    if (categoryIndex !== -1) {
      setCategories((prevCategories) => {
        const newCategories = { ...prevCategories };
        newCategories.data = [...prevCategories.data];
        return newCategories;
      });
    } else {
      setCategories((prevCategories) => ({
        ...prevCategories,
        data: [
          ...prevCategories.data,
          { id: newMaterial.categoryId, name: newMaterial.category, materials: [{ ...newMaterial, temporary: true }], jurisdiction: newMaterial.jurisdiction, currency: newMaterial.currency },
        ],
      }));
    }

    setSelectedMaterials((prevSelected) => [
      ...prevSelected,
      {
        ...newMaterial,
        category: categories?.data?.find((c) => c.id === newMaterial.categoryId)?.name || newMaterial.category,
        unit: newMaterial.unit,
        quantity: newMaterial.quantity || 1,
        temporary: true,
        currency: newMaterial.currency,
      },
    ]);

    setShowAddCustomPopup(false);
  };
  const total = selectedMaterials?.reduce((sum, m) => sum + m.price * m.quantity, 0);

  const groupedMaterials = selectedMaterials.reduce((acc, material) => {
    if (!acc[material.category]) {
      acc[material.category] = [];
    }
    acc[material.category].push(material);
    return acc;
  }, {});

  const handleSendQuoteClick = async () => {
    if (isEditMode) {
      const confirmation = await new Promise((resolve) => {
        openMainModal({
          title: "¿Estás seguro de que deseas guardar los cambios en la cotización?",
          body: "Se le notificará a los proveedores que se han realizado cambios en la cotización.",
          iconType: "warning",
          noLabel: "Cancelar",
          yesLabel: "Guardar cambios",
          confirmHandler: () => {
            closeMainModal();
            setIsLoading(true);
            resolve(true);
          },
          customCloseMainModal: () => {
            closeMainModal();
            resolve(false);
          },
        });
      });

      if (confirmation) {
        const formattedMaterials = selectedMaterials.map((material) => {
          const category = categories?.data?.find(
            (cat) => cat.name === material.category
          );
          return {
            category_id: category?.id || 0,
            material_id: material.id,
            temporary: material.temporary || false,
            amount: material.quantity,
            name: material.name,
            unit: material.unit,
          };
        });

        try {
          await editSubquotationMaterials(
            quotationData?.subquotation[0]?.id,
            formattedMaterials,
            navigate
          );
        } catch (error) {
          console.error("Error al editar los materiales:", error);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      setShowSendQuotePopup(true);
    }
  };

  const handleQuoteSuccess = () => {
    setSelectedMaterials([]);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setFilteredMaterials([]);
  };

  const handleSearchButtonClick = () => {
    setSearchInputActive(true);
    searchInputRef.current?.focus();
  };

  const titleWhenEmpty = isEstimacionDeCostos ? selectedMaterials.length > 0 ? "Para continuar selecciona una categoría"
    : "Comienza tu estimación" : selectedMaterials.length > 0 ? "Para continuar selecciona una categoría"
    : "Comienza tu cotización";

  const subTitleWhenEmpty = isEstimacionDeCostos ? selectedMaterials.length > 0 ? "Selecciona una categoría o busca materiales específicos para continuar con tu estimación de costos."
    : "Selecciona una categoría o busca materiales específicos para comenzar con tu estimación de costos" : selectedMaterials.length > 0 ? "Selecciona una categoría o busca materiales específicos para continuar con tu cotización."
    : "Selecciona una categoría o busca materiales específicos para comenzar a calcular el costo de tu proyecto.";

  const unitToShow = (unit) => {
    switch (unit) {
      case "m2":
        return "Metro cuadrado";
      case "m3":
        return "Metro cúbico";
      case "kg":
        return "Kilogramo";
      case "ml":
        return "Mililitro";
      case "cm":
        return "Centímetro";
      case "u":
        return "Unidad";
      case "l":
        return "Litro";
      case "cm2":
        return "Centímetro cuadrado";
      case "cm3":
        return "Centímetro cúbico";
      case "gr":
        return "Gramo";
      case "m":
        return "Metro";
      default:
        return unit;
    }
  };

  const handlePreviewButtonClick = () => {
    setShowPreview(true);
  };


  return (
    <>
      {isLoading ? <RegisteredUserProfileLoader /> : (
        <div className={styles.container}>
          <div className={styles.container_header}>
            <div className={styles.container_header_title}>
              Cotización de materiales
              <h1 className={styles.container_header_title_text}>
                {isEditMode
                  ? "Editar cotización"
                  : isEstimacionDeCostos
                    ? "Crear estimación de costos"
                    : "Crear cotización"}
              </h1>
            </div>
            <div className={styles.container_header_buttons}>
              <button
                className={styles.container_header_addCustomBtn}
                onClick={() => setShowAddCustomPopup(true)}
              >
                <Plus size={16} style={{ minHeight: "1.6rem", minWidth: "1.6rem" }} />
                Material personalizado
              </button>

              {isEstimacionDeCostos ? (
                <DownloadPDFButton
                  materials={selectedMaterials}
                  setShowSendQuotePopup={setShowSendQuotePopup}
                  className={styles.container_header_download} />
              ) : (
                <button
                  className={styles.container_header_quoteBtn}
                  onClick={handleSendQuoteClick}
                  disabled={isEditMode ? !hasChanges() : selectedMaterials.length === 0}
                >
                  <Send size={16} style={{ minHeight: "1.6rem", minWidth: "1.6rem" }} />
                  <span>
                    {isEditMode ? "Guardar cambios" : "Enviar para cotizar"}
                  </span>
                </button>
              )}
            </div>
          </div>
          <div className={styles.container_bodyContainer}>
            <div className={styles.container_body}>
              <div className={styles.container_body_header}>
                <div className={styles.container_body_header_inputs}>
                  <div className={`${styles.container_body_header_searchContainer} ${searchInputActive &&
                    styles.container_body_header_searchContainer_active}`}>
                    <Search size={20} />
                    <input
                      ref={searchInputRef}
                      type="text"
                      placeholder="Buscar material..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className={styles.container_body_header_searchContainer_input}
                    />
                    {searchTerm && (
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.clearIcon}
                        onClick={clearSearch}
                      />
                    )}
                    {searchTerm && filteredMaterials.length > 0 && (
                      <ul className={styles.container_body_header_searchContainer_filteredResults}>
                        {filteredMaterials.map((material) => (
                          <li className={styles.container_body_header_searchContainer_filteredResults_item} key={material.id} onClick={() => toggleMaterial(material, material.category)}>
                            <span className={styles.container_body_header_searchContainer_filteredResults_item_category}>{material.category}</span>
                            <span className={styles.container_body_header_searchContainer_filteredResults_item_material}>{material.name}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                    {searchTerm && filteredMaterials.length === 0 && (
                      <ul className={styles.container_body_header_searchContainer_filteredResults}>
                        <li className={styles.container_body_header_searchContainer_filteredResults_item}>
                          No se encontraron materiales
                        </li>
                      </ul>
                    )}
                  </div>
                  <div className={styles.container_body_header_categories}>
                    <select className={styles.container_body_header_categories_select}
                      value={selectedCategory}
                      onChange={(e) => toggleCategory(e.target.value)}
                    >
                      <option value="0">Seleccionar categoría</option>
                      {categories?.data
                        ?.slice()
                        .sort((a, b) => a?.name?.localeCompare(b.name))
                        .map((category) => (
                          <option key={category.id} value={category.id} >
                            {category.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className={styles.container_body_categoryMaterials}>
                {expandedCategories &&
                  Number(selectedCategory) !== 0 &&
                  expandedCategories.includes(selectedCategory) ? (
                  <motion.ul
                    variants={ulVariants}
                    initial="hidden"
                    animate="visible"
                    className={styles.container_body_categoryMaterials_list}
                  >
                    {categories?.data
                      ?.find((cat) => cat.id === selectedCategory)
                      ?.materials?.map((material) => {
                        const isChecked = selectedMaterials.some((m) => m.id === material.id);
                        const category = categories?.data?.find((cat) => cat.id === selectedCategory)?.name || selectedCategory;
                        return (
                          <li
                            key={material.id}
                            className={styles.container_body_categoryMaterials_list_item}
                            onClick={() => toggleMaterial(material, selectedCategory)}
                            style={{ cursor: "pointer" }}
                          >
                            <div className={styles.container_body_categoryMaterials_list_item_text}>
                              <span className={styles.container_body_categoryMaterials_list_item_material}>{material.name}</span>
                              <span className={styles.container_body_categoryMaterials_list_item_category}>{category}</span>
                            </div>
                            <div className={`${styles.container_body_categoryMaterials_list_item_buttonContainer} 
                            ${!isEstimacionDeCostos && styles.container_body_categoryMaterials_list_item_buttonContainer_estimacionDeCostos}`}>
                              <div className={styles.container_body_categoryMaterials_list_item_quantity}>
                                {isEstimacionDeCostos && (
                                  <span className={styles.container_body_categoryMaterials_list_item_price}>
                                    {new Intl.NumberFormat("es-AR", {
                                      style: "currency",
                                      currency: "ARS",
                                    }).format(material.price)}
                                  </span>
                                )}
                                <span className={styles.container_body_categoryMaterials_list_item_unit}>
                                  por {material.unit}
                                </span>
                              </div>
                              <button
                                onClick={() => selectedMaterials.some((m) => m.id === material.id)}
                                className={`${styles.container_body_categoryMaterials_list_item_button} 
                                ${isChecked && styles.container_body_categoryMaterials_list_item_button_checked}`}
                              >
                                {isChecked ? "Eliminar" : "Agregar"}

                              </button>
                            </div>
                          </li>
                        );
                      })}
                  </motion.ul>
                ) : (
                  <div className={styles.container_body_categoryMaterials_empty}>
                    <Package size={64} className={styles.container_body_categoryMaterials_empty_icon} />
                    <h2 className={styles.container_body_categoryMaterials_empty_title}>
                      {titleWhenEmpty}
                    </h2>
                    <p className={styles.container_body_categoryMaterials_empty_text}>{subTitleWhenEmpty}</p>
                    <div className={styles.container_body_categoryMaterials_empty_searchContainer}>
                      <select className={styles.container_body_header_categories_select}
                        onChange={(e) => toggleCategory(e.target.value)}
                      >
                        <option value="0">Seleccionar categoría</option>
                        {categories?.data
                          ?.slice()
                          .sort((a, b) => a?.name?.localeCompare(b.name))
                          .map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                      </select>
                      <button className={styles.container_body_categoryMaterials_empty_searchBtn} onClick={handleSearchButtonClick}>
                        Buscar material
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.container_summaryContainer}>
              <div className={styles.container_summary_stickyContainer}>
                <div className={styles.container_summary}>
                  <div className={styles.container_summary_header}>
                  <h2 className={styles.container_summary_title}>Resumen de materiales</h2>
                  <button
                    className={styles.container_summary_showPreviewBtn}
                    onClick={handlePreviewButtonClick}
                    title='Vista previa de materiales'
                    >
                    <Eye size={20} style={{minWidth: "2rem", minHeight: "2rem"}} />
                  </button>

                   
                  </div>
                  {selectedMaterials && selectedMaterials.length > 0 ? (
                    <div className={styles.container_summary_info}>
                      {Object.entries(groupedMaterials)?.map(([category, materials]) => (
                        <div key={category} className={styles.container_summary_info_category}>
                          <h3 className={styles.container_summary_info_category_title}>
                            {category || "Sin categoría"}
                          </h3>
                          {materials?.map((material) => (
                            <div
                              key={material.id}
                              className={styles.container_summary_info_category_item}
                            >
                              <div className={styles.container_summary_info_category_text}>
                                {material.name}
                              </div>
                              <div
                                className={
                                  styles.container_summary_info_category_actionsContainer
                                }
                              >
                                <div
                                  className={
                                    styles.container_summary_info_category_actions
                                  }
                                >
                                  <input
                                    type="number"
                                    min="1"
                                    className={
                                      styles.container_summary_info_category_quantityInput
                                    }
                                    value={material.quantity}
                                    onChange={(e) =>
                                      updateQuantity(
                                        material.id,
                                        parseInt(e.target.value) || material.quantity
                                      )
                                    }
                                  />
                                  <button
                                    onClick={() => removeMaterial(material.id)}
                                    className={
                                      styles.container_summary_info_category_actions_removeBtn
                                    }
                                  >
                                    <Trash2 size={16} />
                                  </button>
                                </div>
                                <span
                                  className={styles.container_summary_info_category_unit}
                                >
                                  {unitToShow(material.unit)}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.container_summary_empty}>
                      <Package
                        size={64}
                        className={styles.container_summary_empty_icon}
                      />
                      <h2 className={styles.container_summary_empty_title}>
                        No hay materiales seleccionados
                      </h2>
                    </div>
                  )}
                  <div className={styles.container_summary_info_category_total}>
                    {isEstimacionDeCostos ? (
                      <>
                        <div
                          className={styles.container_summary_info_category_total_text}
                        >
                          Total estimado
                          <span
                            className={styles.container_summary_info_category_total_price}
                          >
                            {new Intl.NumberFormat("es-AR", {
                              style: "currency",
                              currency: "ARS",
                            }).format(total || 0)}
                          </span>
                        </div>
                        <p
                          className={
                            styles.container_summary_info_category_total_disclaimer
                          }
                        >
                          *Los precios son estimativos y pueden variar.
                        </p>
                      </>
                    ) : (
                      <div
                        className={styles.container_summary_info_category_total_materials}
                      >
                        Has seleccionado {selectedMaterials.length} materiales
                      </div>
                    )}
                    {isEstimacionDeCostos ? (
                      <DownloadPDFButton
                        materials={selectedMaterials}
                        setShowSendQuotePopup={setShowSendQuotePopup}
                        className={styles.container_header_download}
                      />
                    ) : (
                      <button
                        className={styles.container_header_quoteBtn}
                        onClick={handleSendQuoteClick}
                        disabled={isEditMode ? !hasChanges() : selectedMaterials.length === 0}
                      >
                        <Send
                          size={16}
                          style={{ minHeight: "1.6rem", minWidth: "1.6rem" }}
                        />
                        <span>
                          {isEditMode ? "Guardar cambios" : "Enviar para cotizar"}
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showAddCustomPopup && (
            <AddCustomMaterialPopup
              onClose={() => setShowAddCustomPopup(false)}
              onAdd={addCustomMaterial}
              categories={categories}
              isEstimacionDeCostos={isEstimacionDeCostos}
            />
          )}
          {showSendQuotePopup && (
            <SendQuotePopup
              onClose={() => setShowSendQuotePopup(false)}
              onSend={handleSendQuoteClick}
              materials={selectedMaterials}
              onSuccess={handleQuoteSuccess}
              setShowPreview={setShowPreview}
            />
          )}
          {showPreview && (
            <MaterialsListPreview
              materials={selectedMaterials}
              sendHandler={handleSendQuoteClick}
              setShowSendQuotePopup={setShowSendQuotePopup}
              isEstimacionDeCostos={isEstimacionDeCostos}
              closePreview={() => setShowPreview(false)}
            />
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  categoriesWithMaterials: state.category.categories,
  quotationData: state.profile.quotation,
  materialsBySubquotation: state.profile.materialsBySubquotation,
});

export default connect(mapStateToProps, {
  getCategoriesWithMaterials,
  getMaterialsBySubquotationId,
  editSubquotationMaterials,
  openMainModal,
  closeMainModal,
})(CalculadoraDeMateriales);


