import React, { useState, useEffect } from "react";
import styles from "./FeedCard.module.scss";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import {
    likePost,
    likePostComment,
    responsePostComment,
    getPostComments,
    createPostComment,
    deletePost,
    openMainModal,
    editPostComment,
    editPosteo,
    deletePostComment
} from "../../../store/actions";
import { ReactComponent as Recomendar } from "../../../assets/svg/heartEmpty.svg";
import { ReactComponent as RecomendarCompleto } from "../../../assets/svg/heartSolid.svg";
import { ReactComponent as Comentar } from "../../../assets/svg/messageEmpty.svg";
import { ReactComponent as MundoPost } from "../../../assets/svg/worldPost.svg";
import { ReactComponent as DotPost } from "../../../assets/svg/dotPost.svg";
import { ReactComponent as SendPostComment } from "../../../assets/svg/sendPostComment.svg";
import { ReactComponent as PencilIcon } from "../../../assets/svg/editComment.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CancelIcon } from "../../../assets/svg/cancelIcon.svg";
import TextareaAutosize from "react-textarea-autosize";
import useUserImage from "../../../hooks/useUserImage";
import { ReactComponent as NoImage } from "../../../assets/svg/noImageIcon.svg";
import FeedCardComment from "./FeedCardComment";

const FeedCard = ({
    userData,
    posteos,
    likePost,
    likePostComment,
    responsePostComment,
    getPostComments,
    createPostComment,
    deletePost,
    deletePostComment,
    editPostComment,
    editPosteo,
    openMainModal,
    myPosts,
    ownPosteos,
    item,
    onLike,
    setIsMyLike,
    likes,
    setLikes,
    ...props }) => {
    const [commentContent, setCommentContent] = useState("");
    const [isEditingPost, setIsEditingPost] = useState(false);
    const [editPost, setEditPost] = useState("");
    const [editImage, setEditImage] = useState("");
    const [editImagePreview, setEditImagePreview] = useState("");
    const [editVideo, setEditVideo] = useState("");
    const [editVideoPreview, setEditVideoPreview] = useState("");
    const [editComment, setEditComment] = useState("");
    const [editSelectedCard, setEditSelectedCard] = useState("");
    const [loadingStates, setLoadingStates] = useState({});
    const [showMenu, setShowMenu] = useState(false);
    const [showMenuComments, setShowMenuComments] = useState(false);
    const [menuToShow, setMenuToShow] = useState("");
    const [menuToShowComments, setMenuToShowComments] = useState("");
    const [isEditingComment, setIsEditingComment] = useState(false);
    const [expandedStates, setExpandedStates] = useState({});
    const [showUsersReacted, setShowUsersReacted] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [responseInput, setResponseInput] = useState(false);
    const [responseCardComments, setResponseCardComments] = useState("");
    const [responseContent, setResponseContent] = useState("");
    const [showComments, setShowComments] = useState(false);
    const navigate = useNavigate();

    

    const { content, comments, id, createdAt, video, images } = item;
    const { name, last_name, razon_social, image_profile, profession, type, id: userId } = item.user;
let likesArray = item.likes|| [];
const isMyLike = likesArray.some(like => like?.user.id === userData?.id);
    const isMyPost = item?.user?.id === userData?.id;
    const hasImage = item.images[0]?.image ? true : false;
    const hasVideo = item.video[0]?.video ? true : false;
    const completeName = type === "COMPANY" ? razon_social : name + " " + last_name;
    const { imageError, initials, profileImage } = useUserImage(item.user);
    
    //Maneja el redireccionamiento al perfil del usuario
    const handleProfile = (id, completeName) => {
        navigate(`/profesionales/${id}/${completeName}`);
    }

    //Maneja el boton de recomendar poste
    const handleLike = async (id, isPost = true) => {
        setLoadingStates(prev => ({ ...prev, [id]: true }));
        const likeAction = isPost ? likePost : likePostComment;
    
        try {
            await likeAction(id);
    
            setLikes(prevState => {
                const alreadyLiked = prevState[id]?.some(like => like.id === userData?.id);
                return {
                    ...prevState,
                    [id]: alreadyLiked
                        ? prevState[id].filter(like => like.id !== userData?.id)
                        : [...(prevState[id] || []), userData],
                };
            });
        } catch (err) {
            console.error("Error al actualizar el like:", err);
        } finally {
            setLoadingStates(prev => ({ ...prev, [id]: false }));
        }
    };
    // Función para calcular la diferencia de tiempo en minutos, horas, días, semanas y meses
    const calcularDiferenciaTiempo = (fechaCreacion) => {
        const fechaActual = new Date();
        const fechaCreacionPost = new Date(fechaCreacion);
        const diferencia = fechaActual - fechaCreacionPost;

        const minutos = Math.floor(diferencia / (1000 * 60));
        const horas = Math.floor(diferencia / (1000 * 60 * 60));
        const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
        const semanas = Math.floor(dias / 7);
        const meses = Math.floor(dias / 30);

        if (minutos === 0) {
            return "Ahora";
        } else if (minutos === 1) {
            return "1 minuto";
        } else if (minutos < 60) {
            return `${minutos} minutos`;
        } else if (horas === 1) {
            return "1 hora";
        } else if (horas < 24) {
            return `${horas} horas`;
        } else if (dias === 1) {
            return "1 día";
        } else if (dias < 7) {
            return `${dias} días`;
        } else if (semanas === 1) {
            return "1 semana";
        } else if (semanas < 4) {
            return `${semanas} semanas`;
        } else if (meses === 1) {
            return "1 mes";
        } else {
            return `${meses} meses`;
        }
    };

    //Maneja el renderizado de los comentarios
    const handleShowPostComments = (id) => {
        getPostComments(id);
        setShowComments(!showComments);

    }

    //Maneja el posteo de comentarios 
    const handlePostComment = (postId, content) => {
        createPostComment(parseInt(postId), content);
        setCommentContent("");
    }

    //Manejea el menu de opciones del posteo

    const handleToggleMenu = () => {
        setShowMenu(!showMenu);

    }

    //Maneja el menu de opciones de un comentario
    const handleToggleMenuComments = (commentId) => {
        setShowMenuComments(prevState => ({
            ...prevState,
            [commentId]: !prevState[commentId]
        }));
    };

    //Maneja la expansion del contenedor de texto 
    const toggleExpanded = (id) => {
        setExpandedStates(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    //Funcion para eliminar un posteo
    const handleDeletePost = (id) => {
        openMainModal({
            title: "¿Estas seguro de querer eliminar el posteo?",
            body: "Si lo eliminas no podrás recuperarlo",
            isSuccess: false,
            confirmHandler: () => {
                deletePost(id)
            }
        })
    }

    //Funcion para eliminar un coemntario
    const handleDeleteComment = (id) => {
        openMainModal({
            title: "¿Estas seguro de querer eliminar el comentario?",
            body: "Si lo eliminas no podrás recuperarlo",
            isSuccess: false,
            confirmHandler: () => {
                deletePostComment(id)
            }
        })
    }

    //Funcion para editar un posteo
    const handleEditPost = (postId, images, content, video) => {
        let body = {
            id: postId,
            images: images,
            content: content,
            video: video
        }
        openMainModal({
            title: "¿Estas seguro de querer editar el posteo?",
            body: "Si lo editas no podrás recuperar la versión anterior",
            isSuccess: false,
            confirmHandler: () => {
                editPosteo(body)
            }
        })
        setIsEditingPost(false);
        setEditPost("");
        setEditImage("");
        setEditImagePreview("");
        setEditVideo("");
        setEditVideoPreview("");
    }

    //Funcion para editar un comentario
    const handleEditComment = (commentId, content) => {
        openMainModal({
            title: "Editar comentario",
            body: "¿Seguro quieres cambiar tu comentario?",
            isSuccess: false,
            confirmHandler: () => {
                editPostComment(commentId, content)
            }
        })
        setIsEditingComment(false);
        setEditComment("");
    }

    //Funcion para responder un comentario
    const handleResponseComment = (postId, commentId, content) => {
        responsePostComment(postId, commentId, content);
        setResponseInput(false);
        setResponseContent("");
    }

    const handleCancelEditPost = () => {
        setIsEditingPost(false);
        setEditPost("");
        setEditImage("");
        setEditImagePreview("");
        setEditVideo("");
        setEditVideoPreview("");
        setShowMenu(false);
    };



    //Maneja la selección de la opción del menu
    const handleMenuItemClick = (action, id, postMenu) => {
        setSelectedAction(action);
        setShowMenu(false);
        if (postMenu & action === "Eliminar") {
            handleDeletePost(id)
        }
        if (postMenu & action === "Editar") {
            setIsEditingPost(true);
            closeMenu();
        }
        if (!postMenu & action === "Eliminar") {
            handleDeleteComment(id)
        }
        if (!postMenu & action === "Editar") {
            setIsEditingComment(true);
            setEditSelectedCard(id);
        }
        if (action === "Cerrar") {
            closeMenu();
            setIsEditingPost(false);
            setIsEditingComment(false);
        }
    };

    // Función para cerrar el menú
    const closeMenu = () => {
        setShowMenu(false);
        setMenuToShow("");
    };

    // Función para cerrar el menú de comentarios
    const closeMenuComments = () => {
        setShowMenuComments(false);
        setMenuToShowComments("");
    };

    //Agrega un controlador de eventos al documento para cerrar el menú de comentarios cuando se hace clic fuera de él
    useEffect(() => {
        if (showMenuComments) {
            document.addEventListener('click', closeMenuComments);
        } else {
            document.removeEventListener('click', closeMenuComments);
        }

        return () => {
            document.removeEventListener('click', closeMenuComments);
        };
    }, [showMenuComments]);

    return (
        <section className={styles.generalContainer}>
            <div className={styles.feed_itemContainer}>
                <div className={styles.feed_itemHeader_profileContainer} >
                    <div className={styles.feed_itemHeader_profileContainer_nameAndImg} onClick={() => handleProfile(userId, completeName)}>
                        <div className={styles.feed_itemHeader_imgContainer} >
                            {!imageError && profileImage ? (
                                <img
                                    className={styles.feed_itemHeader_imgContainer_img}
                                    src={profileImage}
                                    alt="user"
                                />
                            ) : initials ? (
                                <div className={styles.feed_itemHeader_imgContainer_initials}>{initials}</div>
                            ) : (
                                <div className={styles.feed_itemHeader_imgContainer_img}>{<NoImage width={60} height={60} />}</div>
                            )}
                            

                        </div>
                        <div className={styles.feed_itemHeader_text}>
                            <div className={styles.feed_itemHeader_info}>
                                <p className={styles.feed_itemHeader_text_name}>{completeName}</p>
                                <div className={styles.feed_itemHeader_text_timeContainer}>                    
                                    <MundoPost width={15} height={15} />
                                    <span className={styles.feed_itemHeader_text_time}><DotPost height={10} width={10} /></span>
                                    {calcularDiferenciaTiempo(createdAt)}                          
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {hasImage && isEditingPost && (
                    editImagePreview ? (
                        <div className={styles.feed_itemBody_imgContainer}>
                            <img src={editImagePreview} alt="" className={styles.feed_itemBody_imgPost} />
                        </div>) : (
                        <label htmlFor="postImage" className={styles.feed_itemBody_input_item_label}>
                            <input
                                type="file"
                                accept="image/*"
                                id="postImage"
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        setEditImagePreview(URL.createObjectURL(file));
                                        setEditImage(file);
                                    }
                                }}
                                style={{ display: "none" }} />
                            <div className={styles.feed_itemBody_imgContainer}>
                                <img src={images[0]?.image} alt="" className={styles.feed_itemBody_imgPost} />
                                <p className={styles.feed_itemBody_imgContainer_editButton}>Cambiar imagen</p>
                            </div>
                        </label>))
                }
                {hasImage && !isEditingPost && (
                    <div className={styles.feed_itemBody_imgContainer}>
                        <img src={images[0]?.image} alt="" className={styles.feed_itemBody_imgPost} />
                    </div>
                )}
                {hasVideo && isEditingPost && (
                    editVideoPreview ? (
                        <div className={styles.feed_itemBody_videoContainer}>
                            <video src={editVideoPreview} controls className={styles.feed_itemBody_videoPost}></video>
                        </div>) : (
                        <label htmlFor="postVideo" className={styles.feed_itemBody_input_item_label}>
                            <input
                                type="file"
                                accept="video/*"
                                id="postVideo"
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        setEditVideoPreview(URL.createObjectURL(file));
                                        setEditVideo(file);
                                    }
                                }
                                }
                                style={{ display: "none" }} />
                            <div className={styles.feed_itemBody_videoContainer}>
                                <video src={video[0]?.video} controls className={styles.feed_itemBody_videoPost}></video>
                                <p className={styles.feed_itemBody_videoContainer_editButton}>Cambiar video</p>
                            </div>
                        </label>))
                }
                {hasVideo && !isEditingPost && (
                    <div className={styles.feed_itemBody_videoContainer}>
                        <video src={video[0]?.video} controls className={styles.feed_itemBody_videoPost}></video>
                    </div>
                )}

                {isEditingPost ? (
                    <div className={styles.feed_itemBody_inputContainer}>
                        <TextareaAutosize
                            type="text"
                            className={styles.feed_itemBody_input_item}
                            id="postText"
                            value={editPost || content}
                            onChange={(e) => setEditPost(e.target.value)}
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = (e.target.scrollHeight + 10) + 'px';
                            }}
                        />
                        <div className={styles.feed_itemBody_input_item_iconContainer} >
                            <div className={styles.feed_itemBody_input_item_iconContainer_cancelIcon} onClick={() => handleCancelEditPost()}>
                                <CancelIcon width={30} height={30} />
                            </div>
                            <div className={styles.feed_itemBody_input_item_iconContainer_sendIcon} onClick={() => handleEditPost(id, editImage, editPost, editVideo)}>
                                <SendPostComment width={30} height={30} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <motion.div className={styles.feed_itemBody_txt}>{content}</motion.div>
                )}
                <div className={styles.feed_itemHeader_footerContainer}>
                    <div className={styles.feed_itemHeader_footerContainer_reactions}>
                        <div className={styles.feed_itemHeader_actionsMenu_menu}>
                            <div className={styles.feed_itemHeader_actionsMenu_menu_item} onClick={() => handleLike(id, true)} onMouseEnter={() => setShowUsersReacted(true)} onMouseLeave={() => setShowUsersReacted(false)}>
                                {isMyLike ? <RecomendarCompleto width={20} height={20} title="Recomendar" /> : <Recomendar width={20} height={20} title="Recomendar" />}
                                {likesArray?.length > 0 && (
                                    <p className={styles.feed_itemHeader_actionsMenu_menu_item_number}>{likesArray?.length}</p>
                                )}         
                                {showUsersReacted && (
                                    <div className={styles.feed_itemHeader_optionsContainer_likes_showUsers_menu}>
                                        {
                                            Array.isArray(likesArray) && 
                                        likesArray.map((user, index) => {
                                            let completeName = user.type === "COMPANY" ? user.razon_social : user.name + " " + user.last_name;
                                            let imageProfile = user.image_profile;
                                            return (
                                                <motion.div
                                                    initial={{ opacity: 0, y: -10 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    exit={{ opacity: 0, y: -10 }}
                                                    className={styles.feed_itemHeader_optionsContainer_likes_showUsers_menu_item} key={index} onClick={() => handleProfile(user.id, completeName)}>
                                                    <img src={imageProfile} alt="" className={styles.feed_itemHeader_optionsContainer_likes_showUsers_menu_item_img} />
                                                    <p className={styles.feed_itemHeader_optionsContainer_likes_showUsers_menu_item_txt}>{completeName}</p>
                                                </motion.div>
                                            )
                                        })}
                                    </div>)}
                            </div>
                            <div className={styles.feed_itemHeader_actionsMenu_menu_item} onClick={() => handleShowPostComments(id)}>                
                                <Comentar width={20} height={20} title="Comentar" />
                                {comments?.length > 0 && (
                                    <p className={styles.feed_itemHeader_actionsMenu_menu_item_number}>{comments?.length}</p>
                                )}
                                Comentarios
                            </div>
                            
                        </div>
                        {isMyPost && (
                                <div className={styles.feed_itemHeader_options}>
                                    <div className={styles.feed_itemHeader_options_container_item} onClick={() => handleToggleMenu()}>
                                        <PencilIcon width={20} height={20} title="Editar" />
                                    </div>
                                    {showMenu && (
                                        <ul className={styles.feed_itemHeader_options_dropdownMenu}>
                                            <li className={styles.feed_itemHeader_options_dropdownMenu_close} onClick={() => handleToggleMenu()}>X</li>
                                            <li className={styles.feed_itemHeader_options_dropdownMenu_item} onClick={() => handleMenuItemClick("Editar", id, true)}>Editar</li>
                                            <li className={styles.feed_itemHeader_options_dropdownMenu_item} onClick={() => handleMenuItemClick("Eliminar", id, true)}>Eliminar</li>
                                        </ul>
                                    )}
                                </div>
                            )}
                    </div>
                </div>
                <FeedCardComment 
                    comments={comments}
                    userData={userData}
                    postId={id}
                    commentContent={commentContent}
                    setCommentContent={setCommentContent}
                    handleEditComment={handleEditComment}
                    handleDeleteComment={handleDeleteComment}
                    handleToggleMenuComments={handleToggleMenuComments}
                    showMenuComments={showMenuComments}
                    showComments={showComments}
                    editComment={editComment}
                    id={id}
                    handleMenuItemClick={handleMenuItemClick}
                    handlePostComment={handlePostComment}
                    isEditingComment={isEditingComment}
                    editSelectedCard={editSelectedCard}
                    setEditComment={setEditComment}
                    setIsEditingComment={setIsEditingComment}
                    calcularDiferenciaTiempo={calcularDiferenciaTiempo}
                />             
            </div>
        </section>
    )
};

const mapStateToProps = (state) => {
    return {
        userData: state.general.userData,
    }
}

export default connect(mapStateToProps, {
    likePost,
    likePostComment,
    responsePostComment,
    getPostComments,
    createPostComment,
    editPostComment,
    deletePost,
    deletePostComment,
    openMainModal,
    editPosteo,
})(FeedCard);
