import React, { useState } from "react";
import styles from "./RegisteredUserItem.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as StarEmpty } from "../../assets/svg/emptyStarSvg.svg";
import { ReactComponent as StarFull } from "../../assets/svg/starFullSvg.svg";
import { ReactComponent as StarHalf } from "../../assets/svg/halfStarSvg.svg";
import { ReactComponent as NoImage } from "../../assets/svg/noImageIcon.svg";
import { ImageOff, Star, Briefcase, MapPin, Mail, Globe } from "lucide-react";
import { connect } from "react-redux";
import { openSendContactNotificationModal } from "../../store/actions";
import usePremiumCheck from "../../hooks/usePremiumCheck";

const RegisteredUserItem = ({ user, openSendContactNotificationModal, stars, portfolio, profession, initialState, skills }) => {
  const [imageError, setImageError] = useState(false);

  const premiumCheck = usePremiumCheck();
  const navigate = useNavigate();

  const tags = Array.isArray(user?.skill)
    ? user.skill.map((skill) => skill.skill.name)
    : Array.isArray(skills)
      ? skills.map((skill) => skill.name)
      : skills?.name
        ? [skills.name]
        : [];
  const projectCount = user?.portfolio?.length || portfolio?.length;
  const userLocation = user?.zone;
  const webSite = user?.url_site_web;

  const maxVisibleTags = 2;
  const visibleTags = tags?.slice(0, maxVisibleTags);

  const userFullName = user?.type === "COMPANY" ? user?.razon_social : user?.name + " " + user?.last_name;

  const handleRedirectToUser = () => {
    navigate(`/profesionales/${user?.id}/${userFullName}`);
  }

  const getInitials = () => {
    if (user?.type === "PERSON") {
      const firstNameInitial = user?.name?.charAt(0) || "";
      const lastNameInitial = user?.last_name?.charAt(0) || "";
      return `${firstNameInitial}${lastNameInitial}`.toUpperCase();
    }
    if (user?.type === "COMPANY") {
      return user?.razon_social?.charAt(0)?.toUpperCase() || "";
    }
    return null;
  };

  const profileImage = user?.image_profile || null;
  const initials = getInitials();

  let starsArray = [];
  for (let i = 0; i < 5; i++) {
    if (i < stars) {
      starsArray.push(<StarFull key={i} width={10} height={10} />);
    } else if (i === stars && stars % 1 !== 0) {
      starsArray.push(<StarHalf key={i} width={10} height={10} />);
    } else {
      starsArray.push(<StarEmpty key={i} width={10} height={10} />);
    }
  }

  let portfolioImages = [];
  if (portfolio && portfolio.length > 0) {
    for (let i = 0; i < Math.min(portfolio.length, 3); i++) {
      const portfolioItem = portfolio[i];
      if (portfolioItem.portfolio_images && portfolioItem.portfolio_images.length > 0) {
        const firstImage = portfolioItem.portfolio_images[0].image;
        portfolioImages.push(
          <img key={i} src={firstImage} alt={`Portfolio ${i + 1}`} className={styles.portfolioImage} />
        );
      }

    }

  }

  if (portfolioImages.length === 0) {
    portfolioImages.push(
      <NoImage key="noImage" width={100} height={100} />
    );
  }

  const handleContact = () => {
    openSendContactNotificationModal(user)
  }

  return (
    <div className={styles.card} >
      <div className={styles.content}>
        <div className={styles.profileInfo}>
          <div className={styles.profileInfo_image}>
            {!imageError && profileImage ? (
              <img
                className={styles.profileInfo_image_img}
                src={profileImage}
                alt="user"
                onError={() => setImageError(true)}
              />
            ) : initials ? (
              <div className={styles.initialsContainer}>{initials}</div>
            ) : (
              <div className={styles.initialsContainer}>{<ImageOff />}</div>
            )}
          </div>
          <div className={styles.profileInfo_dataContainer}>
            <h4 className={styles.name}>{userFullName}</h4>
            <div className={styles.rating}>
              {[...Array(5)].map((_, i) => (
                <Star
                  key={i}
                  className={`${styles.star} ${i < stars ? styles.filled : styles.empty}`}
                />
              ))}
              <span className={styles.ratingValue}>({stars.toFixed(1)})</span>
            </div>
          </div>
        </div>
        <div className={styles.infoItem}>
          <Mail className={styles.icon} />
          <span>{user.username}</span>
        </div>
        {userLocation && (
          <div className={styles.infoItem}>
            <MapPin className={styles.icon} />
            <span>{userLocation}</span>
          </div>
        )}
        {webSite && (
          <div className={styles.infoItem}>
            <Globe className={styles.icon} />
            <span>{webSite}</span>
          </div>
        )}
        <div className={styles.infoItem}>
          <Briefcase className={styles.icon} />
          <span>
            {projectCount === 0
              ? 'No tiene proyectos en Edify'
              : `${projectCount} proyectos completados`}
          </span>
        </div>
        <div className={styles.infoItem_skills}>
          <div className={styles.tags}>
            {visibleTags?.map((tag, index) => (
              <span key={index} className={styles.tag}>
                {tag}
              </span>
            ))}
            {tags?.length > maxVisibleTags && (
              <button className={styles.showMoreButton} >
                {`(${tags?.length - maxVisibleTags})`}
              </button>
            )}
          </div>

        </div>
        <div className={styles.buttons}>
          <button className={styles.button} onClick={() => handleRedirectToUser()}>Ver perfil</button>
          <button className={styles.contactButton} onClick={premiumCheck(handleContact)}>Contactar</button>
        </div>

      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
  }
}

export default connect(mapStateToProps, { openSendContactNotificationModal })(RegisteredUserItem);