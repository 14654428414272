import React from 'react';
import styles from './QuotationForm.module.scss';
import moment from 'moment';
import 'moment/locale/es';
import { MapPin, Locate, DollarSign, SquarePen, CalendarDays, Clock } from 'lucide-react';

function QuotationForm({ quotation }) {

    moment.locale('es');
    const quo = quotation?.quotation;

    const calculateDaysLeft = (endDate) => {
        if (!endDate) return "No definido";
        const today = new Date();
        const end = new Date(endDate);
        const diff = end - today;
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        return days;
    }

    // Formateamos fechas
    const formatDate = (date) => {
        const utcDate = new Date(date);
        const year = utcDate.getUTCFullYear();
        const monthNames = [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ];
        const month = monthNames[utcDate.getUTCMonth()];
        const day = utcDate.getUTCDate();
        return `${day} de ${month} ${year}`;
    };

    return (
        <div className={styles.flexContainer}>
            <div className={styles.infoBox}>
                <h3 className={styles.title}>Detalles de la cotización</h3>
                <div className={styles.infoGrid}>
                    <div className={styles.infoRow}>
                        <div className={styles.infoContainer}>
                            <label className={styles.infoLabel}>
                                <MapPin size={16} />
                                Provincia</label>
                            <div className={styles.infoText}>{quo?.jurisdiccion?.name}</div>
                        </div>
                        <div className={styles.infoContainer}>
                            <label className={styles.infoLabel}>
                                <Locate size={16} />
                                Ciudad</label>
                            <div className={styles.infoText}>{quo?.zone}</div>
                        </div>
                        <div className={styles.infoContainer}>
                            <label className={styles.infoLabel}>
                                <DollarSign size={16} />Moneda</label>
                            <div className={styles.currency}> {quo?.currency === "ARS" ? "Pesos argentinos" : "Dolar"}</div>

                        </div>
                    </div>
                    <div className={styles.calendarHeader}>
                        <CalendarDays size={16} />
                        <h3 className={styles.calendarHeader_title}>Fechas para presupuestar</h3>
                    </div>
                    <div className={styles.calendarInfo}>
                        <div className={styles.calendarInfo_container} >
                            <div className={styles.calendarInfo_dates}>
                                <span>Inicio</span>
                                <span className={styles.calendarInfo_dates_date}>{formatDate(quo?.startDate)}</span>
                            </div>
                            <div className={styles.calendarInfo_divider} />
                            <div className={styles.calendarInfo_dates} >
                                <span>Fin</span>
                                <span className={styles.calendarInfo_dates_date}>{formatDate(quo?.endDate)}</span>
                            </div>
                            <div className={styles.calendarInfo_daysLeft}  >
                                <Clock size={16} />
                                <span >{calculateDaysLeft(quo?.endDate)}</span>
                                <span>Días restantes</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.infoContainer}>
                    <label className={styles.infoLabel}>
                        <SquarePen size={16} />
                        Descripción</label>
                    <textarea className={styles.textArea} value={quo?.description} readOnly></textarea>
                </div>
            </div>
        </div>
    );
}

export default QuotationForm;


