import * as actionTypes from "../actions/types";
import axios from "../../axios";
import {
  loadUserData,
  getProfileVisualizer,
  clearProjects,
  closeCalificationModal,
  getSkills,
} from "../actions";
import { fireAnalytics } from "../../utils/firebase";

export const viewAccessRequest = () => (dispatch) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  axios
    .get("/notificaciones/get", bearerToken)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_SOLICITUDE_LIST,
        payload: res.data,
      });
    })
    .catch((err) => { });
};

export const clearSubQuotations = () => {
  return {
    type: actionTypes.CLEAR_SUBQUOTATIONS,
  };
};

export const clearSubProjectBudgets = () => {
  return {
    type: actionTypes.CLEAR_SUBPROJECT_BUDGETS,
  };
};

export const openMainModal = (config) => {
  return {
    type: actionTypes.OPEN_MAIN_MODAL,
    payload: config,
  };
};

export const openEdifySpinner = (isOpen) => {
  return {
    type: actionTypes.OPEN_EDIFY_SPINNER,
    payload: isOpen,
  };
};

export const closeEdifySpinner = () => {
  return {
    type: actionTypes.CLOSE_EDIFY_SPINNER,
  };
};

export const setUsersBySkill = (users, selectedSkill) => {
  return {
    type: actionTypes.SET_USERS_BY_SKILL,
    payload: {
      users,
      selectedSkill,
    }
  };
};

export const resetSelectedSkill = () => {
  return {
    type: actionTypes.RESET_SELECTEDSKILL,
  };
};

export const setUsersInSkillName = (userList, totalPages, totalSkillsListed) => {
  return {
    type: actionTypes.SET_USERS_IN_SKILL_NAME,
    payload: {
      userList,
      totalPages,
      totalSkillsListed,
    },
  };
};

export const closeMainModal = () => {
  return {
    type: actionTypes.CLOSE_MAIN_MODAL,
  };
};

export const openNDAModal = (config) => {
  return {
    type: actionTypes.OPEN_NDA_MODAL,
    payload: config,
  };
};

export const closeNDAModal = () => {
  return {
    type: actionTypes.CLOSE_NDA_MODAL,
  };
};

export const openFeedTextModal = (config) => {
  return {
    type: actionTypes.OPEN_FEED_TEXT_MODAL,
    payload: config,
  };
};

export const closeFeedTextModal = () => {
  return {
    type: actionTypes.CLOSE_FEED_TEXT_MODAL,
  };
};

export const setFile = (file) => {
  return {
    type: actionTypes.SET_NEW_PROFILE_IMAGE,
    payload: file,
  };
};

export const editProfileValues = (values) => {
  return {
    type: actionTypes.SET_EDIT_PROFILE_VALUES,
    payload: values,
  };
};

export const setGoBackToCategoryList = (value) => {
  return {
    type: actionTypes.SET_GO_BACK_TO_CATEGORY_LIST,
    payload: value,
  };
};

export const setQuotationToEdit = (quotation) => {
  return {
    type: actionTypes.SET_QUOTATION_TO_EDIT,
    payload: quotation,
  };
};

export const resetSubProjects = () => {
  return {
    type: actionTypes.RESET_SUBPROJECTS,
  };
};

export const resetProjectToEdit = () => {
  return {
    type: actionTypes.RESET_PROJECT_TO_EDIT,
  };
};
export const openSuscriptionModal = () => {
  return {
    type: actionTypes.OPEN_SUSCRIPTION_MODAL,
  };
};

export const closeSuscriptionModal = () => {
  return {
    type: actionTypes.CLOSE_SUSCRIPTION_MODAL,
  };
};

export const setPrivateProjectLoading = (value) => {
  return {
    type: actionTypes.SET_PRIVATE_PROJECT_LOADING,
    payload: value,
  };
};

export const getProfileData = (id) => {
  let tipoUrl = `/user/data?userFind=${id}`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    dispatch({ type: actionTypes.START_LOADING });
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.MY_DATA,
          payload: res.data,
        });
        dispatch({ type: actionTypes.END_LOADING });
      })
      .catch((err) => {
        console.log(err)
        dispatch({ type: actionTypes.END_LOADING });
      });
  };
};

//Lo usamos para obetener los datos del perfil del usuario que envio un presupuesto
export const getProfileDataOfUserSender = (id) => {
  let tipoUrl = `/user/data?id=${id}`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.PROFILE_DATA_OF_USER_SENDER,
          payload: res.data,
        });
      })
      .catch((err) => { });
  };
};

export const getCalificationsByUser = (userId) => {
  let tipoUrl = `/perfil/likes?userFind=${userId}`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.USER_CALIFICATIONS,
          payload: res.data,
        });
      })
      .catch((err) => { });
  };
};
export const getUsersRegisteredData = () => {
  let tipoUrl = `/user/registers`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.USERS_REGISTERED_DATA,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const openShareProfile = (value) => {
  return {
    type: actionTypes.OPEN_SHARE_PROFILE,
    payload: value,
  };
};
export const openProfileMenu = (value) => {
  return {
    type: actionTypes.OPEN_PROFILE_MENU,
    payload: value,
  };
};

//Accion para abrir modal de datos personales en perfil

export const openEditProfileData = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.OPEN_EDIT_PROFILE_DATA });
  };
};

// accion para cerrar modal de datos personales en perfil
export const closeEditProfileData = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLOSE_EDIT_PROFILE_DATA });
  };
};


//accion para abrir modal de skills en perfil
export const openEditProfileSkill = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.OPEN_EDIT_PROFILE_SKILL });
  };
};

//accion para cerrar modal de skills en perfil
export const closeEditProfileSkill = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLOSE_EDIT_PROFILE_SKILL });
  };
};

//Acción para volver a un array vacio a la nueva skill creada
export const resetNewSkill = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_NEWSKILL_TO_LIST, payload: null });
  };
};

//Acción para crear una nueva skill a la lista general
export const createNewSkill = (skillName, isAtNewProject) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  let sendUrl = `/skill/create?name=${skillName}`;
  return (dispatch) => {
    axios
      .post(sendUrl, {}, bearerToken)
      .then((res) => {
        if (isAtNewProject) {
          dispatch({ type: actionTypes.SET_NEWSKILL_TO_LIST, payload: res.data });
          setTimeout(() => {
            dispatch(getSkills())
          }, 2000);
        } else {
          dispatch(
            openMainModal({
              title: "¡Has creado un nuevo rubro!",
              body: "El rubro ya está disponible en la plataforma",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "Se produjo un error al crear el rubro, intente nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

//accion para actualizar skills en perfil (falta eliminar)
export const sendSkillsProfile = (userSkills) => {
  let userSkillPost = userSkills.map((elem) => {
    return elem.id;
  });
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = {
    headers: { Authorization: `Bearer ${authToken}` },
    "Content-Type": "application/json",
  };
  const skillPostObj = {
    skillId: userSkillPost.map(Number),
  };
  return (dispatch) => {
    axios
      .post("/skill/assign", skillPostObj, bearerToken)
      .then((res) => {
        dispatch(loadUserData());
      })
      .catch((err) => {
        if (err?.response?.data?.errorCode == 401) {
          dispatch(
            openMainModal({
              title: "¡Ups!",
              body: "No hemos podido procesar los cambios, por favor inténtelo nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        } else {
          dispatch(
            openMainModal({
              title: "¡Ups!",
              body: "Se produjo un error al actualizar la información de su perfil. Inténtelo nuevamente en unos minutos o escríbanos a contacto@edify.la. ¡Muchas gracias!",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        }
      });
  };
};

export const getUsersBySkillName = (skillNames, fromSelect, page) => {
  const skillParams = skillNames.map(skill => `name=${encodeURIComponent(skill)}`).join('&');
  let tipoUrl = `/skill/usersBySkill?${skillParams}&order=ASC&page=${page ? page : 1}&take=12`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        if (fromSelect) {
          dispatch(openEdifySpinner(false));
          dispatch({
            type: actionTypes.SEARCH_SKILL,
            payload: { skillName: skillNames, users: res.data.itemcount },
          });
        } else {
          dispatch(openEdifySpinner(false));
          dispatch(setUsersBySkill(res.data, skillNames));
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(openEdifySpinner(false));
      });
  };
};

export const getAllUsersBySkills = () => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(`/skill/AllUsers`, bearerToken)
      .then((res) => {
        const skillsList = res.data.response.results;
        dispatch({
          type: actionTypes.SET_SKILLS_LIST,
          payload: skillsList,
        });

      })
      .catch((err) => {
        console.log(err)
      });
  };
};


//accion para editar datos personales en persona en perfil
export const testPersonaEdit = (personaForm, image) => {
  const formData = new FormData();

  const {
    dni_cuit,
    name,
    last_name,
    profession,
    phone,
    address,
    url_site_web,
    type,
    razon_social,
    instagram,
    twitter,
    linkedin,
    facebook,
    rubro,
  } = personaForm;
  if (image) formData.append("image", image);
  if (dni_cuit === undefined ? "" : dni_cuit)
    formData.append("dni_cuit", dni_cuit);
  if (name === undefined ? "" : name) formData.append("name", name);
  if (last_name === undefined ? "" : last_name)
    formData.append("last_name", last_name);
  if (profession === undefined ? "" : profession)
    formData.append("profession", profession);
  if (phone === undefined ? "" : phone) formData.append("phone", phone);
  if (address === undefined ? "" : address) formData.append("address", address);
  if (url_site_web === undefined ? "" : url_site_web)
    formData.append("url_site_web", url_site_web);
  if (type === undefined ? "" : type) formData.append("type", type);
  if (razon_social === undefined ? "" : razon_social)
    formData.append("razon_social", razon_social);
  if (rubro === undefined ? "" : rubro) formData.append("rubro", rubro);
  if (twitter === undefined ? "" : twitter) formData.append("twitter", twitter);
  if (instagram === undefined ? "" : instagram) formData.append("instagram", instagram);
  if (linkedin === undefined ? "" : linkedin) formData.append("linkedin", linkedin);
  if (facebook === undefined ? "" : facebook) formData.append("facebook", facebook);

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .patch("/user/update", formData, bearerToken)
      .then((res) => {
        dispatch(loadUserData());

        dispatch(
          openMainModal({
            title: "¡Has actualizado los datos de tu cuenta!",
            body: "Los cambios ya están visibles en tu perfil",
            isSuccess: true,
            confirmHandler: null,
            iconType: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "Ups!",
            body: "No hemos podido procesar los cambios, por favor inténtelo nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
            iconType: "error",
          })
        );
      });
  };
};

//accion para editar datos personales de empresa en perfil
export const testEmpresaEdit = (empresaForm, image) => {
  const formData = new FormData();
  const {
    dni_cuit,
    razon_social,
    profession,
    phone,
    address,
    rubro,
    url_site_web,
    instagram,
    twitter,
    linkedin,
    facebook,
    type,
  } = empresaForm;

  if (image) formData.append("image", image);
  if (dni_cuit === undefined ? "" : dni_cuit)
    formData.append("dni_cuit", dni_cuit);
  if (profession === undefined ? "" : profession)
    formData.append("profession", profession);
  if (phone === undefined ? "" : phone) formData.append("phone", phone);
  if (address === undefined ? "" : address) formData.append("address", address);
  if (rubro === undefined ? "" : rubro) formData.append("rubro", rubro);
  if (url_site_web === undefined ? "" : url_site_web)
    formData.append("url_site_web", url_site_web);
  if (type === undefined ? "" : type) formData.append("type", type);
  if (razon_social === undefined ? "" : razon_social)
    formData.append("razon_social", razon_social);
  if (twitter === undefined ? "" : twitter) formData.append("twitter", twitter);
  if (instagram === undefined ? "" : instagram) formData.append("instagram", instagram);
  if (linkedin === undefined ? "" : linkedin) formData.append("linkedin", linkedin);
  if (facebook === undefined ? "" : facebook) formData.append("facebook", facebook);

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .patch("/user/update", formData, bearerToken)
      .then((res) => {
        dispatch(loadUserData());

        dispatch(
          openMainModal({
            title: "¡Has actualizado los datos de tu cuenta!",
            body: "Los cambios ya están visibles en tu perfil",
            isSuccess: true,
            confirmHandler: null,
            iconType: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "Ups!",
            body: "No hemos podido procesar los cambios, por favor inténtelo nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
            iconType: "error",
          })
        );
      });
  };
};

//accion para editar descripcion en perfil
export const testDescriptionEdit = (description) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .patch("/user/descripcionUpdate", { description }, bearerToken)
      .then((res) => {
        dispatch(loadUserData());
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "Ups!",
            body: "No hemos podido procesar los cambios, por favor inténtelo nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

//POSTULACIONES
//accion para obtener postulaciones en perfil

export const getPostulations = (userId) => (dispatch) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  const url = userId
    ? `/postulacion/user?userFind=${userId}`
    : "/postulacion/user";
  axios
    .get(url, bearerToken)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_POSTULATIONS,
        payload: res.data,
      });
    })
    .catch((err) => { });
};

//PROYECTOS

export const setProjectIdForSub = (project) => {
  return {
    type: actionTypes.SET_PROJECT_ID,
    payload: project,
  };
};


//accion para obtener proyectos en perfil

export const getProjects = (id) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  const url = id ? `/proyecto/user?userFind=${id}` : "/proyecto/user";
  return (dispatch, getState) => {
    axios
      .get(url, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_PROJECTS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

//Obtiene los datos de un proyecto de obra privada por id
export const getPrivateProjectById = (id) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    dispatch(setPrivateProjectLoading(true));
    axios
      .get(`/proyecto/${id}`, bearerToken)
      .then((res) => {
        dispatch(setPrivateProjectLoading(false));
        dispatch({
          type: actionTypes.GET_PRIVATE_PROJECT_BY_ID,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(setPrivateProjectLoading(false));
        console.log(err)
      });
  };
};

//Obtiene los datos de un proyecto de obra publica por id

export const getPublicProjectById = (id) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios
      .get(`/proyecto/${id}`, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_PUBLIC_PROJECT,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  }
}

export const getQuotationById = (id, isEditing, publicQuotation) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(`/quotation/${id}`, bearerToken)
      .then((res) => {
        if (publicQuotation) {
          dispatch({
            type: actionTypes.SET_PUBLIC_QUOTATION,
            payload: res.data,
          });
        } else {
          dispatch({
            type: actionTypes.GET_QUOTATION,
            payload: res.data,
          });
          if (isEditing) {
            dispatch(setQuotationToEdit(res.data));
          };
        }
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

export const getAdjudicatedProjectById = (id) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(`/adjudicados/${id}`, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ADJUDICATED_PUBLIC_PROJECT,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

//Nueva funcion de crear proyecto 
export const createNewProject = (proyecto, subProjects, navigate) => {
  fireAnalytics("confirm_publish_project", { category: "publishing" });
  const formData = new FormData();
  formData.append("description", proyecto.description);
  formData.append("endDate", proyecto.endDate);
  formData.append("active", 1);
  formData.append("name", proyecto.name);
  formData.append("startDate", proyecto.startDate);
  formData.append("zone", proyecto.zone);
  proyecto.image.forEach((image) => {
    formData.append(`images`, image);
  });

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .post("/proyecto/assign", formData, bearerToken)
      .then((res) => {
        let projectId = res.data.id;
        let promises = subProjects.map(async (sub, index) => {
          return await dispatch(postSubprojects(sub, projectId));
        });
        dispatch(openEdifySpinner(false));
        Promise.all(promises).then((response) => {
          dispatch(openMainModal({
            title: " ¡Gracias por publicar en edify! ",
            iconType: "success",
            body: "El proyecto ya se encuentra publicado en la plataforma, ¡esperamos que tengas muchos presupuestos!",
            isSuccess: true,
            noLabel: "Cerrar",
            confirmHandler: null
          }));

          setTimeout(() => {
            dispatch(getPrivateProjectById(projectId));
          }, 2000);
          setTimeout(() => {
            navigate(`/obrasprivadas/${projectId}`);
          }, 2000);
        });
      })
      .catch((err) => {
        dispatch(openEdifySpinner(false));
        dispatch(openMainModal({
          title: "Ups!",
          body: "Se produjo un error al crear el proyecto, intente nuevamente o escríbanos a contacto@edify.la para poder asistirlo. ¡Muchas gracias!",
          isSuccess: true,
          confirmHandler: null,
        }));
      });
  };
};

export const postSubprojects = (subproject, projectId, isEditing, setShowSubProjectsModal) => {

  let {
    name,
    description,
    endDate,
    startDate,
    subProyectoListId,
    link,
    plantillaDePresupuesto,
    computoMetrico,
    especificacionesTecnicas,
    planosArchivo,
    planosFile
  } = subproject;

  let splitOptionValues = name
    ? name.includes("*")
      ? name.split("*")[1]
      : name
    : null;

  const formData = new FormData();
  formData.append("name", splitOptionValues)
  formData.append("description", description);
  formData.append("proyecto", projectId);
  subProyectoListId
    ? formData.append("skill", parseInt(subProyectoListId))
    : formData.append("skill", parseInt(splitOptionValues[0]));
  formData.append("startDate", startDate);
  formData.append("endDate", endDate);
  formData.append("link", link);
  planosArchivo ? formData.append("planos", planosArchivo) : formData.append("planos", planosFile);
  plantillaDePresupuesto
    ? formData.append("budget", plantillaDePresupuesto)
    : formData.append("budget", null);
  formData.append("status", "OPEN");
  computoMetrico
    ? formData.append("metricComputation", computoMetrico)
    : formData.append("metricComputation", null);
  especificacionesTecnicas
    ? formData.append("technicalSpecifications", especificacionesTecnicas)
    : formData.append("technicalSpecifications", null);
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    if (isEditing) {
      dispatch(openEdifySpinner(true));
    }
    axios
      .post("/sub_proyecto/crear", formData, bearerToken)
      .then((res) => {
        dispatch(setProjectToEdit(projectId));
        dispatch(setSubProjectId(res.data));
        dispatch(openEdifySpinner(false));
        if (isEditing) {
          dispatch(
            openMainModal({
              title: " ¡Tu rubro se agregó correctamente! ",
              body: "¿Deseas cambiar o sumar otro rubro al proyecto?",
              iconType: "success",
              noLabel: "No, ya termine de editar mi proyecto",
              confirmHandler: () => {
                dispatch({ type: actionTypes.RESET_SUBPROJECTFORM_STYLE });
                dispatch(closeMainModal());
                dispatch(getPrivateProjectById(projectId));
              },
              customCloseMainModal: () => {
                dispatch(getPrivateProjectById(projectId));
                setShowSubProjectsModal(false);
                dispatch(openMainModal({
                  title: " ¡Has editado tu proyecto con éxito! ",
                  body: "Los cambios ya se encuentran publicados en la plataforma, ¡muchas gracias!",
                  isSuccess: true,
                  iconType: "success",
                  noLabel: "Cerrar",
                  confirmHandler: null
                }));
              }
            }));
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(openEdifySpinner(false));
        dispatch(openMainModal
          ({
            title: " ¡Ups! ",
            body: "Hubo un error al agregar el rubro, por favor intenta nuevamente o escríbenos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};
//update proyecto

export const updateProyecto = (portfolioForm, file, id, navigate) => {
  const formData = new FormData();
  if (file.length > 0) {
    file.forEach((image) => {
      formData.append("images", image);
    });
  }
  formData.append("name", portfolioForm.name);
  formData.append("endDate", portfolioForm.endDate);
  formData.append("description", portfolioForm.description);
  formData.append("id", id);
  formData.append("zone", portfolioForm.zone);
  formData.append("startDate", portfolioForm.startDate);
  formData.append("active", "1");

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .patch("/proyecto/update", formData, bearerToken)
      .then((res) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: " ¡Tu proyecto se actualizó correctamente! ",
            body: "Gracias por confiar en edify",
            iconType: "success",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        navigate(`/obrasprivadas/${id}`)
        dispatch(getPrivateProjectById(id));
      })
      .catch((err) => {
        console.log(err)
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "Se produjo un error al actualizar el proyecto, intente nuevamente o escríbanos a contacto@edify.la ¡Muchas gracias!",
            iconType: "error",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(clearPostulationProject());
      });
  }
};

//delete proyecto

export const deleteProyecto = (id, navigate) => {
  fireAnalytics("delete_project", { category: "publishing" });
  let authToken = localStorage.getItem("access-Token-Edify");
  return (dispatch) => {
    dispatch(
      openMainModal({
        title: "¿Estás seguro que deseas eliminar el proyecto?",
        body: "Una vez eliminado no podrás recuperar los datos ingresados",
        iconType: "warning",
        confirmHandler: () => {
          dispatch(openEdifySpinner(true));
          axios
            .delete("/proyecto/delete", {
              headers: { Authorization: `Bearer ${authToken}` },
              data: { idProyecto: id }
            })
            .then((res) => {
              dispatch(getProjectsByType("PRIVATE"));
              dispatch(closeMainModal());
              navigate("/obrasprivadas");
              dispatch(openEdifySpinner(false));
              dispatch(
                openMainModal({
                  title: " ¡Eliminado! ",
                  body: "Tu proyecto se ha eliminado con éxito",
                  iconType: "success",
                  isSuccess: true,
                  confirmHandler: null,
                })
              );
            })
            .catch((err) => {
              console.log(err)
              dispatch(openEdifySpinner(false));
              dispatch(
                openMainModal({
                  title: "Ups!",
                  body: "Se produjo un error al eliminar el proyecto, intente nuevamente o escribanos a contacto@edify.la ¡Muchas gracias!",
                  isSuccess: true,
                  confirmHandler: null,
                  iconType: "error",
                })
              );
            });
        },
      })
    );
  };
};

//Borrar imagenes del proyecto creado
export const deleteImageFromProject = (id, projectId) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  return (dispatch) => {
    axios
      .delete("/proyecto/deleteProjectImage", {
        headers: { Authorization: `Bearer ${authToken}` },
        data: {
          id: id,
        },
      })
      .then((res) => {
        dispatch(
          openMainModal({
            title: " ¡Eliminado! ",
            body: "La imagen se ha eliminado con éxito",
            iconType: "success",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getPrivateProjectById(projectId));
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "Ups!",
            body: "Se produjo un error al eliminar la imagen, intente nuevamente o escribanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

//Proceso de calificación

export const likeUser = (stars, comment, userReceiverId, skillIds) => {
  const data = {
    stars,
    comment,
    userReceiverId,
    skillIds,
  };

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .post("/perfil/like", data, bearerToken)
      .then((response) => {
        dispatch(
          openMainModal({
            title: "¡Excelente!",
            body: `El proceso de calificación fue completado con éxito. ¡Gracias por tu tiempo!`,
            iconType: "success",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getProfileVisualizer(userReceiverId));
        dispatch(getCalificationsByUser(userReceiverId))
        dispatch(closeCalificationModal());
      })
      .catch((error) => {
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "Se produjo un error al calificar, intente nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
            iconType: "error",
          })
        );
      });
  };
};

//Generar una visita de un perfil a otro
export const generateVisit = (userId) => {


  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios
      .post("/perfil/view", {
        profileUserId: userId,
      }, bearerToken)
      .then((response) => {
      })
      .catch((error) => { }
      );
  };
};

//Obtener los datos de las visitas recibidas
export const getVisits = () => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios
      .get("/perfil/views", bearerToken)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_VISITS,
          payload: response.data,
        });
      })
      .catch((error) => {
      }
      );
  };
};

//Setear el proyecto completo para editar
export const setProjectToEdit = (id) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  return (dispatch) => {
    axios
      .get(`/proyecto/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.SET_PROJECT_TO_EDIT,
          payload: res.data,
        });
        dispatch(setPostulationProject(res.data));
      })

      .catch((err) => {
        console.log(err)
      });
  };
};

//Setear Id de subProyecto
export const setSubProjectId = (subProjectEdit) => {
  return {
    type: actionTypes.SET_SUBPROJECT_ID,
    payload: subProjectEdit,
  };
};

//Setear estilo oculto en formulario de subproyectos
export const setSubProjectStyle = (style) => {
  return {
    type: actionTypes.SET_SUBPROJECTFORM_STYLE,
    payload: style,
  };
};

//Borrar subproyecto /sub_proyecto/delete

export const deleteSubProjects = (subProjectId, projectToEditId) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  return (dispatch) => {
    dispatch(
      openMainModal({
        title: "¿Estás seguro que deseas eliminar el rubro?",
        body: "Una vez eliminado no podrás recuperar los datos ingresados",
        iconType: "warning",
        confirmHandler: () => {
          dispatch(openEdifySpinner(true));
          axios
            .delete("/sub_proyecto/delete", {
              headers: { Authorization: `Bearer ${authToken}` },
              data: { idSubProject: subProjectId }
            })
            .then((res) => {
              dispatch(openEdifySpinner(false));
              dispatch(getPrivateProjectById(projectToEditId));
              dispatch(setProjectToEdit(projectToEditId));
              dispatch(
                openMainModal({
                  title: "¡Tu rubro se eliminó correctamente!",
                  iconType: "success",
                  isSuccess: true,
                  confirmHandler: null,
                  customCloseMainModal: () => {
                    dispatch(closeMainModal());
                  },
                })
              );
            })
            .catch((error) => {
              dispatch(openEdifySpinner(false));
              dispatch(openMainModal
                ({
                  title: " ¡Ups! ",
                  iconType: "error",
                  body: "Hubo un error al eliminar el rubro, por favor intenta nuevamente",
                  isSuccess: false,
                  confirmHandler: null,
                })
              );
            });
        },
        customCloseMainModal: () => {
          dispatch(closeMainModal());
        },
      })
    );
  };
};


export const getSubprojectsType = () => (dispatch) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  axios
    .get("/sub_proyecto/list", bearerToken)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_SUBPROJECTS_TYPE,
        payload: res.data,
      });
      dispatch({
        type: actionTypes.SHOW_SUBQUOTATIONS,
      });
    })
    .catch((err) => { });
};

//Crea un subProyecto para el proyecto en el reducer
export const setSendSubProject = (sendSubproject) => {
  return {
    type: actionTypes.SET_SEND_SUBPROJECT,
    payload: sendSubproject,
  };
};

//Elimina un subProyecto para el proyecto en el reducer
export const deleteSubProjectFromReducer = (sendSubproject) => {
  return {
    type: actionTypes.SET_DELETE_SUBPROJECT,
    payload: sendSubproject,
  };
};


export const setEditSendSubProject = (sendSubproject, subProjectToEdit) => {
  return {
    type: actionTypes.SET_EDIT_SUBPROJECT,
    payload: {
      sendSubproject: sendSubproject,
      subProjectToEdit: subProjectToEdit,
    },
  };
};

//Cotizaciones / subCotizaciones
export const getSubquotationType = () => (dispatch) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  axios
    .get("/sub_quotation/list", bearerToken)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_SUBQUOTATIONS_TYPE,
        payload: res.data,
      });
      dispatch({
        type: actionTypes.SHOW_SUBQUOTATIONS,
      });
    })
    .catch((err) => {
      console.log(err)
    });
};

//setear la subcotizacion que estamos cargando en el reducer
export const setSendSubQuotation = (sendSubQuotation) => {
  return {
    type: actionTypes.SET_SEND_SUBQUOTATION,
    payload: sendSubQuotation,
  };
};

export const setNewSubQuotation = (subquotation) => {
  return {
    type: actionTypes.SET_NEW_SUBQUOTATION,
    payload: subquotation,
  };
};

//Eliminamos la subcotizacion en el proceso de creacion de una cotizacion
export const deleteSubQuotationFromReducer = (sendSubQuotation) => {
  return {
    type: actionTypes.SET_DELETE_SUBQUOTATION,
    payload: sendSubQuotation,
  };
};

//Obtener las preguntas de un producto o servicio de un perfil
export const getPortfolioQuestions = (idProyecto) => (dispatch) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  axios
    .get(`/portfolio/question?project=${idProyecto}`, bearerToken)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_PORTFOLIO_QUESTIONS,
        payload: res.data,
        idProyecto: idProyecto,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

//Enviar pregunta al producto o servicio de un perfil

export const sendPortfolioQuestions = (user_id, portfolioId, question) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  const data = {
    user_id: parseInt(user_id),
    portfolio: parseInt(portfolioId),
    question: question,
    email: null,
    name: null,
  };

  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .post("/portfolio/question", data, bearerToken)
      .then((res) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Éxito!",
            iconType: "success",
            body: `Tu pregunta ha sido enviada con éxito`,
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getPortfolioQuestions(portfolioId));
      })
      .catch((error) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Ups!",
            iconType: "error",
            body: "No hemos podido procesar tu pregunta, por favor intenta nuevamente o escríbenos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

//Responder a una pregunta de un producto o servicio de un perfil
export const answerPortfolioQuestion = (questionId, answer, portfolioId) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  const data = {
    questionId: parseInt(questionId),
    answer: answer,
  };

  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .post("/portfolio/question/respond", data, bearerToken)
      .then((res) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Éxcelente!",
            iconType: "success",
            body: `Tu respuesta ha sido enviada con éxito`,
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getPortfolioQuestions(portfolioId));
      })
      .catch((error) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Ups!",
            iconType: "error",
            body: "No hemos podido procesar tu respuesta, por favor intenta nuevamente o escríbenos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

//Obtener preguntas del proyecto
export const getProjectQuestions = (idProyecto) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(`/proyecto/question?project=${idProyecto}`, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_PROJECT_QUESTIONS,
          payload: res.data,
          idProyecto: idProyecto,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
}

//Enviar pregunta al proyecto

export const sendProjectQuestions = (projectId, question,) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  const data = {
    projectId: parseInt(projectId),
    question: question,
  };

  return (dispatch) => {
    axios
      .post("/proyecto/question", data, bearerToken)
      .then((res) => {
        dispatch(
          openMainModal({
            title: "¡Éxito!",
            body: `Tu pregunta ha sido enviada con éxito`,
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getProjectQuestions(projectId));
      })
      .catch((error) => {
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: `${error?.response?.data?.message === "Ya hizo una pregunta en el proyecto" ? "Ya realizaste una pregunta en este proyecto, espera a que el usuario responda a tu solicitud."
              : "No hemos podido procesar tu pregunta, por favor intenta nuevamente o escríbenos a contacto@edify.la ¡Muchas gracias!"}`,
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

//Respuesta a una pregunta del proyecto
export const answerProjectQuestion = (questionId, answer, projectId) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  const data = {
    projectId: parseInt(projectId),
    questionId: parseInt(questionId),
    answer: answer,
  };

  return (dispatch) => {
    axios
      .post("/proyecto/question/respond", data, bearerToken)
      .then((res) => {
        dispatch(
          openMainModal({
            title: "¡Éxito!",
            body: `Tu respuesta ha sido enviada con éxito`,
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getProjectQuestions(projectId));
      })
      .catch((error) => {
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "No hemos podido procesar tu respuesta, por favor intenta nuevamente o escríbenos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};



export const getProjectsByType = (type, page = 1, take = 5, currentPage) => {
  if (currentPage) {
    page = currentPage;
  }

  let authToken = localStorage.getItem("access-Token-Edify");
  const axiosData = {
    method: "get",
    url: `/proyecto/getWithPagination?type=${type}&order=DESC&page=${page}&take=${take}`,
    headers: {
      Authorization: `Bearer ${authToken}`,
      "content-type": "application/json",
    },
  };
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DASHBOARD_PROJECTS_LOADING,
      payload: true,
    });
    axios(axiosData)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_DASHBOARD_PROJECTS,
          payload: {
            data: response?.data || response?.data,
            page,
            itemCount: response?.data?.itemCount,
            take: response?.data?.take,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_DASHBOARD_PROJECTS_LOADING,
          payload: false,
        });
        dispatch(
          openMainModal({
            title: "¡Error!",
            body: `${error?.response?.data?.message}`,
            isSuccess: true,
            confirmHandler: null,
          })
        );

      });
  };
};

// Acceso a Proyecto: Provider a Oferor
export const accessRequest = (idProyecto, navigate, userId, paginacion) => {
  let currentPage = paginacion;
  let authToken = localStorage.getItem("access-Token-Edify");
  const data = {
    idProyecto: parseInt(idProyecto),
  };
  const axiosData = {
    method: "post",
    url: "/postulacion/request",
    data: data,
    headers: {
      Authorization: `Bearer ${authToken}`,
      "content-type": "application/json",
    },
  };
  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios(axiosData)
      .then(() => {     
        dispatch(clearProjects());
      })
      .then((userId) => {
        dispatch(getProjectsByType("PRIVATE", currentPage));
        dispatch(getPostulations(userId));
      })
      .then(() => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Tu postulación se envió correctamente!",
            body: "El oferente recibirá tu solicitud y te responderá a la brevedad.",
            iconType: "success",
            isSuccess: true,
            confirmHandler: null,
            customCloseMainModal: () => {
              dispatch(closeMainModal());
            }
          })
        );
      })
      .catch((error) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Error!",
            body: "Ya realizaste una postulacion, espera a que el usuario responda a tu solicitud.",
            iconType: "error",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

//Acceso a Proyecto: Oferor a Provider

export const validateAccessRequest = (permisoId, status, solicitudType) => {
  fireAnalytics("nda_confirm_approval", { category: "publishing" });
  let authToken = localStorage.getItem("access-Token-Edify");
  let url = "";
  let data = {};

  if (solicitudType === "POSTULACION_ACCEPTED") {
    url = "postulacion/request/respond";
    data = { permisoId: parseInt(permisoId), status: status };
  } else if (solicitudType === "POSTULACION_NEGATED") {
    url = "postulacion/request/respond";
    data = { permisoId: parseInt(permisoId), status: status };
  }

  return (dispatch) => {
    axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        "content-type": "application/json",
      },
    })
      .then((response) => {
        if (status === "ALLOWED") {
          dispatch(viewAccessRequest());
          dispatch(
            openMainModal({
              title: "¡Has aceptado una solicitud de postulación!",
              iconType: "success",
              body: "Daremos aviso al usuario para que inicien el proyecto.",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        } else {
          dispatch(viewAccessRequest());
          dispatch(
            openMainModal({
              title: "Has rechazado la solicitud de postulación",
              body: "Daremos aviso al usuario.",

              isSuccess: true,
              confirmHandler: null,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "Ha ocurrido un error al intentar responder a la solicitud, por favor intenta nuevamente o escribenos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

//Aceptar Presupuesto
export const validateBudgetRequest = (
  budgetId,
  status,
  subProjectId,
  selectedUser
) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  return (dispatch) => {
    axios({
      method: "post",
      url: "/sub_proyecto/budget/respond",
      data: {
        budgetId: budgetId,
        status: status,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        "content-type": "application/json",
      },
    })
      .then((response) => {
        if (status === "ACCEPTED") {
          fireAnalytics("confirm_accept_budget", { category: "publishing" });
          dispatch(closeSubproject(subProjectId, selectedUser));
        } else {
          dispatch(viewBudgetRequest());
          dispatch(
            openMainModal({
              title: "Has rechazado el presupuesto",
              body: "Daremos aviso al usuario.",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

//Cerrar subproyecto /sub_proyecto/close

export const closeSubproject = (subProjectId, selectedUser) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  return (dispatch) => {
    axios({
      method: "post",
      url: `/sub_proyecto/close?subproject=${subProjectId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        dispatch(viewBudgetRequest());
        dispatch(
          openMainModal({
            title: "¡Has aceptado el presupuesto!",
            body: `El rubro fue cerrado. Daremos aviso a ${selectedUser} para que inicien el proyecto`,
            isSuccess: true,
            confirmHandler: null,
          })
        );
      })
      .catch((error) => {
        dispatch(
          openMainModal({
            title: "¡Error!",
            body: `${error.response.data.message}`,
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

export const getViewAccessProfile = async (id) => {
  /// user/profile/data?id=52 Ejemplo
  let tipoUrl = `/user/data?id=${id}`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  const response = await axios.get(tipoUrl, bearerToken);
  return response.data;
};
///sub_proyecto/budget

//Get de notificaciones de usuario



//Marca las notificaciones como vistas

export const setViewNotification = (not_id) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  const idNotificacion = Number(not_id);
  return (dispatch) => {
    axios
      .patch("notificaciones/view", { idNotificacion }, bearerToken)
      .then((res) => {
        dispatch(viewAccessRequest());
      })
      .catch((err) => { });
  };
};

//Envio de notificacion de contacto entre usuarios

export const sendContactNotification = (userReceiver, message, closeSendContactNotificationModal) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  const contactNotification = {
    userReceiver: userReceiver,
    message: message
  }

  return (dispatch) => {
    axios
      .post("notificaciones/send-contact", contactNotification, bearerToken)
      .then((res) => {
        dispatch(
          openMainModal({
            title: "¡Notificación enviada!",
            body: "Tu mensaje ha sido enviado con éxito",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        closeSendContactNotificationModal();
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "Ups",
            body: "No hemos podido procesar tu solicitud de contacto, intentelo nuevamente en unos minutos o escribanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        )
      }
      );
  };
};

//Elimina la notificación
export const deleteNotification = (id) => {
  let authToken = localStorage.getItem("access-Token-Edify");

  return (dispatch) => {
    axios
      .delete("/notificaciones/delete", {
        headers: { Authorization: `Bearer ${authToken}` },
        data: {
          idNotification: id,
        },
      })
      .then((res) => {
        dispatch(viewAccessRequest());
        dispatch(
          openMainModal({
            title: "¡Notificación eliminada!",
            body: "La notificación ha sido eliminada con éxito",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      }
      )
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "Ups",
            body: "No hemos podido procesar tu solicitud de contacto, intentelo nuevamente en unos minutos o escribanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        )
      }
      );
  };
};

export const viewBudgetRequest = () => (dispatch) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  axios
    .get("/sub_proyecto/request", bearerToken)
    .then((res) => {
      let toSet = res.data.map(async (solicitud) => {
        let userInfo = await getViewAccessProfile(solicitud.userId);
        return {
          ...solicitud,
          userInfo: { ...userInfo },
        };
      });
      Promise.all(toSet).then((response) => {
        dispatch({
          type: actionTypes.GET_BUDGETS_LIST,
          payload: response,
        });
      });
    })
    .catch((err) => {
      console.log(err)
    });
};

//Get de postulaciones de un proyecto

export const getPostulationProjectStatus = (proyectoId) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(`/postulacion/proyecto?proyectoId=${proyectoId}`, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_POSTULATION_PROJECT_STATUS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

export const setProjectFormStyle = (style) => {
  return {
    type: actionTypes.SET_PROJECT_FORM_STYLE,
    payload: style,
  };
};
export const setPostulationProject = (project) => {
  return {
    type: actionTypes.SET_POSTULATION_PROJECT,
    payload: { ...project },
  };
};
export const setPostulationProjectId = (projectId) => {
  return {
    type: actionTypes.SET_POSTULATION_PROJECT_ID,
    payload: projectId,
  };
};
export const clearPostulationProject = () => {
  return {
    type: actionTypes.CLEAR_POSTULATION_PROJECT,
  };
};

export const setBudgetId = (project) => {
  return {
    type: actionTypes.SET_BUDGET_ID,
    payload: project,
  };
};

//BUDGET
export const sendBudget = (archivo, subProjectId, mainProjectId) => {
  const formData = new FormData();
  formData.append("subProjectId", subProjectId);
  formData.append("archivo", archivo);
  formData.append("descripcion", mainProjectId);

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .post("/sub_proyecto/budget", formData, bearerToken)
      .then((res) => {
        dispatch(openEdifySpinner(false));
        dispatch(getPostulations());
        dispatch(
          openMainModal({
            title: "¡Éxito!",
            iconType: "success",
            body: "¡Tu presupuesto ha sido enviado con éxito!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      })
      .catch((error) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "Ups!",
            body: "No hemos podido procesar tu presupuesto, por favor intenta nuevamente o escribanos a contacto@edify.la ¡Muchas gracias!",
            iconType: "error",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

//Update de sub-proyectos desde el proyecto

export const updateSubProject = (subProject, projectId) => {
  const formData = new FormData();
  formData.append("id", subProject.id);
  formData.append("name", subProject.name);
  formData.append("description", subProject.description);
  formData.append("link", subProject.link);
  formData.append("startDate", subProject.startDate);
  formData.append("endDate", subProject.endDate);
  formData.append("budget", subProject.budget);
  formData.append("metricComputation", subProject.metricComputation);
  formData.append("technicalSpecifications", subProject.technicalSpecifications);
  formData.append("planos", subProject.planoslanos);

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .patch("/sub_proyecto/update", formData, bearerToken)
      .then((res) => {
        dispatch(setProjectToEdit(projectId));
        dispatch(getPrivateProjectById(projectId));
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Éxito!",
            body: "¡Los datos de tu rubro han sido actualizados con correctamente!",
            iconType: "success",
            isSuccess: true,
            customCloseMainModal: () => {
              dispatch(closeMainModal());
            },
          })
        );
      })
      .catch((error) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "Error!",
            body: "No hemos podido procesar la actualización de datos, por favor inténtelo nuevamente o escribanos a contacto@edify.la para asistirlo, disculpe las molestias ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

export const getSubProjectBudgets = (subProjectId) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(`/sub_proyecto/budget/select?subProyectoId=${subProjectId}`, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_SUBPROJECT_BUDGETS,
          payload: { subProjectId: subProjectId, budgets: res.data },
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };

}

//Eliminar Cotizacion
export const deleteQuotation = (quotationId, navigate) => {
  const quotationIdParse = parseInt(quotationId);
  let authToken = localStorage.getItem("access-Token-Edify");
  return (dispatch) => {
    axios
      .delete("/quotation/delete", {
        headers: { Authorization: `Bearer ${authToken}` },
        data: {
          idQuotation: quotationIdParse,
        },
      })
      .then((res) => {
        dispatch(getQuotationById(quotationId))
        dispatch(
          openMainModal({
            title: "¡Éxito!",
            body: "¡Tu cotización ha sido eliminada con éxito!",
            iconType: "success",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        setTimeout(() => {
          navigate(`/cotizaciondemateriales`);
        }, 2000);
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "¡Error!",
            body: "No hemos podido procesar la eliminación de tu cotización, por favor inténtelo nuevamente o escribanos a contacto@edify.la",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};


export const getMyQuotations = (userId) => (dispatch) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  const url = userId ? `/quotation/user?userFind=${userId}` : "/quotation/user";
  axios
    .get(url, bearerToken)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_MY_QUOTATIONS_USERPANEL,
        payload: res.data,
      });
    })
    .catch((err) => { });
};

export const getSubQuotationsPostulation = (subId) => (dispatch) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  const url = `/sub_quotation/postulation/select?subQuotationId=${subId}`;
  axios
    .get(url, bearerToken)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_SUB_QUOTATIONS_POSTULATIONS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err)
    });
};

//Actualizar datos de la cotización de materiales
export const updateQuotation = (quotation, id, isEditing) => {

  const updatedQuotationParams = {
    name: encodeURIComponent(quotation.name),
    startDate: encodeURIComponent(quotation.startDate),
    endDate: encodeURIComponent(quotation.endDate),
    description: encodeURIComponent(quotation.description),
    id: encodeURIComponent(parseInt(id)),
    zone: encodeURIComponent(quotation.zone),
    jurisdiccion: encodeURIComponent(parseInt(quotation.jurisdiction)),
    monto: encodeURIComponent(parseInt(quotation.monto)) || "",
    currency: encodeURIComponent(quotation.moneda) || "",
  };

  // Construcción de la URL con parámetros
  const sendUrl = `/quotation/update?name=${updatedQuotationParams.name}&startDate=${updatedQuotationParams.startDate}&endDate=${updatedQuotationParams.endDate}&description=${updatedQuotationParams.description}&id=${updatedQuotationParams.id}&zone=${updatedQuotationParams.zone}&jurisdiccion=${updatedQuotationParams.jurisdiccion}&monto=${updatedQuotationParams.monto}&currency=${updatedQuotationParams.currency}`;

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    if (quotation.startDate > quotation.endDate) {
      dispatch(
        openMainModal({
          title: "¡Ups!",
          body: "La fecha de inicio no puede ser mayor a la fecha de finalización",
          isSuccess: true,
          confirmHandler: null,
        })
      );
    } else {
      axios
        .patch(sendUrl, {}, bearerToken)
        .then((res) => {
          dispatch(getQuotationById(id, isEditing))
          dispatch(
            openMainModal({
              title: " ¡Tu cotización se actualizó correctamente! ",
              body: "Te avisaremos cuando recibas una postulación",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        })
        .catch((err) => {
          dispatch(
            openMainModal({
              title: "¡Ups!",
              body: "No hemos podido procesar la actualización de datos, por favor inténtelo nuevamente o escribanos a contacto@edify.la ¡Muchas gracias!",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        }
        );
    }
  };
}

export const resetIdProject = () => {
  return {
    type: actionTypes.RESET_ID_PROJECT,
  };
};

export const setOpenMainId = (id) => {
  return {
    type: actionTypes.SET_OPEN_MAIN_ID,
    payload: id,
  };
};

//FUNCIONES DE SUSCRIPCIÓN

export const setSuscriptionLoading = () => {
  return {
    type: actionTypes.SUSCRIPTION_LOADING,
  };
};

export const setCancelSuscriptionLoading = () => {
  return {
    type: actionTypes.CANCEL_SUSCRIPTION_LOADING,
  };
};

export const setActivateFreeTrial = (data) => {
  return {
    type: actionTypes.ACTIVATE_FREE_TRIAL,
    payload: data,
  };
};

export const getSuscriptionLink = (
  plan_summary_id,
  user_id,
  card_data,
  afterSuccess,
  afterFailure
) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  const reason = "Suscripcion Edify servicio Web";
  const back_url = "https://www.app.edify.la/dashboard";
  const body = {
    plan_summary_id: plan_summary_id,
    user_id: user_id,
    card_data: card_data,
    reason: reason,
    back_url: back_url,
    level: "bronze",
  };
  const axiosData = {
    method: "post",
    url: "/subscription/create",
    data: body,
    headers: {
      Authorization: `Bearer ${authToken}`,
      "content-type": "application/json",
    },
  };
  return (dispatch) => {
    dispatch(setSuscriptionLoading());
    axios(axiosData)
      .then((response) => {
        dispatch(setSuscriptionLoading());
        afterSuccess();
      })
      .catch((error) => {
        dispatch(setSuscriptionLoading());
        afterFailure(error.response.status);
      });
  };
};

export const activateFreeTrial = () => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    dispatch(setSuscriptionLoading());

    axios
      .get(`/subscription/free-trial/activate`, bearerToken)
      .then((res) => {
        dispatch(setSuscriptionLoading());
      })
      .catch((err) => {
        dispatch(setSuscriptionLoading());
      });
  };
};

export const cancelCurrentSuscription = (userId) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    dispatch(setCancelSuscriptionLoading());
    axios
      .request({
        url: `/subscription/cancel/${userId}`,
        method: "put",
        ...bearerToken,
      })
      .then((res) => {
        dispatch(setCancelSuscriptionLoading());
        const isSuscriptionCancelled = res.data.status === "cancelled";
        if (isSuscriptionCancelled) {
          dispatch(loadUserData());
          dispatch(closeMainModal());
          dispatch(
            openMainModal({
              title: "Tu suscripcion ha sido cancelada correctamente",
              body: "El plan no se renovará automaticamente",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        } else {
          dispatch(closeMainModal());
          dispatch(
            openMainModal({
              title: "Ha ocurrido un error al cancelar tu suscripcion",
              body: "Intente nuevamente en unos minutos o escribanos a contacto@edify.la ¡Muchas Gracias!",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setCancelSuscriptionLoading());
        dispatch(
          openMainModal({
            title: "Ha ocurrido un error al cancelar tu suscripcion",
            body: "Intente nuevamente en unos minutos o escribanos a contacto@edify.la ¡Muchas Gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

export const getMaterialsBySubquotationId = (subquotationId) => async (dispatch) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  try {
    const response = await axios.get(`/sub_quotation/materials/${subquotationId}`, bearerToken);
    dispatch({
      type: actionTypes.GET_MATERIALS_SUCCESS,
      payload: {
        subquotationId,
        materials: response.data,
      },
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_MATERIALS_FAILURE,
      payload: error.message,
    });
  }
};
export const subQuotationPostulation = (subQuotationId, pdfBlob, note, endDateSub, navigate) => {
  const formData = new FormData();
  formData.append("descripcion", note || null);
  formData.append("endDate", endDateSub);
  formData.append("archivo", pdfBlob);
  formData.append("subQuotationId", subQuotationId);

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .post("/sub_quotation/postulation", formData, bearerToken)
      .then((res) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: " ¡Tu presupuesto se envió correctamente! ",
            body: "El usuario evaluara tu propuesta y se pondra en contacto contigo",
            isSuccess: true,
            confirmHandler: null,
            iconType: "success",
            noLabel: "Cerrar",
          })
        );
        setTimeout(() => {
          navigate(`/cotizaciondemateriales`);
        }, 2000);
      })
      .catch((err) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "No hemos podido procesar tu postulacion, por favor inténtelo nuevamente o escribanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
            iconType: "error",
          })
        );
      });
  };
};

export const editSubquotationMaterials = (subquotationId, materials, navigate) => {

  return async (dispatch) => {

    try {
      const authToken = localStorage.getItem("access-Token-Edify");
      const bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

      const payload = {
        materials: materials.map((material) => ({
          category_id: material.category_id || 0,
          material_id: material.material_id || 0,
          temporary: material.temporary || false,
          amount: material.amount,
          name: material.name || "Sin nombre",
          unit: material.unit || "N/A",
        })),
      };

      const response = await axios.patch(
        `/sub_quotation/materials/update/${subquotationId}`,
        payload,
        bearerToken
      );
      dispatch(
        openMainModal({
          title: "¡Tu cotización se ha actualizado correctamente!",
          body: "Graciar por confiar en Edify",
          isSuccess: true,
          noLabel: "Cerrar",
          iconType: "success",
          confirmHandler: null,
          customCloseMainModal: () => {
            navigate(`/cotizaciondemateriales/${response.data.id}`);
            dispatch(closeMainModal());
          },
        })
      );
    } catch (error) {
      console.error("Error al editar los materiales:", error);
      dispatch(
        openMainModal({
          title: "¡Ups!",
          body: "No hemos actualizado tu cotización. Por favor, inténtalo nuevamente o escríbenos a contacto@edify.la. ¡Muchas gracias!",
          isSuccess: false,
          confirmHandler: null,
        })
      );
    }
  };
};

export const PauseQuotation = (id, isActive) => {

  const sendUrl = `/quotation/update?id=${id}&active=${isActive}`;

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .patch(sendUrl, {}, bearerToken)
      .then((res) => {
        dispatch(openEdifySpinner(false));
        isActive === true ?
          dispatch(
            openMainModal({
              title: " ¡Tu cotización se ha activado correctamente! ",
              body: "Puedes volver a pausarla en cualquier momento",
              iconType: "success",
              isSuccess: true,
              confirmHandler: null,
            })
          ) : dispatch(
            openMainModal({
              title: " ¡Tu cotización se ha pausado correctamente! ",
              body: "Puedes volver a activarla en cualquier momento",
              isSuccess: true,
              confirmHandler: null,
              iconType: "success",
            })
          )
      }, dispatch(getQuotationById(id)))
      .catch((err) => {
        dispatch(openEdifySpinner(false));
        isActive === true ?
          dispatch(
            openMainModal({
              title: "¡Ups!",
              body: "No hemos podido activar tu cotizacion, por favor inténtelo nuevamente o escribanos a contacto@edify.la ¡Muchas gracias!",
              isSuccess: true,
              confirmHandler: null,
              iconType: "error",
            })
          ) :
          dispatch(
            openMainModal({
              title: "¡Ups!",
              body: "No hemos podido activar tu cotizacion, por favor inténtelo nuevamente o escribanos a contacto@edify.la ¡Muchas gracias!",
              isSuccess: true,
              confirmHandler: null,
              iconType: "error",
            })
          );
      }
      );
  }
};



export const clearQuotations = () => {
  return {
    type: actionTypes.CLEAR_QUOTATIONS,
  };
};
