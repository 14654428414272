import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { useFormik } from "formik";
import styles from "./NuevoProducto.module.scss";
import {
  openMainModal,
  closeMainModal,
  setPortfolio,
} from "../../../../../store/actions";
import { ReactComponent as PortfolioSVG } from "../../../../../assets/svg/portfolio-profile.svg";
import NuevoProductoModal from "./NuevoProductoModal";
import * as Yup from "yup";

const backdrop = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
const modal = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2 },
  },
};



const NuevoProducto = ({
  setPortfolio,
  openMainModal,
  cancel,
  closeMainModal,
  ...props }) => {
  const [image, setImageEdit] = useState([]);


  const PortfolioSchema = Yup.object().shape({
    name: Yup.string().required("El campo es requerido"),
    description: Yup.string().required("El campo es requerido"),
    startDate: Yup.date().required("El campo es requerido"),
    endDate: Yup.date().required("El campo es requerido"),
    zone: Yup.string().required("El campo es requerido"),
    images: Yup.array().min(1, "Debe subir al menos una imagen").required("El campo es requerido"),
    type: Yup.string().required("El campo es requerido"),
  });

  const initialValues = {
    name: "",
    startDate: "",
    endDate: "",
    description: "",
    zone: "",
    images: [],
    type: "",
    priceUnit: "",
    price: "",
    currencyType: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: PortfolioSchema,
    validateOnMount: true,
  });

  const createHandler = () => {
    openMainModal({
      title: "¿Estas seguro que deseas crear el producto - servicio dentro de tu perfil?",
      iconType: "warning",
      body: "Una vez creado será publicado en tu perfil y podrá ser visto por toda la comunidad.",
      isSuccess: false,
      confirmHandler: () => {
        cancel();
        setPortfolio(formik.values, image, "perfil");
        closeMainModal();
      }
    });
  };


  //Setea la imagen a enviar
  const handleImageFilesChange = (file) => {
    setImageEdit(file);
  };

  //Maneja el estado del array de imagenes a enviar
  const handleImageEditChange = (newImageEdit) => {
    setImageEdit(newImageEdit);
  };


  return (
    <AnimatePresence>
      <motion.div
        key="1"
        variants={backdrop}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className={styles.newPortfolioModal_overlay}
      >
        <motion.div
          key="2"
          variants={modal}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={styles.newPortfolioModal}
        >
          <motion.div
            initial={{ height: "0" }}
            animate={{ height: "auto" }}
            exit={{ height: "0" }}
            transition={{ damping: 5, height: { duration: 0.5 } }}
            className={styles.container}
          >
            <header className={styles.container_header}>
              <div className={styles.container_header_txt}>
                <PortfolioSVG width={15} height={15} />
                Crear producto o servicio</div>
              <div className={styles.container_header_cancel} onClick={cancel}>
                Cancelar
              </div>
            </header>
            <NuevoProductoModal
              values={formik.values}
              formik={formik}
              setImage={handleImageEditChange}
            />
            <div
              className={`${styles.container_submit} ${!formik.isValid ? styles.disabled : ""
                }`}
              onClick={() => createHandler()}
            >
              Publicar en mi perfil
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default connect(null, { openMainModal, setPortfolio, closeMainModal })(
  NuevoProducto
);
