import { useState, useEffect } from "react";
import { variables } from "../components/FinancialData/Constants";

export const useFinancialData = () => {
    const [financialData, setFinancialData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFinancialData = async () => {
            try {
                const storedData = localStorage.getItem("financialData");
                if (storedData) {
                    const parsedData = JSON.parse(storedData);
                    const storedTime = new Date(parsedData.timestamp);
                    const currentTime = new Date();
                    if (currentTime.getTime() - storedTime.getTime() < 24 * 60 * 60 * 1000) {
                        setFinancialData(parsedData.data);
                        setIsLoading(false);
                        return;
                    }
                }

                const today = new Date();

                // Crear un array para almacenar los resultados de todas las variables
                const dataPromises = variables.map(async (variable) => {
                    let desde, hasta, limit;

                    // Ajuste de las fechas y el límite según el id de la variable
                    if (variable.id === 5 || variable.id === 6) {
                        // Últimos 7 días
                        hasta = today.toISOString().split("T")[0];
                        desde = new Date(today);
                        desde.setDate(desde.getDate() - 7);
                        desde = desde.toISOString().split("T")[0];
                        limit = 7;
                    } else if (variable.id === 27) {
                        hasta = today.toISOString().split("T")[0];
                        desde = ""; 
                        limit = 3;
                    } else if (variable.id === 28 || variable.id === 29) {
                        hasta = today.toISOString().split("T")[0];
                        desde = new Date(today);
                        desde.setDate(desde.getDate() - 360);
                        desde = desde.toISOString().split("T")[0];
                        limit = 1;
                    } else {
                        hasta = today.toISOString().split("T")[0];
                        desde = hasta;
                        limit = 1;
                    }

                    // Realizar la petición API por cada variable
                    const response = await fetch(
                        `https://api.bcra.gob.ar/estadisticas/v3.0/Monetarias/${variable.id}?desde=${desde}&hasta=${hasta}&limit=${limit}`
                    );
                    const responseData = await response.json();

                    // Si hay resultados, mapearlos de manera adecuada, si no, devolver un array vacío
                    if (responseData?.results?.length > 0) {
                        return responseData.results.map((result) => ({
                            name: variable.name,
                            fecha: formatDate(result.fecha),
                            valor: result.valor,
                        }));
                    } else {
                        // Retornar la variable con un mensaje de sin datos si no hay resultados
                        return [{ name: variable.name, fecha: "Sin datos", valor: 0 }];
                    }
                });

                // Esperamos a que todas las promesas terminen
                const results = await Promise.all(dataPromises);

                // Aplanar y filtrar resultados
                const filteredData = results.flat();

                // Guardar en el estado
                setFinancialData(filteredData);

                // Guardar en el localStorage con el timestamp
                localStorage.setItem(
                    "financialData",
                    JSON.stringify({
                        data: filteredData,
                        timestamp: new Date(),
                    })
                );
            } catch (error) {
                setError("Error al cargar los datos financieros");
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchFinancialData();
    }, []);

    return { financialData, isLoading, error };
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("es-AR", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
    });
};


