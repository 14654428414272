import React, { useState, useEffect } from "react";
import styles from "./SubProyectoItem.module.scss";
import { connect } from "react-redux";
import { setEditSendSubProject } from "../../../../../store/actions/index";
import { updateSubProject, deleteSubProjects, deleteSubProjectFromReducer, setSendSubProject } from "../../../../../store/actions/profileActions"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { checkDisabled } from "../../../../../utils/utilsFunctions";
import { useNavigate } from "react-router-dom";
import { Pencil, X } from "lucide-react";


const SubProyectoItem = ({
  updateSubProject,
  deleteSubProjects,
  deleteSubProjectFromReducer,
  subProjectId,
  subProjectEdit,
  projectIdForSub,
  projectToEditId,
  maxDateForSubProjects,
  minDateForSubProjects,
  updatedSubProject,
  setSendSubProject,
  projectId,
  isEditing,
  ...props }) => {
  const [open, openHandler] = useState(false);
  const [hoverStyles, setHoverStyles] = useState(false);
  const [showMetricInput, setShowMetricInput] = useState(false);
  const [showTechnicalInput, setShowTechnicalInput] = useState(false);
  const [showBluePrintsInput, setShowBluePrintsInput] = useState(false);
  const [showBudgetInput, setShowBudgetInput] = useState(false);
  const [subProject, setSubProject] = useState(props.subproject);
  const [hasBluePrints, setHasBluePrints] = useState(false);
  const [selectFileOrlink, setSelectFileOrLink] = useState(false);
  const [isFileOrLink, setIsFileOrLink] = useState(false);
  const navigate = useNavigate();

  const editHandler = (values) => {

    const data = {
      id: parseInt(props.subproject.id) || parseInt(subProjectId),
      name: props.subproject.name,
      description: values.description,
      budget: values.file,
      link: values.link,
      endDate: values.endDate,
      startDate: values.startDate,
      metricComputation: values.metric_computation,
      technicalSpecifications: values.technical_specifications,
      planos: values.planos,
      navigate
    }
    updateSubProject(data, parseInt(projectId));
    setSubProject(data);
    openHandler(!open);
    setShowMetricInput(false);
    setShowTechnicalInput(false);
    setShowBudgetInput(false);
  };

  //Defino si tiene cargado algun archivo o link como planos
  useEffect(() => {
    if (
      (["", "null", "undefined"].includes(subProject?.planos)) &&
      (["", "undefined"].includes(subProject?.link))
    ) {
      setHasBluePrints(false);
    } else {
      setHasBluePrints(true);
    }
  }, [subProject]);

  //Defino si tiene cargado, o un archivo o un link para ver que dato mostrar
  useEffect(() => {
    if (subProject?.planos === "null" || subProject?.planos === "undefined") {
      setIsFileOrLink(true)
    } else {
      setIsFileOrLink(false)
    }
  }, [subProject]);


  const deleteSubProjectHandler = () => {
    if (isEditing) {
      deleteSubProjects(parseInt(props.subproject.id || subProjectId), projectToEditId);
    } else {
      deleteSubProjectFromReducer(props.subproject.subProyectoListId);
    };
  };

  //VALIDACIONES
  const subProyectosSchema = Yup.object().shape({
    descriptionEdit: Yup.string().required("El campo es requerido"),

    linkEdit: Yup.string().required("El campo es requerido"),

    cierreEdit: Yup.date().required("El campo es requerido"),
  });



  return (
    <div className={`${styles.proyectItem}`}>
      <div
        className={`${styles.proyectActions} ${props.subproject.finished ? styles.finished : ""}`}
      >
        <p className={`${styles.description} ${hoverStyles && styles.isHovered} ${open ? styles.open : ""}`}>
          {props.subproject.name} {props.subproject.status === "OPEN" ? "| ABIERTO" : ""}
        </p>
        <div className={`${styles.iconContainer}`}>
          <div className={styles.editContainer}
            onClick={() => openHandler(!open)}>
            <div className={`${styles.iconEdit} ${open ? styles.iconEdit_open : ""}`}>
              <Pencil size={20} />
            </div>
          </div>
          <div className={styles.borderIconDelete} onClick={(e) => {
            e.stopPropagation();
            deleteSubProjectHandler()
          }}>
            <div className={`${styles.iconDelete} ${open ? styles.iconDelete_open : ""}`}  >
              <X size={24}/>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.proyectInput}>
        {open ? (
          <Formik
            initialValues={{
              description: subProject.description === "undefined" ? "" : subProject.description,
              link: subProject.link === "undefined" ? "" : subProject.link,
              startDate: subProject.startDate || "",
              endDate: subProject.endDate || "",
              file: subProject.plantillaDePresupuesto  || "",
              metric_computation: subProject.metric_computation || "",
              technical_specifications: subProject.technical_specifications || "",
              planos: subProject.planos || "",

            }}
            validationSchema={subProyectosSchema}
          >

            {({ handleSubmit, values, initialValues, setFieldValue }) => (
              <Form onSubmit={handleSubmit} className={`${styles.formContainer} ${open ? styles.open : ""}`}>
                <div className={styles.fieldWrapper_documentacionHeader}>
                  <p>Información adicional para cotizar:</p>
                </div>
                <div className={styles.fieldWrapper_documentacionHeader_body}>
                  <div className={styles.fieldWrapper_linkHeader} style={{alignItems: "flex-start"}}>
                    <p className={styles.fieldWrapper_linkHeader_subTitle}>Planos:</p>
                    {hasBluePrints ? (
                      isFileOrLink ? (
                        <Field id='link' name='link' placeholder='Por ej: Google Drive, Dropbox, etc.' className={styles.fieldWrapper_documentationField} />
                      ) : (
                        <div className={styles.fieldWrapper_documentationFileField}>
                          {showBluePrintsInput ? (
                            <Field
                              id='planosFile'
                              name='planosFile'
                              type='file'
                              accept=".pdf, .xlsx, .xls"
                              className={styles.fileInput}
                              onChange={(event) => {
                                event.currentTarget.files[0] &&
                                  setFieldValue("planos", event.currentTarget.files[0]);
                              }}
                            />
                          ) : (
                            <>
                              <button className={styles.fieldWrapper_documentationFileField_button} onClick={() => setShowBluePrintsInput(true)}>
                                Actualizar archivo
                              </button>
                              <p className={styles.fieldWrapper_documentationFileField_txt}>{props.subproject.planos ? "(1)Archivo cargado" : "No se ha cargado un archivo"}</p>
                            </>
                          )}
                        </div>
                      )
                    ) : (
                      <div style={{ width: "70%", paddingTop: "1rem", paddingBottom: "1rem" }}>
                        <div className={styles.radioGroup}>
                          <label className={styles.radioGroup_label}>
                            <input
                              type="radio"
                              name="uploadOption"
                              value="link"
                              checked={selectFileOrlink === 'link'}
                              onChange={() => setSelectFileOrLink('link')}
                              className={styles.radioGroup_label_input}
                            />
                            Cargar link de acceso
                          </label>
                          <label className={styles.radioGroup_label} title="PDF o excel">
                            <input
                              type="radio"
                              name="uploadOption"
                              value="planos"
                              checked={selectFileOrlink === 'planosFile'}
                              onChange={() => setSelectFileOrLink('planosFile')}
                              className={styles.radioGroup_label_input}
                            />
                            Cargar archivo
                          </label>
                        </div>
                        {selectFileOrlink === 'link' && (
                          <div className={styles.fieldWrapperLink}>
                            <div className={styles.relative}>
                              <Field
                                className={styles.fieldWrapper_addLinkInEdit}
                                id="link"
                                name="link"
                                placeholder="Por ej: Google Drive, Dropbox, etc."
                              />
                            </div>
                          </div>
                        )}
                        {selectFileOrlink === 'planosFile' && (
                          <div className={styles.fieldWrapperLink}>
                            <div className={styles.relative}>
                              <Field
                                style={{ padding: "1rem" }}
                                id="planos"
                                name="planos"
                                type="file"
                                accept=".pdf, .xlsx, .xls"
                                onChange={(event) => {
                                  event.currentTarget.files[0] &&
                                    setFieldValue("planoslanos", event.currentTarget.files[0]);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className={styles.fieldWrapper_linkHeader}>
                    <p className={styles.fieldWrapper_linkHeader_subTitle}>Cómputo métrico:</p>
                    <div className={styles.fieldWrapper_documentationFileField}>
                      {showMetricInput ? (
                        <Field
                          type='file'
                          id='computation'
                          name='computation'
                          className={styles.fileInput}
                          accept=".pdf, .xlsx, .xls"
                          onChange={(event) => {
                            event.currentTarget.files[0] &&
                              setFieldValue("metric_computation", event.currentTarget.files[0]);
                          }}
                        />
                      ) : (
                        <>
                          <button className={styles.fieldWrapper_documentationFileField_button} onClick={() => setShowMetricInput(true)}>
                            Actualizar archivo
                          </button>
                          <p className={styles.fieldWrapper_documentationFileField_txt}>{props.subproject.metric_computation ? "(1)Archivo cargado" : "No se ha cargado un archivo"}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={styles.fieldWrapper_linkHeader}>
                    <p className={styles.fieldWrapper_linkHeader_subTitle}>Especificaciónes técnicas:</p>
                    <div className={styles.fieldWrapper_documentationFileField}>
                      {showTechnicalInput ? (
                        <Field
                          type='file'
                          id='techinicalSpecs'
                          name='techinicalSpecs'
                          className={styles.fileInput}
                          accept=".pdf, .xlsx, .xls"
                          onChange={(event) => {
                            event.currentTarget.files[0] &&
                              setFieldValue("techinical_specification", event.currentTarget.files[0]);
                          }}
                        />
                      ) : (
                        <>
                          <button className={styles.fieldWrapper_documentationFileField_button} onClick={() => setShowTechnicalInput(true)}>
                            Actualizar archivo
                          </button>
                          <p className={styles.fieldWrapper_documentationFileField_txt}>{props.subproject.technical_specifications ? "(1)Archivo cargado" : "No se ha cargado un archivo"}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={styles.fieldWrapper_linkHeader}>
                    <p className={styles.fieldWrapper_linkHeader_subTitle}>Planilla de cotización:</p>
                    <div className={styles.fieldWrapper_documentationFileField}>
                      {showBudgetInput ? (
                        <Field
                          type='file'
                          id='budgetFile'
                          name='bugetFile'
                          accept=".pdf, .xlsx, .xls"
                          className={styles.fileInput}
                          onChange={(event) => {
                            event.currentTarget.files[0] &&
                              setFieldValue("file", event.currentTarget.files[0]);
                          }}
                        />
                      ) : (
                        <>
                          <button className={styles.fieldWrapper_documentationFileField_button} onClick={() => setShowBudgetInput(true)}>
                            Actualizar archivo
                          </button>
                          <p className={styles.fieldWrapper_documentationFileField_txt}>{props.subproject.budget_payroll ? "(1)Archivo cargado" : "No se ha cargado un archivo"}</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.fieldWrapper_linkHeader} style={{alignItems: "flex-start"}}>
                  <p className={styles.fieldWrapper_linkHeader_subTitle}>Descripción particular:</p>
                  <div className={styles.fieldWrapper}>
                    <Field 
                          id='description' 
                          name='description' 
                          placeholder='Carga una breve descripción' 
                          onChange={(e) => setFieldValue('description', e.target.value)}
                          value={values.description}
                          as='textarea' 
                          className={styles.fieldWrapper_textArea} />
                  </div>
                </div>
                <div className={styles.fieldWrapper_linkHeader} style={{alignItems: "flex-start"}}>
                  <div className={styles.fieldWrapper_linkHeader_subTitle}>
                    Fechas de recepción de presupuestos:
                  </div>
                  <div className={styles.fieldWrapper_dateHeader_field}>
                    <div className={styles.fieldWrapper_dateHeader_subTitle}>
                      Desde:
                      <div className={styles.fieldWrapper_dateHeader_value}>

                        <Field
                          type="date"
                          id='startDate'
                          name='startDate'
                          value={values.startDate?.split("T")[0]}
                          min={minDateForSubProjects?.split("T")[0]}
                          className={styles.fieldWrapper_dateHeader_valueInput} />
                      </div>
                    </div>
                    <div className={styles.fieldWrapper_dateHeader_subTitle}>
                      Hasta:
                      <div className={styles.fieldWrapper_dateHeader_value}>
                        <Field
                          type="date"
                          id='endDate'
                          name='endDate'
                          value={values.endDate?.split("T")[0]}
                          max={maxDateForSubProjects?.split("T")[0]}
                          min={values.startDate}
                          className={styles.fieldWrapper_dateHeader_valueInput} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.fieldWrapper_buttonContainer}>
                  <button type='button' className={styles.buttonCambios_cancelar} onClick={() => openHandler(!open)}>
                    Cancelar
                  </button>
                  <button type='button' className={styles.buttonCambios} disabled={checkDisabled(initialValues, values)} onClick={() => { editHandler(values); }}>
                    Guardar cambios
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    subProjectEdit: state.profile.subProjectId,
    subProjectId: state.profile.subProjectId?.id,
    projectIdForSub: state.profile.subProjectId?.proyecto || state.profile.projectIdForSub,
    projectToEditId: state.profile.projectToEdit.id,
    updatedSubProject: state.profile.projectToEdit,
  };
};

export default connect(mapStateToProps, {
  deleteSubProjects,
  setEditSendSubProject,
  updateSubProject,
  deleteSubProjectFromReducer,
  setSendSubProject,
})(SubProyectoItem);
