import React from "react";
import SubProyectosWrapper from "./SubProyectosWrapper/SubProyectosWrapper";
import NuevoSubproyecto from "./NuevoSubproyecto/NuevoSubproyecto";
import styles from "./Subproyectos.module.scss";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { X } from "lucide-react";

const variants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1 },
};

const Subproyectos = ({
  subProjects,
  subProjectsToEdit,
  isEditing,
  isPopUp,
  projectIdForSub,
  maxDateForSubProjects,
  minDateForSubProjects,
  closeModal,
  setShowSubProjectsModal,
}) => {

  let subprojectsToMap = isEditing ? subProjectsToEdit : subProjects;
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={variants}
      transition={{ duration: 0.5 }}
      className={styles.subProyectos}>
      <h2 className={styles.subProyectos_header}>Rubros a cotizar
        <button onClick={() => setShowSubProjectsModal(false)} className={styles.closeButton}>
          <X size={20} />
        </button>
      </h2>
      <NuevoSubproyecto
        showProyects={true}
        isEditing={isEditing}
        projectIdForSub={projectIdForSub}
        setShowSubProjectsModal={setShowSubProjectsModal}
        closeModal={closeModal}
        existingSubProjects={subprojectsToMap}
        maxDateForSubProjects={maxDateForSubProjects}
        minDateForSubProjects={minDateForSubProjects} />
      {subprojectsToMap?.length > 0 &&
      <>
        <SubProyectosWrapper
          maxDateForSubProjects={maxDateForSubProjects}
          minDateForSubProjects={minDateForSubProjects}
          subProjectsToEdit={subProjectsToEdit}
          isEditing={isEditing}
          isPopUp={isPopUp}
          projectIdForSub={projectIdForSub} />
          <button onClick={() => setShowSubProjectsModal(false)} className={styles.saveButton}>
            Guardar
          </button>
          </>
          }
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    subProjects: state.profile.sendSubprojects,
  };
};

export default connect(mapStateToProps)(Subproyectos);
