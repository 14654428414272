import React, { useState, useEffect } from 'react';
import styles from './WorkBag.module.scss';
import { connect } from 'react-redux';
import Logo from "../../assets/images/logoBig.png";
import LogoMobile from "../../assets/images/logo-navbar.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AnimatePresence } from 'framer-motion';
import { getSkills, createWorkBagUser, openIdentityModal } from '../../store/actions';
import useGetTodayDate from '../../hooks/useGetTodayDate';
import WorkOffer from './WorkOffer';
import { useNavigate } from 'react-router-dom';
import SeeWorkOfferImage from "../../assets/images/fondoObraPrivada1.jpg";
import SeeFormImage from "../../assets/images/backgroundEdify.avif";
import WorkBagForm from './WorkBagForm';

const WorkBag = ({
    getSkills,
    skills,
    createWorkBagUser,
    openIdentityModal,
    ...props }) => {
    const [isPhone, setIsPhone] = useState(false);
    const [showForm, setShowForm] = useState(true);

    useEffect(() => {
        getSkills()
    }, [])


    const today = useGetTodayDate();

    const navigate = useNavigate();

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 600) {
            setIsPhone(true);
        } else {
            setIsPhone(false);
        }
    }

    useEffect(() => {
        handleResize();
    }, []);

    // Agregar un event listener para el cambio de tamaño de la ventana
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        // Limpiar el event listener al desmontar el componente
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const initialValues = {
        name: "",
        lastname: "",
        email: "",
        phone: "",
        skills: [],
        workZone: [],
        city: "",
        unemploymentFund: false,
        currentJob: false,
        employmentEndDate: "",
        termsAndConditions: false,
        selectedJurisdiction: null
    }

    const telRegex = /[0-9+]/;

    const formSchema = Yup.object({
        name: Yup.string().required("Nombre*"),
        lastname: Yup.string().required("Apellido*"),
        email: Yup.string().email("Email invalido").required("Email*"),
        phone: Yup.string().matches(telRegex, "*Solo se permiten números").min(6, "*Número no válido").required("Teléfono*"),
        skills: Yup.array().required("Debes seleccionar al menos un rubro de trabajo*"),
        workZone: Yup.array().required("Elige al menos una provincia*"),
        unemploymentFund: Yup.boolean().required("*Campo obligatorio"),
        currentJob: Yup.boolean().required("*Campo obligatorio"),
        employmentEndDate: Yup.string().when('currentJob', {
            is: true,
            then: Yup.string().required("*Debes seleccionar una fecha aprox."),
            otherwise: Yup.string()
        }),
        selectedJurisdiction: Yup.object().nullable(),
        termsAndConditions: Yup.boolean().oneOf([true], "*Debes aceptar los términos y condiciones")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: formSchema,
        onSubmit: (values) => {
            createWorkBagUser(values, navigate);
            formik.setErrors({});
            formik.resetForm();

        }
    })


    return (
        <div className={styles.mainContainer}>
            {isPhone ? (
                showForm ? (
                    <div className={styles.workBagContainer}>
                        <div className={styles.workBag_logoContainer}>
                            <img src={isPhone ? LogoMobile : Logo} alt="Logo de Edify" className={styles.workBag_logo} />
                        </div>
                          <WorkBagForm
                                formik={formik}
                                skills={skills}
                                today={today}
                                isPhone={isPhone}
                                setShowForm={setShowForm}
                            />
                    </div>
                ) : (
                    <>
                        <div className={styles.workBag_logoContainer}>
                            <img src={isPhone ? LogoMobile : Logo} alt="Logo de Edify" className={styles.workBag_logo} />
                        </div>
                        <WorkOffer isPhone={isPhone} showForm={showForm} setShowForm={setShowForm} />
                    </>
                )
            ) : (
                showForm ? (
                    <main className={styles.workBagContainer}>
                        <div className={styles.workBagContainer_body}>
                                <div className={styles.workBag_logoContainer}>
                                    <img src={isPhone ? LogoMobile : Logo} alt="Logo de Edify" className={styles.workBag_logo} />
                                </div>
                                <WorkBagForm
                                    Logo={Logo}
                                    LogoMobile={LogoMobile}
                                    formik={formik}
                                    skills={skills}
                                    today={today}
                                    isPhone={isPhone}
                                />
                        </div>
                        <section className={styles.workBag_rightSideComponent}>
                            <div className={styles.workBag_rightSideComponent_body}>
                                <img src={SeeWorkOfferImage} alt="Oferta de trabajo" className={styles.workBag_rightSideComponent_image} />
                                <h2 className={styles.workBag_rightSideComponent_title}>Encontrá tu próxima oportunidad profesional</h2>
                                <p className={styles.workBag_rightSideComponent_text}>
                                    En edify conectamos profesionales talentosos con las mejores empresas de construcción.
                                </p>
                                <button className={styles.workBag_rightSideComponent_btn} onClick={() => setShowForm(false)}>Ver ofertas de trabajo</button>
                            </div>
                        </section>
                    </main>
                ) : (
                    <div className={styles.workBagContainer}>
                        <section className={styles.workBag_leftSideComponent}>
                            <div className={styles.workBag_leftSideComponent_body}>
                                <img src={SeeFormImage} alt="Oferta de trabajo" className={styles.workBag_leftSideComponent_image} />
                                <h2 className={styles.workBag_leftSideComponent_title}>Inscribite en nuestra bolsa de trabajo</h2>
                                <p className={styles.workBag_leftSideComponent_text}>
                                    En edify conectamos profesionales talentosos con las mejores empresas de construcción.
                                </p>
                                <button className={styles.workBag_leftSideComponent_btn} onClick={() => setShowForm(true)}>Quiero inscribirme</button>
                            </div>
                        </section>
                        <div className={styles.workBagContainer_body}>
                        <div className={styles.workBag_logoContainer}>
                            <img src={isPhone ? LogoMobile : Logo} alt="Logo de Edify" className={styles.workBag_logo} />
                        </div>
                        <WorkOffer isPhone={isPhone} showForm={showForm} setShowForm={setShowForm} />
                        </div>
                    </div>
                )

            )}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        skills: state.register.skills,
    }
}

export default connect(mapStateToProps, { getSkills, createWorkBagUser, openIdentityModal })(WorkBag);