import { useState, useEffect } from "react";

const useUserImage = (userData) => {
  const [profileImage, setProfileImage] = useState(null);
  const [initials, setInitials] = useState("");
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    const getInitials = () => {
      if (!userData) return "";

      const { name, last_name, razon_social, type } = userData;

      if (type === "PERSON") {
        const firstNameInitial = name?.charAt(0) || "";
        const lastNameInitial = last_name?.charAt(0) || "";
        return `${firstNameInitial}${lastNameInitial}`.toUpperCase();
      }

      if (type === "COMPANY") {
        return razon_social?.charAt(0)?.toUpperCase() || "";
      }

      return "";
    };

    const validateImage = () => {
      if (userData?.image_profile) {
        const img = new Image();
        img.src = userData.image_profile;

        img.onload = () => {
          setProfileImage(userData.image_profile);
          setImageError(false); 
        };

        img.onerror = () => {
          setProfileImage(null);
          setInitials(getInitials());
          setImageError(true); 
        };
      } else {
        setInitials(getInitials());
        setProfileImage(null);
        setImageError(true); 
      }
    };

    validateImage();
  }, [userData]);

  return { profileImage, initials, imageError };
};

export default useUserImage;


