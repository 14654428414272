import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getTotalUsers,
  getTotalPublicProjects,
  getTotalPublicQuotations,
  getTotalPrivateQuotations,
  getLastNewUsers,
  getTotalNewPublicQuotations,
  getLastAdjudicatedProject,
  getTotalNewPrivateQuotations,
  getTotalAdjudications,
} from "../../store/actions";
import styles from "./QuePasaEnEdify.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AdjudicatedIcon } from "../../assets/svg/empresaAdjudicadaIcon.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowDownIcon.svg";
import { Newspaper, BadgeCheck, MapPin, Building2 } from "lucide-react";
import { motion } from "framer-motion";

const QuePasaEnEdify = ({
  getTotalUsers,
  totalUsers = 0,
  getTotalPublicProjects,
  totalPublicProjects,
  getTotalPublicQuotations,
  totalAdjudicatedProjects,
  getLastAdjudicatedProject,
  lastAdjudicatedProject,
  getTotalAdjudications,
  totalPublicQuotations,
  lastNewUsers = [],
  getLastNewUsers,
  lastNewUser = [],
  lastPublicProject = [],
  lastPublicQuotation = [],
}) => {
  const [showContent, setShowContent] = useState({});
  const navigate = useNavigate();

  //Obtenemos los datos para el dashboard

  useEffect(() => {
    getTotalUsers();
    getLastNewUsers();
    getTotalPublicProjects();
    getLastAdjudicatedProject();
    getTotalAdjudications();
    getTotalPublicQuotations();
  }, [getTotalUsers, getTotalPublicProjects, getTotalPublicQuotations]);

  //Maneja la redirección al perfil del usuario
  const handleClickProfile = () => {
    const userName = lastNewUsers[0].type === "COMPANY" ? lastNewUsers[0].razon_social : lastNewUsers[0].name + " " + lastNewUsers[0].last_name;
    navigate(`/profesionales/${lastNewUsers[0].id}/${userName}`);
  }

  //Maneja la redirección a la ultima obra pública
  const handleClickOpenProject = (quotation, publicProject) => {
    if (quotation) {
      window.open(`/materiales-a-licitar-publicos/${lastPublicQuotation.id}`, "_blank");
    } else if (publicProject) {
      window.open(`/obra-publica-a-licitar/${lastPublicProject?.id}`, "_blank");
    } else {
      window.open(`/obraspublicas/proyectos-adjudicados/${lastAdjudicatedProject?.id}`, "_blank");
    }
  }

  //Maneja el estado de la flecha de abajo
  const handleDropdown = (menu) => {
    setShowContent((prevShowContent) => ({
      ...prevShowContent,
      [menu]: !prevShowContent[menu],
    }));
  }


  return (
    <main className={styles.QuePasaEnEdify}>
      <header className={styles.QuePasaEnEdify_container_header}>
        Novedades
        <Newspaper size={24} style={{color: "#2196F3"}} />
      </header>
      <div className={styles.QuePasaEnEdify_container}>
        <div className={styles.QuePasaEnEdify_container_content}>
          <div className={styles.cantidadContainerFirst}>
            <div className={styles.QuePasaEnEdify_container_content_item}>
              {totalUsers !== undefined && totalUsers > 0 && (
                <p className={styles.userCantidad} style={{color: "#234ba2"}}>{totalUsers}</p>
              )}
              <span className={styles.userCantidad_text}>Profesionales</span>
            </div>
            <div className={styles.QuePasaEnEdify_container_arrow} onClick={() => handleDropdown("users")}>
              <ArrowDownIcon height={12} width={12} title="Ver más" />
            </div>

          </div>
          {showContent.users && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className={styles.notificationContainer}>
              <div
                className={styles.notificationContainer_profile}
                onClick={() => handleClickProfile()}
              >
                <div className={styles.notificationContainer_profile_image}>
                  <img
                    className={styles.notificationContainer_profile_img}
                    src={lastNewUsers[0]?.image_profile}
                    alt="user"
                  />
                  {lastNewUsers[0]?.validated && (
                    <BadgeCheck size={20} style={{ position: "absolute", right: "0", bottom: "0", color: "rgb(59 130 246)" }} />
                  )}
                </div>
                <div className={styles.notificationContainer_info}>
                  <span className={styles.notificationContainer_userName}>{lastNewUsers[0]?.type === "COMPANY" ? lastNewUsers[0].razon_social : lastNewUsers[0]?.name + " " + lastNewUsers[0]?.last_name}</span>
                  <span className={styles.notificationContainer_sub}>Nuevo profesional</span>
                </div>
              </div>
            </motion.div>
          )}
          {totalPublicProjects !== undefined && totalPublicProjects > 0 && (
            <>
              <div className={styles.cantidadContainer}>
                <div className={styles.QuePasaEnEdify_container_content_item}>
                  <p className={styles.obrasCantidad} style={{color: "#f5b948"}}>{totalPublicProjects}</p>
                  <span className={styles.userCantidad_text}> Obras Públicas</span>
                </div>
                <div className={styles.QuePasaEnEdify_container_arrow} onClick={() => handleDropdown("publicProjects")}>
                  <ArrowDownIcon height={12} width={12} title="Ver más" />
                </div>
              </div>
              {showContent.publicProjects && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className={styles.notificationContainer}>
                  <div className={styles.notificationContainer_publicProject} onClick={() => handleClickOpenProject(false, true)}>
                    <div className={styles.notificationContainer_publicProject_info}>
                      <span className={styles.notificationContainer_publicProject_name}>
                       <span>{lastPublicProject?.name}</span> 
                      </span>
                      <span className={styles.notificationContainer_publicProject_zone}>
                        <MapPin style={{minHeight: "1.6rem", minWidth: "1.6rem", maxHeight: "1.6rem", maxWidth: "1.6rem"}} />
                        <span>{lastPublicProject?.zone}</span> 
                      </span>
                    </div>
                  </div>
                </motion.div>
              )}
            </>
          )}
          {totalAdjudicatedProjects !== undefined && totalAdjudicatedProjects > 0 && (
            <>
              <div className={styles.cantidadContainer}>
                <div className={styles.QuePasaEnEdify_container_content_item}>
                  <p className={styles.obrasCantidad} style={{color: "#ea580c"}}>{totalAdjudicatedProjects}</p>
                  <span className={styles.userCantidad_text}>Adjudicaciones</span>
                </div>
                <div className={styles.QuePasaEnEdify_container_arrow} onClick={() => handleDropdown("adjudications")}>
                  <ArrowDownIcon height={12} width={12} title="Ver más" />
                </div>
              </div>
              {showContent.adjudications && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className={styles.notificationContainer}>
                  <div className={styles.notificationContainer_publicProject} onClick={() => handleClickOpenProject()}>
                    <div className={styles.notificationContainer_publicProject_info}>
                      <span className={styles.notificationContainer_publicProject_name}>
                        <span>{lastAdjudicatedProject?.name}</span>
                      </span>
                      <span className={styles.notificationContainer_publicProject_factory}>
                        <Building2 style={{minHeight: "1.6rem", minWidth: "1.6rem", maxHeight: "1.6rem", maxWidth: "1.6rem"}} />
                        <span>{lastAdjudicatedProject?.empresa}</span>
                      </span>
                      <span className={styles.notificationContainer_publicProject_zone}>
                      <MapPin style={{minHeight: "1.6rem", minWidth: "1.6rem", maxHeight: "1.6rem", maxWidth: "1.6rem"}} />
                        <span>{lastAdjudicatedProject?.province}</span>
                      </span>
                    </div>
                  </div>
                </motion.div>
              )}
            </>
          )}
          <div className={styles.cantidadContainer}>
            <div className={styles.QuePasaEnEdify_container_content_item}>
              <p className={styles.cotizacionCantidad} style={{color: "#45aff7"}}>{totalPublicQuotations}</p>
              <span className={styles.userCantidad_text}>Cotización de Materiales</span>
            </div>
            <div className={styles.QuePasaEnEdify_container_arrow} onClick={() => handleDropdown("publicQuotations")}>
              <ArrowDownIcon height={12} width={12} title="Ver más" />
            </div>
          </div>
          {showContent.publicQuotations && lastPublicQuotation.length !== 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className={styles.notificationContainer}>
              <div className={styles.notificationContainer_publicQuotation} onClick={() => handleClickOpenProject(true)}>
                <div className={styles.notificationContainer_publicQuotation_info}>
                  <span className={styles.notificationContainer_publicQuotation_name}>
                   <span>{lastPublicQuotation?.name}</span> 
                  </span>
                  <span className={styles.notificationContainer_publicQuotation_zone}>
                  <MapPin style={{minHeight: "1.6rem", minWidth: "1.6rem", maxHeight: "1.6rem", maxWidth: "1.6rem"}} />
                    <span>{lastPublicQuotation?.zone}</span> 
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    totalUsers: state.dashboard.totalUsers,
    totalPublicProjects: state.dashboard.totalPublicProjects,
    totalPublicQuotations: state.dashboard.totalPublicQuotations,
    totalPrivateQuotations: state.dashboard.totalPrivateQuotations,
    totalAdjudicatedProjects: state.dashboard.totalAdjudicatedProjects,
    lastNewUsers: state.dashboard.lastNewUsers,
    totalNewPrivateProjects: state.dashboard.totalNewPrivateProjects,
    totalNewPublicQuotations: state.dashboard.totalNewPublicQuotations,
    totalNewPrivateQuotations: state.dashboard.totalNewPrivateQuotations,
    lastPublicProject: state.dashboard.lastPublicProject,
    lastPublicQuotation: state.dashboard.lastPublicQuotation,
    lastAdjudicatedProject: state.dashboard.lastAdjudicatedProject,
  };
}

export default connect(mapStateToProps, {
  getTotalUsers,
  getTotalPublicProjects,
  getTotalPublicQuotations,
  getTotalPrivateQuotations,
  getLastNewUsers,
  getTotalNewPublicQuotations,
  getTotalNewPrivateQuotations,
  getTotalAdjudications,
  getLastAdjudicatedProject,
})(QuePasaEnEdify);
