import React, { useEffect, useState } from "react";
import styles from "./PrivateProyects.module.scss";
import styled from "styled-components";
import Botonera from "../../components/Botonera/Botonera";
import { connect } from "react-redux";
import {
  getDashboardProjects,
  resetFilterBy,
  getProjectsByType,
  openFeedTextModal,
  clearProjects,
  closeMainModal,
  setProjectFormStyle,
  resetProjectToEdit,
} from "../../store/actions";
import EmptyState from "../../components/UI/EmptyState/EmptyState";
import useSuscriptionModalTimeout from "../../hooks/useSuscriptionModalTimeout";
import Pagination from "../../hooks/pagination";
import AllProjectsItemEmptyCard from "../../components/Dashboard/AllProjectsItem/AllProjectsItemEmptyCard";
import FilterPrivateProjects from "../../components/Filters/FilterPrivateProjects/FilterPrivateProjects";
import { motion } from "framer-motion";
import FondoListadoObra from "../../assets/images/PrivateProject.avif";
import FondoObraNueva from "../../assets/images/fondoObraNueva.jpg";
import { ReactComponent as NoResultsIcon } from "../../assets/svg/NoResultsMagnifier.svg";
import { Info, FolderTree, Filter, CalendarDays, Star } from "lucide-react";
import NewNav from "../../components/Nav/NewNav";
import { useNavigate } from "react-router-dom";
import RegisteredUserProfileLoader from "../../components/RegisteredUsers/RegisteredUsersSkeleton/RegisteredUserProfileLoader";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 8%;
  padding-left: 8%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

function PrivateProjects({

  allProjects,
  getProjectsByType,
  clearProjects,
  closeMainModal,
  loading,
}) {

  const [isOpen, setIsOpen] = useState(false);
  const pageNumberLimit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [isOpenProject, setModalOpenProject] = useState(false);
  const [showProjectList, setShowProjectList] = useState(false);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  useSuscriptionModalTimeout();

  const navigate = useNavigate();

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };

  useEffect(() => {
    handleResize();
    clearProjects();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getProjectsByType("PRIVATE", currentPage, pageNumberLimit);
  }, [currentPage, clearProjects]);


  function setModalOpen(isOpen) {
    closeMainModal();
    setIsOpen(isOpen);
  }

  const handleNewOpenProject = () => {
    resetProjectToEdit();
    setProjectFormStyle(false);
    navigate("/obrasprivadas/crear");
    closeMainModal();
  };


  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    response: allProjects,
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const onPrevClick = () => {
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    setCurrentPage((prev) => prev - 1);
  };

  const onNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    setCurrentPage((prev) => prev + 1);
  };

  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      <div className={styles.container}>
        {!showProjectList ? (
          <section className={styles.containerList}>
            <motion.div
              className={styles.containerList_section}
              initial={{ x: -500 }} animate={{ x: 0 }}
              transition={{ duration: 0.5 }}>
              <div className={styles.containerList_section_description}>
                <div className={styles.containerList_section_title}>Encontrá tu próximo proyecto: cotizá las obras en búsqueda de tu talento</div>
                <ul className={styles.containerList_section_description_txt}>
                  <li className={styles.containerList_section_description_txt_item}>
                    <Filter className={styles.containerList_section_description_icon} />
                    Usá el filtrador para encontrar las obras por ubicación, tipo de trabajo solicitado, etc.</li>
                  <li className={styles.containerList_section_description_txt_item}>
                    <FolderTree className={styles.containerList_section_description_icon} />
                    Postulate para acceder a la documentación del proyecto y realizar una cotizacion.</li>
                  <li className={styles.containerList_section_description_txt_item}>
                    <Info className={styles.containerList_section_description_icon} />
                    Podés ver todos los detalles de cada obra haciendo click en "ver más".</li>
                </ul>
              </div>
              <div className={styles.containerList_section_backdropList}>
                <button className={styles.containerList_section_button} onClick={() => setShowProjectList(true)}>
                  Ver los proyectos existentes
                </button>
                <div className={styles.containerList_backgroundWrapper}>
                  <img src={FondoListadoObra} alt="FondoListadoObra" className={styles.containerList_background} />
                </div>
              </div>

            </motion.div>
            <motion.div
              className={styles.containerList_section}
              initial={{ x: 500 }} animate={{ x: 0 }}
              transition={{ duration: 0.5 }}>
              <div className={`${styles.containerList_section_backdropList} ${styles.calculator}`}>
                <button className={styles.containerList_section_button} onClick={() => handleNewOpenProject()}>
                  Crear una publicación nueva
                </button>
                <div className={`${styles.containerList_backgroundWrapper} ${styles.calculatorWrapper}`}>
                  <img src={FondoObraNueva} alt="FondoListadoObra" className={`${styles.containerList_background} ${styles.calculatorBackground}`} />
                </div>
              </div>
              <div className={styles.containerList_section_description}>
                <div className={styles.containerList_section_title}>¿Tenés un proyecto y necesitas una cotización?</div>
                <ul className={styles.containerList_section_description_txt}>
                  <li className={styles.containerList_section_description_txt_item}>
                    <CalendarDays className={styles.containerList_section_description_icon} />
                    Podés programar la recepción de cotizaciones de acuerdo a tu agenda.</li>
                  <li className={styles.containerList_section_description_txt_item}>
                    <Info className={styles.containerList_section_description_icon}/>
                    Para cotizar, los usuarios te enviarán una solicitud para acceder a la documentación, en base a su perfil vos decidís quién puede descargarla.</li>
                  <li className={styles.containerList_section_description_txt_item}>
                    <Star className={styles.containerList_section_description_icon} />
                    Calificá al usuario de acuerdo a tu experiencia con él y la valoración de su trabajo.</li>
                </ul>
              </div>
            </motion.div>
          </section>
        ) : (
          <>
            <FilterPrivateProjects />
            {Array.isArray(allProjects) && allProjects.length === 0 && !loading ? (
              <div className={styles.emptyState}>
                <EmptyState title="No hay resultados para su búsqueda." icon={<NoResultsIcon width={80} height={80} />} />
              </div>
            ) : loading ? (
              <RegisteredUserProfileLoader />
            ) : <div className={styles.AllProjects} id="allProjects">
              {allProjects?.itemCount || allProjects?.length > 0 ? (
                <Pagination
                  {...paginationAttributes}
                  onPrevClick={onPrevClick}
                  onNextClick={onNextClick}
                  onPageChange={onPageChange}
                  setModalOpen={setModalOpen}
                  isOpen={isOpen}
                />
              ) : (
                <AllProjectsItemEmptyCard
                  setModalOpenProject={setModalOpenProject}
                  isOpen={isOpenProject} />
              )}
            </div>}
          </>
        )}

      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.dashboard.loading,
    allProjects: state.dashboard.viewProjects,
    searchParams: state.dashboard.searchParams,
    isFilter: state.dashboard.isFilter,
    page: state.dashboard.page,
    itemCount: state.dashboard.itemCount,
    take: state.dashboard.take,
    proyectos: state.profile.proyectos,
    postulaciones: state.profile.postulations,
  };
};

export default connect(mapStateToProps, {
  getDashboardProjects,
  getProjectsByType,
  resetFilterBy,
  clearProjects,
  closeMainModal,
  setProjectFormStyle,
  resetProjectToEdit,
  openFeedTextModal,
})(PrivateProjects);
