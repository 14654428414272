import React from "react";
import styles from "./MaterialsListPreview.module.scss";
import { X, Send } from "lucide-react";
import { motion } from "framer-motion";
import DownloadPdfButton from "./DownloadPdfButton";

const variants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
};


const MaterialsListPreview = ({ materials, sendHandler, closePreview, isEstimacionDeCostos, setShowSendQuotePopup }) => {

    const unitToShow = (unit) => {
        switch (unit) {
            case "m2":
                return "Metro cuadrado";
            case "m3":
                return "Metro cúbico";
            case "kg":
                return "Kilogramo";
            case "ml":
                return "Mililitro";
            case "cm":
                return "Centímetro";
            case "u":
                return "Unidad";
            case "l":
                return "Litro";
            case "cm2":
                return "Centímetro cuadrado";
            case "cm3":
                return "Centímetro cúbico";
            case "gr":
                return "Gramo";
            case "m":
                return "Metro";
            default:
                return unit;
        }
    };

    return (
        <div className={styles.overlay}>
            <motion.div
                className={styles.container}
                initial="hidden"
                animate="visible"
                variants={variants}
            >
                <div className={styles.container_header}>
                    <h2 className={styles.container_header_title}>Vista previa de materiales</h2>
                    <X size={20} onClick={closePreview} className={styles.container_header_closeIcon} />
                </div>
                <div className={styles.container_tableContainer}>
                    <table className={styles.container_table}>
                        <thead className={styles.container_table_head}>
                            <tr>
                                <th className={styles.container_table_head_item}>Material</th>
                                <th className={styles.container_table_head_item}>Categoría</th>
                                <th className={styles.container_table_head_item}>Cantidad</th>
                                <th className={styles.container_table_head_item}>Unidad</th>
                                <th className={styles.container_table_head_item}>Precio Unitario</th>
                                <th className={styles.container_table_head_item}>Precio Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {materials.map((material, index) => (
                                <tr className={styles.container_table_borderBottom} key={index}>
                                    <td className={styles.container_table_item}>{material.name}</td>
                                    <td className={styles.container_table_item}>{material.category}</td>
                                    <td className={styles.container_table_item}>{material.quantity}</td>
                                    <td className={styles.container_table_item}>{unitToShow(material.unit)}</td>
                                    <td className={styles.container_table_item}>${material.price}</td>
                                    <td className={styles.container_table_item}>${material.price * material.quantity}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr className={styles.container_footer}>
                                <td colSpan="5" className={styles.container_footer_total}>Total:</td>
                                <td className={styles.container_footer_number}>${materials.reduce((acc, curr) => acc + curr.price * curr.quantity, 0)}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className={styles.container_buttonsContainer}>
                    <button className={styles.container_buttonsContainer_buttonCancel} onClick={closePreview}>Cancelar</button>
                    {isEstimacionDeCostos ?
                        <DownloadPdfButton
                            closePreview={closePreview}
                            previewPopUp={true}
                            materials={materials}
                            setShowSendQuotePopup={setShowSendQuotePopup} /> : (
                            <button className={styles.container_buttonsContainer_buttonSend}
                            disabled={materials.length === 0}
                            onClick={sendHandler}>
                            <Send size={16} />
                                Enviar para cotizar
                            </button>
                        )}
                </div>
            </motion.div>
        </div>
    );
}



export default MaterialsListPreview;