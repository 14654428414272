import React from "react";
import LicitacionesItem from "../components/Dashboard/LicitacionesPublicas/LicitacionesItem";
import AdjudicatedItem from "../components/Dashboard/PublicProjectsAdjudicated/AdjudicadosItem";
import QuotationsList from "../components/MarketPlace/QuotationsList";
import AllProjectsItem from "../components/Dashboard/AllProjectsItem/AllProjectsItem";
import styles from "../hooks/pagination.module.scss";
import usePremiumCheck from "./usePremiumCheck";
import { connect } from "react-redux";
import { ReactComponent as ArrowRight } from "../assets/svg/arrowRightIcon.svg";
import { ReactComponent as LeftArrow } from "../assets/svg/arrowLeftIcon.svg";


const Pagination = ({ itemCount, take, isPublicProject, ...props }) => {

  const premiumCheck = usePremiumCheck();
  const { currentPage, maxPageLimit, minPageLimit, response } = props;
  const data = props.response.entities || props.response;
  const totalPage = isPublicProject ? Math.ceil(itemCount / take) : Math.ceil(response?.itemCount / response?.take);
  let pageIncrementEllipses = null;
  let pageDecremenEllipses = null;

  const pages = [];
  if (totalPage > 1) {
    for (let i = 1; i <= totalPage; i++) {
      pages.push(i);
    }
  }
  const handlePrevClick = () => {
    if (currentPage > 1) {
      props.onPrevClick();
    }
  };

  const handleNextClick = premiumCheck(() => {
    if (currentPage < totalPage) {
      props.onNextClick();
    }
  });

  const handlePageClick = (page) => {
    props.onPageChange(page);
  };

  const pageNumbers = pages.map((page, index) => {
    if (page <= maxPageLimit && page > minPageLimit) {
      return (
        <div
          key={page}
          id={page}
          onClick={() => handlePageClick(page)}
          className={currentPage === page ? styles.active : null}
          style={{ cursor: "pointer" }}
        >
          {page + (index !== maxPageLimit - 1 && " - ")}
        </div>
      );
    } else {
      return null;
    }
  });

  if (pages.length > maxPageLimit) {
    pageIncrementEllipses = <div onClick={handleNextClick}>{"..."}</div>;
  }
  if (minPageLimit >= 1) {
    pageDecremenEllipses = <div onClick={handlePrevClick}>{"..."}</div>;
  }

  const renderProjects = (projects, type, isQuotation) => {

    if (!projects || projects.length === 0) return null;
  
    switch (true) {
      case isQuotation:
        return (
          <div className={styles.quotationsContainer}>
            {projects.map((project) => (
              <QuotationsList item={project} key={project.id} quotation />
            ))}
          </div>
        );
  
      case type === "PRIVATE":
        return (
          <div className={styles.privateProjectsContainer}>
            {projects.map((project) => (
              <AllProjectsItem item={project} key={project.id} />
            ))}
          </div>
        );
  
      case type === "PUBLIC":
        return (
          <div className={styles.publicProjectsContainer}>
            {projects.map((project) => (
              <LicitacionesItem key={project.id} item={project} />
            ))}
          </div>
        );
  
      case type === "adjudicated":
        return (
          <div className={styles.publicProjectsContainer}>
            {projects.map((project) => (
              <AdjudicatedItem key={project.id} item={project} />
            ))}
          </div>
        );
  
      default:
        return null;
    }
  };
  
  return (
    <div style={{ width: "100%" }}>
      {data && data.length > 0 && (
         <>
         {renderProjects(data.filter((p) => p.type === "PRIVATE"), "PRIVATE", props.isQuotation)}
         {renderProjects(data.filter((p) => p.type === "PUBLIC"), "PUBLIC", props.isQuotation)}
         {renderProjects(data.filter((p) => p.isQuotation), "QUOTATION", props.isQuotation)}
         {renderProjects(data.filter((p) => !p.type), "adjudicated", props.isQuotation)}
       </>
      )}
  
      {totalPage > 1 && (
        <div className={styles.sectionDivider}>
          <div className={styles.numbers}>
            <div className={styles.arrowContainer}>
              <button
                onClick={handlePrevClick}
                disabled={currentPage === pages[0]}
                className={styles.arrow}
                title="Atrás"
                style={{ paddingRight: "20px", paddingLeft: "10px" }}
              >
                <LeftArrow width={20} height={20} />
              </button>
            </div>
            <div className={styles.numbersContainer}>
              {pageDecremenEllipses}
              {pageNumbers}
              {pageIncrementEllipses}
            </div>
            <div className={styles.arrowContainer}>
              <button
                onClick={handleNextClick}
                disabled={currentPage === pages[pages.length - 1]}
                className={styles.arrow}
                title="Siguiente"
                style={{ paddingLeft: "20px", paddingRight: "10px" }}
              >
                <ArrowRight width={20} height={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    take: state.dashboard.take,
    itemCount: state.dashboard.itemCount,
  }
}

export default connect(mapStateToProps)(Pagination);
