import React from "react";
import styles from "./RegisteredUserProfileLoader.module.scss";
import edifyLogo from "../../../assets/images/edify-logo.png";

const RegisteredUserProfileLoader = ({smallVersion}) => {

    return (
        <div className={styles.loaderContainer}>
            <img src={edifyLogo} alt="Edify" className={`${styles.spinnerEdify} ${smallVersion && styles.smallSpinner}`} />
            <div className={styles.loadingText}>Cargando</div>
            <div className={styles.spinner}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
}

export default RegisteredUserProfileLoader;
