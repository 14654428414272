import React, { useState } from 'react';
import styles from './AddCustomMaterialPopup.module.scss';
import { motion } from 'framer-motion';
import { ChevronUp, ChevronDown, CirclePlus } from 'lucide-react';

const variants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};


const AddCustomMaterialPopup = ({ onClose, onAdd, categories, isEstimacionDeCostos }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showUnits, setShowUnits] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('');
  const [newMaterial, setNewMaterial] = useState({
    categoryId: '',
    categoryName: '',
    name: '',
    unit: '',
    price: 0,
    quantity: 1,
    selectedPrice: '',
  });

  const unitNames = {
    kg: 'Kilogramos (kg)',
    gr: 'Gramos (gr)',
    m: 'Metros (m)',
    cm: 'Centímetros (cm)',
    l: 'Litros (l)',
    ml: 'Mililitros (ml)',
    m2: 'Metro Cuadrado (m²)',
    cm2: 'Centímetro Cuadrado (cm²)',
    m3: 'Metro Cúbico (m³)',
    cm3: 'Centímetro Cúbico (cm³)',
    u: 'Unidad (u)',
  };

  const units = Object.keys(unitNames);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewMaterial(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd({
      ...newMaterial,
      id: Date.now().toString(),
      price: parseFloat(newMaterial.price),
      temporary: true,
    });
  };

  const isFormValid = newMaterial.categoryId && newMaterial.name && newMaterial.unit && newMaterial.quantity


  const handleSelectCategory = (category) => {
    setNewMaterial((prev) => ({ ...prev, categoryId: category.id }));
    setSelectedCategory(category.name);
    setShowOptions(false);
  };

  const handleSelectUnit = (unit) => {
    setNewMaterial((prev) => ({ ...prev, unit }));
    setSelectedUnit(unitNames[unit]);
    setShowUnits(false);
  };

  const handleSelectPrice = (price) => {
    setNewMaterial((prev) => ({ ...prev, price }));
    setSelectedPrice(price);
  };


  const handleQuantityChange = (increment) => {
    setNewMaterial((prev) => ({
      ...prev,
      quantity: Math.max(0, prev.quantity + increment),
    }));
  };

  return (
    <div className={styles['popup-overlay']}>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        className={styles.popup}>
        <div className={styles['popup-header']}>
          <h2 className={styles['title']}>
            Crear Material</h2>
          <button type="button" onClick={onClose} className={styles['cancel-btn']}>Cancelar</button>
        </div>
        <form className={styles['popup-form']} onSubmit={handleSubmit}>
          <div className={styles['form-group']}>
            <label htmlFor="category">Categoría</label>
            <div
              className={styles['custom-select']}
              onClick={() => setShowOptions(!showOptions)}
            >
              <span className={`${styles['selected-option']} ${!selectedCategory ? styles['placeholder'] : ''}`}>
                {selectedCategory || 'Seleccione una categoría'}
              </span>
              {showOptions && (
                <ul className={styles['options-list']}>
                  {categories?.data?.map((category) => (
                    <li
                      key={category.id}
                      className={styles['option']}
                      onClick={() => handleSelectCategory(category)}
                    >
                      {category.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className={styles['form-group']}>
            <label htmlFor="name">Nombre</label>
            <input
              type="text"
              id="name"
              name="name"
              value={newMaterial.name}
              onChange={handleChange}
              required
              placeholder='Por ej: Arena fina bolsa x 25kg'
              className={styles['input']}
            />
          </div>
          <div className={styles['form-group']}>
            <label htmlFor="unit">Unidad</label>
            <div
              className={styles['custom-select']}
              onClick={() => setShowUnits(!showUnits)}
            >
              <span className={`${styles['selected-option']} ${!selectedUnit ? styles['placeholder'] : ''}`}>
                {selectedUnit || 'Seleccione una unidad'}
              </span>
              {showUnits && (
                <ul className={styles['options-list']}>
                  {units.map((unit) => (
                    <li
                      key={unit}
                      className={styles['option']}
                      onClick={() => handleSelectUnit(unit)}
                    >
                      {unitNames[unit]}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          {isEstimacionDeCostos && (
          <div className={styles['form-group']}>
            <label htmlFor="price">Precio unitario estimado</label>
            <div className={styles['price-options']}>
              <input
                type="number"
                id="price"
                name="price"
                value={newMaterial.price}
                onChange={(e) => handleSelectPrice(e.target.value)}
                required
                placeholder='Por ej: 100'
                className={styles['input']}
              />
            </div>
          </div>
          )}
          <div className={styles['form-group']}>
            <label htmlFor="quantity">Cantidad</label>
            <div className={styles['quantity-control']}>
              <button
                type="button"
                onClick={() => handleQuantityChange(1)}
                className={styles['quantity-btn']}
              >
                <ChevronUp size={20} />
              </button>
              <input
                type="number"
                id="quantity"
                name="quantity"
                value={newMaterial.quantity}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (!isNaN(value) && value >= 0) {
                    setNewMaterial((prev) => ({ ...prev, quantity: value }));
                  }
                }}
                className={styles['input']}
              />
              <button
                type="button"
                onClick={() => handleQuantityChange(-1)}
                className={styles['quantity-btn']}
              >
                <ChevronDown size={20} />
              </button>
            </div>
          </div>

          <div className={styles['button-group']}>
            <button disabled={!isFormValid} type="submit" className={styles['accept-btn']}>
              <CirclePlus size={18} style={{ marginRight: '1rem' }} />
              Agregar</button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default AddCustomMaterialPopup;





