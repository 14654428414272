import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { connect } from "react-redux";
import styles from "./NewPrivateProject.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import {
    deleteProyecto,
    openMainModal,
    closeMainModal,
    resetSubProjects,
    clearPostulationProject,
    resetIdProject,
    updateProyecto,
    resetProjectToEdit,
    openEdifySpinner,
    createNewProject,
} from "../../store/actions";
import { ArrowUpToLine } from "lucide-react";
import { ReactComponent as Publicar } from "../../assets/svg/publicarProyecto.svg";
import useFileUpload from "../../hooks/useFileUpload";
import DetailsForm from "../../components/UI/DetailsForm/DetailsForm";
import { motion } from "framer-motion";
import { fireAnalytics } from "../../utils/firebase";
import styled from "styled-components";
import NewNav from "../../components/Nav/NewNav";
import Botonera from "../../components/Botonera/Botonera";
import { Trash } from "lucide-react";

const variants = {
    visible: {
        scale: 1.0,
    },
    hidden: {
        scale: 1.1,
    },
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 8%;
  padding-left: 8%;
  max-width: 150rem;
        margin: auto;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const Wrapper = styled.div`
    display: flex;
    padding: 2rem;
    height: 100%;
    width: 100%;

    @media (max-width: 900px) {
    padding-top: 6rem;

    `;

const NewPrivateProject = ({
    userData,
    openMainModal,
    postulationProject,
    updateProyecto,
    deleteProyecto,
    closeModal,
    step1Project,
    createNewProject,
    closeMainModal,
    resetSubProjects,
    subProjects,
    clearPostulationProject,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isEditing = location?.pathname?.includes("edit");
    const [image, setImage] = useState(isEditing ? postulationProject?.proyecto_images[0]?.image : "");
    const [minDate, setMinDate] = useState('');
    const [minDateForSubProjects, setMinDateForSubProjects] = useState('');
    const [maxDateForSubProjects, setMaxDateForSubProjects] = useState('');
    const [imageEdit, setImageEdit] = useState([]);
    const [changedImages, setChangedImages] = useState(false);
    const [hasSubprojects, setHasSubprojects] = useState(false);
    const [showPublishButton, setShowPublishButton] = useState(false);
    const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
    const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
    const [imageFile, imageChangeHandler, fileName] = useFileUpload(
        image,
        setImage
    );

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        setOcultarEnMobile(windowWidth < 900);
        setMobileStylesFromParent(windowWidth < 900);
    };

    useEffect(() => {
        handleResize();
    }, []);

    // Agregar un event listener para el cambio de tamaño de la ventana
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        // Limpiar el event listener al desmontar el componente
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const ProyectoSchema = Yup.object().shape({
        name: Yup.string().required("El campo es requerido"),
        description: Yup.string().required("El campo es requerido"),
        endDate: Yup.date().required("El campo es requerido"),
        startDate: Yup.date().required("El campo es requerido"),
        zone: Yup.string().required("El campo es requerido"),
    });

    const initialValues = {
        name: isEditing ? postulationProject.name : "",
        description: isEditing ? postulationProject.description : "",
        endDate: isEditing ? postulationProject.endDate : "",
        startDate: isEditing ? postulationProject.startDate : "",
        zone: isEditing ? postulationProject.zone : "",
        image: isEditing ? postulationProject.proyecto_images[0]?.image : "",
    };
    const formik = useFormik({
        initialValues,
        validationSchema: ProyectoSchema,
        enableReinitialize: true,
        validateOnMount: true,
    });


    //Array de imagenes existentes en el proyecto
    const imagesFromProject = postulationProject?.proyecto_images ? postulationProject?.proyecto_images : [];

    useEffect(() => {
        if (subProjects.length > 0) {
            setHasSubprojects(true);
        } else if (isEditing && postulationProject.subproyecto.length > 0) {
            setHasSubprojects(true);
        } else {
            setHasSubprojects(false);
        }
    }, [subProjects]);

    // Maneja el texto del botón de publicar proyecto paso 1
    let contentPaso = formik.isValid && hasSubprojects ? "Publicar proyecto" : "Completá los campos";

    useEffect(() => {
        resetProjectToEdit();
        resetSubProjects();
        resetIdProject();

        if (!isEditing) {
            clearPostulationProject();
        }
    }, []);

    const divToScrollRef = useRef(null);

    //Nueva funcion para crear un proyecto 
    const sendNewProject = () => {
        const newProject = {
            ...formik.values,
            image: imageEdit,
        };
        fireAnalytics("publish_project", { category: "publishing" });
        createNewProject(newProject, subProjects, navigate);
    }


    // Botón de eliminar proyecto 
    const deleteHandler = () => {
        const projectId = postulationProject.id;
        deleteProyecto(parseInt(projectId), navigate);
    };

    // Función para editar el proyecto 
    const updateProject = () => {
        const updateProject = {
            ...formik.values,
        };
        let imageToSend = imageEdit
        if (
            formik.values.description === "" &&
            formik.values.endDate === "" &&
            formik.values.name === "" &&
            formik.values.zone === ""
        ) {
            openMainModal({
                title: "¡Atención!",
                body:
                    formik.values.description === "" &&
                        formik.values.endDate === "" &&
                        formik.values.name === ""
                        ? "Los campos de nombre, descripción y fecha de finalización no pueden estar vacíos"
                        : formik.values.zone === postulationProject.zone
                            ? "Debes seleccionar una ubicación diferente a la actual"
                            : "Lamentablemente no hemos podido procesar su solicitud de actualización ya que no se han detectado cambios en el proyecto",
                isSuccess: true,
                confirmHandler: null,
            });
        } else {
            openMainModal({
                title: "¿Deseas guardar los cambios efectuados?",
                body: "Si tenías usuarios postulados en el proyecto, se le notificarán de los cambios realizados. ",
                iconType: "warning",
                isSuccess: false,
                confirmHandler: () => {   
                    updateProyecto(updateProject, imageToSend, postulationProject.id, navigate);
                    closeMainModal();
                },
            });
        }
    };

    //Setea la imagen a enviar
    const handleImageFilesChange = (file) => {
        setImageEdit(file);
    };

    //obtenemos fecha mínima para publicar el proyecto
    useEffect(() => {
        const hoy = new Date();
        let dd = hoy.getDate();
        let mm = hoy.getMonth() + 1;
        let yyyy = hoy.getFullYear();
        const minDate = `${yyyy}-${mm < 10 ? `0${mm}` : mm}-${dd < 10 ? `0${dd}` : dd}`;
        setMinDate(minDate);
    }, []);

    //Maneja el estado del boton de publicar
    useEffect(() => {
        if (formik.isValid && hasSubprojects) {
            setShowPublishButton(true);
        } else {
            setShowPublishButton(false);
        }
    }, [formik.isValid, hasSubprojects]);


    return (
        <Container>
            {!ocultarEnMobile && <NewNav />}
            {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
            <Wrapper>
                {isEditing ? (
                    <motion.div
                        className={styles.newProject}
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        sizeTransition
                    >
                        <header className={styles.newProject_header}>
                            <div className={styles.newProject_header_txtContainer}>
                                <div>Obras privadas</div>
                                <h2 className={styles.newProject_header_txtContainer_title}>Editar proyecto</h2>
                            </div>
                        </header>
                        <main className={styles.newProject_container}>
                            <div className={styles.newProject_fromNewContainer}>
                                <section className={styles.newProject_containerSection}>
                                    <DetailsForm
                                        imageFile={imageFile}
                                        imageChangeHandler={imageChangeHandler}
                                        onImageFilesChange={handleImageFilesChange}
                                        formik={formik}
                                        fileName={fileName}
                                        minDate={minDate}
                                        isEditing={isEditing}
                                        imagesFromProject={imagesFromProject}
                                        projectId={postulationProject.id}
                                        setChangedImages={setChangedImages}
                                        subProjectsToEdit={postulationProject.subproyecto}
                                        projectIdForSub={postulationProject.id}
                                        closeModal={closeModal}
                                        maxDateForSubProjects={formik.values.endDate}
                                        setMaxDateForSubProjects={setMaxDateForSubProjects}
                                        minDateForSubProjects={formik.values.startDate}
                                        setMinDateForSubProjects={setMinDateForSubProjects}
                                        subProjectsLength={subProjects.length}
                                    />
                                </section>
                            </div>
                            <div className={styles.newProject_buttonContainer}>
                                <button
                                    className={`${styles.newProject_buttonDelete_button} ${styles.delete}`}
                                    onClick={deleteHandler}
                                >
                                    <Trash size={18} />
                                    Eliminar proyecto
                                </button>
                                {(formik.dirty || changedImages) && <div
                                    className={styles.newProject_button}
                                    onClick={updateProject}
                                >
                                    <div className={styles.contentStyle}>
                                        <Publicar /> Guardar cambios
                                    </div>
                                </div>}
                            </div>
                        </main>
                    </motion.div>) : (
                    <motion.div
                        className={styles.newProject}
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        sizeTransition
                    >
                        <header className={styles.newProject_header}>
                            <div className={styles.newProject_header_txtContainer}>
                                <div>Obras privadas</div>
                                <h2 className={styles.newProject_header_txtContainer_title}>
                                    Crear proyecto para comenzar a recibir cotizaciones
                                </h2>
                            </div>
                        </header>
                        <main ref={divToScrollRef} className={styles.newProject_container}>
                            <section className={styles.newProject_containerSection}>
                                <DetailsForm
                                    imageFile={imageFile}
                                    imageChangeHandler={imageChangeHandler}
                                    formik={formik}
                                    fileName={fileName}
                                    minDate={minDate}
                                    fromNew
                                    onImageFilesChange={handleImageFilesChange}
                                    projectId={postulationProject.id}
                                    setChangedImages={setChangedImages}
                                    setMaxDateForSubProjects={setMaxDateForSubProjects}
                                    setMinDateForSubProjects={setMinDateForSubProjects}
                                    subProjectsToEdit={postulationProject.subproyecto}
                                    isEditing={isEditing}
                                    projectIdForSub={step1Project.id}
                                    closeModal={closeModal}
                                    maxDateForSubProjects={maxDateForSubProjects}
                                    minDateForSubProjects={minDateForSubProjects}
                                    subProjectsLength={subProjects.length}

                                />
                            </section>
                            {showPublishButton && (
                            <div className={`${styles.newProject_buttonContainer}`}>
                                <button
                                    className={`${styles.newProject_button} ${(!formik.isValid || !hasSubprojects) && styles.disabled}`}
                                    disabled={!formik.isValid || !hasSubprojects}
                                    onClick={sendNewProject}>
                                    <div className={styles.contentStyle}>
                                        <ArrowUpToLine size={24}/> {contentPaso}
                                    </div>
                                </button>
                            </div>
                            )}
                        </main>
                    </motion.div>
                )}
            </Wrapper>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state.general.userData,
        projectIdForSub: state.profile.projectIdForSub,
        step1Project: state.profile.projectStep1Id,
        formStep2Style: state.profile.styleForm,
        edifySpinner: state.general.edifySpinner,
        subProjects: state.profile.sendSubprojects,
        postulationProject: state.dashboard.postulationProject,
    };
};

export default connect(mapStateToProps, {
    resetIdProject,
    openMainModal,
    updateProyecto,
    deleteProyecto,
    createNewProject,
    closeMainModal,
    openEdifySpinner,
    clearPostulationProject,
    resetSubProjects,
})(NewPrivateProject);
